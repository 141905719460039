//@ts-nocheck
import { createSelector } from 'reselect';

export const productsByIdsSelector = (state) => state.products.entities;

export const productsByArticlesSelector = createSelector([productsByIdsSelector], (productsByIds) => {
    return Object.values(productsByIds).reduce((map, product) => {
        map[product.article] = product;
        return map;
    }, {});
});
