//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const REGIONS_URL = `${BASE_URL}/regions`;

export const fetchAllowedCountriesForOnlineRegistration = () => {
    return apiClient.get(`${REGIONS_URL}/online-registration/allowed-countries`);
};

export const fetchRegions = (territory) => {
    return apiClient.get(`${REGIONS_URL}?country=${territory}`);
};
