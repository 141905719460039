//@ts-nocheck
class LocalStorageService {
    getItem(key) {
        return localStorage.getItem(key);
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

    setItem(key, value) {
        localStorage.setItem(key, value);
    }
}

export default new LocalStorageService();
