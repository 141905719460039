import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        en: 'Discounts',
        id: 'Discounts.title',
        ru: 'Скидки'
    },
    tooltip: {
        en: 'Click to see discount description',
        id: 'Discounts.tooltip',
        ru: 'Нажмите, чтобы посмотреть описание скидки'
    }
});
