import { defineMessages } from 'react-intl';

export default defineMessages({
    createSuccess: {
        defaultMessage: 'Patient successfully created',
        id: 'CreatePatientModal.createSuccess'
    },
    middleNameLabel: {
        defaultMessage: 'Middle name',
        id: 'CreatePatientModal.middleNameLabel'
    },
    title: {
        defaultMessage: 'Add patient',
        id: 'CreatePatientModal.title'
    }
});
