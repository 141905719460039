//@ts-nocheck
import IconMobileApp from 'components/icon/mobile/IconMobileApp';
import { CommunicationType } from 'constants/communicationType';
import Button from 'ds_components/button/Button';
import commonMessages from 'messages/CommonMessages';
import Email from 'page/layout/components/email/Email';
import Phone from 'page/layout/components/phone/Phone';
import messages from 'page/layout/footer/FooterMessages';
import UpButton from 'page/layout/footer/middle/UpButton';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

import iconFacebook from '../assets/socialMediaImages/facebook.svg';
import iconInstagram from '../assets/socialMediaImages/instagram.svg';
import iconOk from '../assets/socialMediaImages/ok.svg';
import iconTelegram from '../assets/socialMediaImages/telegram.svg';
import iconVk from '../assets/socialMediaImages/vk.svg';
import iconYoutube from '../assets/socialMediaImages/youtube.svg';
import styles from './Footer.module.css';

const Footer = () => {
    return (
        <>
            <FooterContent />
        </>
    );
};

const FooterContent = () => {
    const intl = useIntl();
    const currentLocale = useSelector((state) => state.i18n.currentLocale);
    const territorySettings = useSelector(currentTerritorySettingsSelector);

    return (
        <footer className={styles.footer}>
            <UpButton />
            <div className={styles.container}>
                <div className={styles.item}>{renderLinks(territorySettings, currentLocale)}</div>
                <div className={styles.communications}>
                    <div className={styles.item}>{renderContactLinks(territorySettings, intl)}</div>
                    <div className={styles.item}>{renderCommunications(territorySettings, intl)}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.linksBlock}>
                        <div className={styles.socialLinks}>{renderSocialLinks(territorySettings)}</div>
                        {renderApplicationLinks(territorySettings)}
                        {renderUniversalApplicationLink(territorySettings, intl)}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const renderLinks = (territorySettings, currentLocale) => {
    const { links } = territorySettings;

    return links?.map((link) => (
        <div key={link.uri} className={styles.link}>
            <a href={link.uri}>{link.localizations[currentLocale]}</a>
        </div>
    ));
};

const renderContactLinks = (territorySettings, intl) => {
    const { communications } = territorySettings;
    const filteredCommunications = communications?.filter((c) => c.type === CommunicationType.CONTACT_LINK);

    return filteredCommunications?.map((link) => (
        <div key={link.uri} className={styles.link}>
            <a href={link.uri}>{intl.formatMessage(commonMessages.feedback)}</a>
        </div>
    ));
};

const renderCommunications = (territorySettings, intl) => {
    const { communications, territory } = territorySettings;
    const filteredCommunications = communications?.filter((c) => c.type === CommunicationType.EMAIL || c.type === CommunicationType.PHONE);

    return filteredCommunications?.map((communication) => {
        if (communication.type === CommunicationType.EMAIL) {
            return <Email key={communication.email} data={communication} />;
        } else {
            return (
                <div key={communication.number} className={styles.communication}>
                    <Phone intl={intl} territory={territory} data={communication} />
                </div>
            );
        }
    });
};

const renderSocialLinks = (territorySettings) => {
    const { socialLinks } = territorySettings;

    return socialLinks?.map((socialLink) => (
        <div key={socialLink.uri} className={styles.socialLink}>
            {getSocialLink(socialLink)}
        </div>
    ));
};

const getSocialLink = (socialLink) => {
    switch (socialLink.type) {
        case 'TELEGRAM':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.telegram}>
                    <svg>
                        <use href={`${iconTelegram}#icon-telegram`} />
                    </svg>
                </a>
            );
        case 'FACEBOOK':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.facebook}>
                    <svg>
                        <use href={`${iconFacebook}#icon-facebook`} />
                    </svg>
                </a>
            );
        case 'OK':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.ok}>
                    <svg>
                        <use href={`${iconOk}#icon-ok`} />
                    </svg>
                </a>
            );
        case 'VK':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.vk}>
                    <svg>
                        <use href={`${iconVk}#icon-vk`} />
                    </svg>
                </a>
            );
        case 'YOUTUBE':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.youtube}>
                    <svg>
                        <use href={`${iconYoutube}#icon-youtube`} />
                    </svg>
                </a>
            );
        case 'INSTAGRAM':
            return (
                <a href={socialLink.uri} target={'_blank'} rel='noopener noreferrer' className={styles.instagram}>
                    <svg>
                        <use href={`${iconInstagram}#icon-instagram`} />
                    </svg>
                </a>
            );
        default:
            return null;
    }
};

const renderApplicationLinks = (territorySettings) => {
    const { applicationLinks, useUniversalUrlApplicationDownload } = territorySettings;

    if (useUniversalUrlApplicationDownload) {
        return null;
    }

    return (
        <div className={styles.applicationLinks}>
            {applicationLinks?.map((applicationLink, i) => (
                <IconMobileApp key={i} type={applicationLink.type} link={applicationLink.uri} />
            ))}
        </div>
    );
};

const renderUniversalApplicationLink = (territorySettings, intl) => {
    const { universalUrlApplicationDownload, useUniversalUrlApplicationDownload } = territorySettings;

    if (!useUniversalUrlApplicationDownload) {
        return null;
    }

    return (
        <div className={styles.applicationLinks}>
            <Button
                className={styles.downloadApp}
                target={'_blank'}
                href={universalUrlApplicationDownload}
                type={'outline'}
                text={intl.formatMessage(messages.downloadApp)}
            />
        </div>
    );
};
export default Footer;
