import { defineMessages } from 'react-intl';

export default defineMessages({
    close: {
        en: 'Close',
        id: 'ReorderControl.close',
        ru: 'Закрыть'
    },
    loginOrRegister: {
        en: 'Log in or register',
        id: 'ReorderControl.loginOrRegister',
        ru: 'Войти или зарегистрироваться'
    },
    makingReorder: {
        en: 'Making a reorder',
        id: 'ReorderControl.makingReorder',
        ru: 'Оформление дозаказа'
    },
    reorder: {
        en: 'Reorder',
        id: 'ReorderControl.reorder',
        ru: 'Дозаказать'
    },
    reorderNoticeAuth: {
        defaultMessage: 'To place an additional order, you need to log in to your personal account, where there is a patient <span>{fullName}</span>.',
        id: 'ReorderControl.reorderNoticeAuth'
    },
    reorderNoticeNotAvailable: {
        en: 'Too much time has passed since the tests were taken.',
        id: 'ReorderControl.reorderNoticeNotAvailable',
        ru: 'С момента сдачи анализов прошло слишком много времени.'
    },
    reorderNoticePatient: {
        defaultMessage:
            'To place an order, you need to add a patient to your personal account <span>{fullName}</span> or log in to the personal account where this patient is.',
        id: 'ReorderControl.reorderNoticePatient'
    },
    reorderTitleNotAvailable: {
        en: 'Reorder is not available',
        id: 'ReorderControl.reorderTitleNotAvailable',
        ru: 'Дозаказ недоступен'
    }
});
