//@ts-nocheck
import apiClient from 'client/ApiClient';
import { AUTHORIZE_URL, BASE_URL } from 'client/constants';
import queryString from 'query-string';

const USER_URL = `${BASE_URL}/users`;
const LOGIN_CONFIRMATION = `${BASE_URL}/login-confirmation`;
const RECOVERY = `${BASE_URL}/recovery`;
const REACTIVATE = `${BASE_URL}/reactivate`;

export const login = (data) => {
    return apiClient.post(`${LOGIN_CONFIRMATION}/send-login-code`, data);
};

export const loginAuthenticated = (data) => {
    return apiClient.post(`${LOGIN_CONFIRMATION}/send/authenticated`, data);
};

export const loginConfirm = (data) => {
    return apiClient.post(`${LOGIN_CONFIRMATION}/confirm`, data);
};

export const loginConfirmAuthenticated = (data) => {
    return apiClient.post(`${LOGIN_CONFIRMATION}/confirm/authenticated`, data);
};

export const loginPassword = (data) => {
    return apiClient.post(`${USER_URL}/login`, data);
};

export const register = (data) => {
    return apiClient.post(`${USER_URL}/register`, data);
};

export const recovery = (data) => {
    return apiClient.post(`${RECOVERY}/send`, data);
};

export const recoveryConfirmByCode = (data) => {
    return apiClient.post(`${RECOVERY}/confirm-by-code`, data);
};

export const recoveryConfirmByINZ = (data) => {
    return apiClient.post(`${RECOVERY}/confirm-by-inz`, data);
};

export const recoverySetPassword = (data) => {
    return apiClient.post(`${RECOVERY}/set-password`, data);
};

export const resetPassword = (data) => {
    return apiClient.post(`${RECOVERY}/reset-password`, data);
};

export const deactivate = (data) => {
    const { id, territory } = data;
    return apiClient.delete(`${USER_URL}/${id}?${queryString.stringify({ territory })}`);
};

export const reactivation = (data) => {
    return apiClient.post(`${REACTIVATE}/send`, data);
};

export const reactivationConfirm = (data) => {
    return apiClient.post(`${REACTIVATE}`, data);
};

export const authorization = (authorizationCode, domain) => {
    return apiClient.get(`${AUTHORIZE_URL}/authorize?${queryString.stringify({ code: authorizationCode, domain })}`);
};

export const refresh = () => {
    return apiClient.get(`${AUTHORIZE_URL}/refresh/cookies`);
};

export const logout = () => {
    return apiClient.get(`${AUTHORIZE_URL}/logout/cookies`);
};

export const fetchCurrent = () => {
    return apiClient.get(`${USER_URL}/current`);
};

export const updateUser = (data) => {
    const { id, ...request } = data;
    return apiClient.patch(`${USER_URL}/${id}`, request);
};

export const updateEmail = (data) => {
    const { id, ...request } = data;
    return apiClient.put(`${USER_URL}/${id}/email`, request);
};

export const updatePhone = (data) => {
    const { id, ...request } = data;
    return apiClient.put(`${USER_URL}/${id}/phone`, request);
};

export const updatePassword = (data) => {
    const { id, ...request } = data;
    return apiClient.put(`${USER_URL}/${id}/password`, request);
};

export const fetchHistory = (data) => {
    const { id, page, perPage } = data;
    return apiClient.get(`${USER_URL}/${id}/history?page=${page}&perPage=${perPage}`);
};
