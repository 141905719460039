import { defineMessages } from 'react-intl';

export default defineMessages({
    documentNote: {
        defaultMessage: `* migration card, temporary registration at the place of residence, serviceman's certificate,
            certificate from the passport office in case of loss of passport, registration card from the hotel.
            The presented document must contain information about the temporary or
            permanent registration on the territory of the Russian Federation and a photograph.`,
        id: 'CartAcceptedPage.documentNote'
    },
    documentNotice: {
        defaultMessage: 'To place this order, you must <span> present your passport  or a document replacing it</span>',
        id: 'CartAcceptedPage.documentNotice'
    },
    onlineRegistrationStep1: {
        id: 'CartAcceptedPage.onlineRegistrationStep1',
        defaultMessage: 'With the code received, go to the selected office'
    },
    orderAcceptedTitleHome: {
        defaultMessage: 'for "Home visit" is accepted',
        id: 'CartAcceptedPage.orderAcceptedTitleHome'
    },
    onlineRegistrationStep2: {
        defaultMessage: 'Go to the online registration terminal at the Invitro office and enter the code',
        id: 'CartAcceptedPage.onlineRegistrationStep2'
    },
    orderAcceptedTitleOffice: {
        defaultMessage: 'Accepted',
        id: 'CartAcceptedPage.orderAcceptedTitleOffice'
    },
    office: {
        defaultMessage: 'Medical office',
        id: 'CartAcceptedPage.office'
    },
    orderNumberLinkOffice: {
        defaultMessage: 'Your order #{number}',
        id: 'CartAcceptedPage.orderNumberLinkOffice'
    },
    onlineRegistrationStep3: {
        defaultMessage: 'After printing the documents, take them and go to the treatment room!',
        id: 'CartAcceptedPage.onlineRegistrationStep3'
    },
    pageTitle: {
        defaultMessage: 'Thank you for the order',
        id: 'CartAcceptedPage.pageTitle'
    },
    overdueNotice: {
        defaultMessage:
            'Your order is available within 7 days up to and including {date}. To submit medical investigation chosen, visit a medical office by that date of the latest. Otherwise preorder will be automatically cancelled, and money will be returned.',
        id: 'CartAcceptedPage.overdueNotice'
    },
    prepareLink: {
        defaultMessage: 'Preparation for analyzes',
        id: 'CartAcceptedPage.prepareLink'
    },
    paymentCompleted: {
        defaultMessage: 'Paid',
        id: 'Payment.completed'
    },
    prepareLinkUrl: {
        defaultMessage: 'https://www.invitro.ru/analizes/for-doctors/prepare/',
        id: 'CartAcceptedPage.prepareLinkUrl'
    },
    paymentAwaiting: {
        defaultMessage: 'In progress',
        id: 'Payment.awaiting'
    },
    printLink: {
        defaultMessage: 'Print order',
        id: 'CartAcceptedPage.printLink'
    },
    paymentAwaitingMessage: {
        defaultMessage: 'We are waiting for payment confirmation from the bank. Please wait.',
        id: 'Payment.paymentAwaitingMessage'
    },
    saveLink: {
        id: 'CartAcceptedPage.saveLink',
        defaultMessage: 'Save order'
    },
    paymentError: {
        defaultMessage: 'Unaccepted',
        id: 'Payment.error'
    },
    paymentErrorMessage: {
        defaultMessage: 'An error occurred while making a payment.',
        id: 'Payment.paymentErrorMessage'
    },
    paymentRequestsLimit: {
        defaultMessage: 'Number of requests exceeded',
        id: 'Payment.requestsLimit'
    },
    registrationCode: {
        id: 'CartAcceptedPage.registrationCode',
        defaultMessage: 'Registration code: {code}'
    },
    paymentSuccess: {
        defaultMessage: 'Accepted',
        id: 'Payment.success'
    },
    phoneFree: {
        defaultMessage: 'Free call within Russia',
        id: 'CartAcceptedPage.phoneFree'
    },
    phonesText: {
        defaultMessage: 'Reference:',
        id: 'CartAcceptedPage.phonesText'
    }
});
