import { defineMessages } from 'react-intl';

export default defineMessages({
    AGREEMENT_BLANK_TYPE_AIDS: {
        defaultMessage: 'Agreeing to an AIDS test',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_AIDS'
    },
    AGREEMENT_BLANK_TYPE_CONSENT: {
        defaultMessage: 'Agreement to the processing of personal data',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_CONSENT'
    },
    AGREEMENT_BLANK_TYPE_GENERAL: {
        defaultMessage: 'The main agreement between the patient and the organization',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_GENERAL'
    },
    AGREEMENT_BLANK_TYPE_INTERVENTION: {
        defaultMessage: 'Agreement to medical intervention',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_INTERVENTION'
    },
    AGREEMENT_BLANK_TYPE_LOYALTY: {
        defaultMessage: 'Agreement to participate in the loyalty program',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY'
    },
    AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL: {
        defaultMessage: 'Refusal to participate in the loyalty program',
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL'
    },
    ANTIGEN_TEST: {
        defaultMessage: 'Analysis for qualitative determination of antigens',
        id: 'DocumentType.ANTIGEN_TEST'
    },
    CYTOLOGY: {
        defaultMessage: 'Referral for cytological examination',
        id: 'DocumentType.CYTOLOGY'
    },
    noDocumentsMsg: {
        defaultMessage: 'No documents found',
        id: 'DocumentListPage.noDocumentsMsg'
    },
    ECOLOGY: {
        defaultMessage: 'Water and soil quality research',
        id: 'DocumentType.ECOLOGY'
    },
    pageTitle: {
        id: 'DocumentListPage.pageTitle',
        defaultMessage: 'Documents'
    },
    FEMOFLOR: {
        defaultMessage: 'Femoflor',
        id: 'DocumentType.FEMOFLOR'
    },
    unableToFetchDocuments: {
        id: 'DocumentListPage.unableToFetchDocuments',
        defaultMessage: 'Unable to fetch documents'
    },
    FIBROMAX: {
        defaultMessage: 'Fibromax',
        id: 'DocumentType.FIBROMAX'
    },
    ANTROPOMETRY: {
        defaultMessage: 'Antropometry',
        id: 'DocumentType.ANTROPOMETRY'
    },
    GENETICS: {
        defaultMessage: 'Molecular genetic research',
        id: 'DocumentType.GENETICS'
    },
    ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER: {
        defaultMessage: 'High-tech water quality research',
        id: 'DocumentType.ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER'
    },
    HISTOLOGY: {
        defaultMessage: 'Referral for histological examination',
        id: 'DocumentType.HISTOLOGY'
    },
    ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER: {
        defaultMessage: 'Radionuclide analysis of watern',
        id: 'DocumentType.ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER'
    },
    MAIN_FORM: {
        id: 'DocumentType.MAIN_FORM',
        defaultMessage: 'Referral form'
    },
    AIDS: {
        defaultMessage: 'Informed consent to be tested for HIV infection',
        id: 'DocumentType.AIDS'
    },
    INTERFERON_STATUS: {
        defaultMessage: 'Interferon status',
        id: 'DocumentType.INTERFERON_STATUS'
    },
    ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER: {
        defaultMessage: 'Screening chemical examination of water',
        id: 'DocumentType.ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER'
    },
    MICRIBIOLOGY: {
        defaultMessage: 'Referral for microbiological research',
        id: 'DocumentType.MICRIBIOLOGY'
    },
    ECOLOGY_SOIL: {
        defaultMessage: 'Water and soil quality research',
        id: 'DocumentType.ECOLOGY_SOIL'
    },
    MICROSCOPIC_STUDY_ON_FUNGAL_CELLS: {
        defaultMessage: 'Microscopic examination of skin and nail samples for the presence of fungal cells',
        id: 'DocumentType.MICROSCOPIC_STUDY_ON_FUNGAL_CELLS'
    },
    ENCEPHALITIS: {
        defaultMessage: 'Tick research',
        id: 'DocumentType.ENCEPHALITIS'
    },
    PCR: {
        defaultMessage: 'PCR-diagnostics of infectious diseases',
        id: 'DocumentType.PCR'
    },
    ESTIMATE: {
        defaultMessage: 'Referral form (estimate)',
        id: 'DocumentType.ESTIMATE'
    },
    STUDIES_OF_TRACE_ELEMENTS: {
        id: 'DocumentType.STUDIES_OF_TRACE_ELEMENTS',
        defaultMessage: 'Study of trace elements'
    },
    GLUCOSE_TUBES_OF_GRAY: {
        defaultMessage: 'Gastropanel',
        id: 'DocumentType.GLUCOSE_TUBES_OF_GRAY'
    },
    PCR_DIGENE_TEST: {
        id: 'DocumentType.PCR_DIGENE_TEST',
        defaultMessage: 'PCR DIGENE-TEST'
    },
    MOLECULAR_GENETIC: {
        defaultMessage: 'Questionnaire of molecular genetic research',
        id: 'DocumentType.MOLECULAR_GENETIC'
    },
    PRISCA: {
        defaultMessage: 'Prisca - Prenatal screening program',
        id: 'DocumentType.PRISCA'
    },
    REFUND_ACT: {
        defaultMessage: 'Return certificate',
        id: 'DocumentType.REFUND_ACT'
    },
    SPERMOGRAMM: {
        defaultMessage: 'Ejaculate examination',
        id: 'DocumentType.SPERMOGRAMM'
    },
    SURVEYS: {
        defaultMessage: 'Surveys',
        id: 'DocumentType.SURVEYS'
    },
    VOLUNTARY: {
        defaultMessage: 'Informed voluntary consent of the patient to complex medical intervention',
        id: 'DocumentType.VOLUNTARY'
    }
});
