//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import classNames from 'classnames/bind';
import ButtonLink from 'components/button/link/ButtonLink';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import Popup from 'page/cart/components/cartSummary/components/popup/Popup';
import noPatientPhoto from 'page/cart/confirmation/assets/no-patient-photo.svg';
import styles from 'page/cart/confirmation/patientBlock/PatientBlock.module.css';
import messages from 'page/cart/confirmation/patientBlock/PatientBlockMessages';
import AuthBlock from 'page/orders/component/authBlock/AuthBlock';
import CreatePatientModal from 'page/partial/patient/create/CreatePatientModal';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SELECT_PATIENT, TOGGLE_ONLINE_REGISTRATION } from 'redux/cart/actions';
import { reorderDataSelector } from 'redux/cart/reorder.selectors';
import { cartOrderTypeSelector, cartPatientsSelector, selectedPatientSelector } from 'redux/cart/selectors';
import { mainPatientSelector } from 'redux/patient/selectors';
import { isAdult } from 'utils/patientUtils';

const cx = classNames.bind(styles);

class PatientBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCreatePatientModal: false
        };
        this.handleShowCreatePatientModal = this.handleShowCreatePatientModal.bind(this);
        this.handleHideCreatePatientModal = this.handleHideCreatePatientModal.bind(this);
        this.handleChangeRegistrationMode = this.handleChangeRegistrationMode.bind(this);
    }

    render() {
        const { mainPatient, onlineRegistrationEnabled, authenticated, intl, cartOrderType, patients } = this.props;
        const { showCreatePatientModal } = this.state;

        if (!authenticated) {
            return <AuthBlock authenticated={authenticated} />;
        } else {
            return (
                <div className={cx({ noPatients: patients.length < 1 })}>
                    <h2 className={styles.patientBlockTitle}>{intl.formatMessage(messages.title)}</h2>
                    {this.renderPatientsList()}
                    {(!onlineRegistrationEnabled || !patients) && !cartOrderType.reorder && !cartOrderType.paymentPreorder ? (
                        <ButtonSecondary
                            className={styles.addPatientButton}
                            text={intl.formatMessage(messages.addPatientButton)}
                            onClick={() => this.handleShowCreatePatientModal()}
                        />
                    ) : null}
                    {onlineRegistrationEnabled && patients ? (
                        !isAdult(mainPatient) ? (
                            <React.Fragment>
                                <Popup text={intl.formatMessage(messages.adultWarning)} position='top' />
                                <ButtonLink
                                    className={styles.changeRegistrationModeBtn}
                                    text={intl.formatMessage(messages.changeRegistrationMode)}
                                    onClick={this.handleChangeRegistrationMode}
                                />
                            </React.Fragment>
                        ) : null
                    ) : null}
                    <CreatePatientModal onClose={() => this.handleHideCreatePatientModal()} show={showCreatePatientModal} />
                </div>
            );
        }
    }

    renderPatientsList() {
        const { patients, selectedPatient, handleSelectPatient, onlineRegistrationEnabled, cartOrderType } = this.props;

        if (patients.length <= 0) {
            return null;
        } else {
            return (
                <React.Fragment>
                    {patients
                        ?.filter((patient) => !onlineRegistrationEnabled || patient.main)
                        .map((patient) => {
                            const classNamePatient = cx({
                                onlineRegistrationPatient: onlineRegistrationEnabled,
                                patient: true
                            });
                            const classNameSwitch = cx({
                                switchActive: selectedPatient?.id === patient.id,
                                switchContainer: true,
                                switchInactive: selectedPatient?.id !== patient.id
                            });

                            return (
                                <div className={classNamePatient} key={patient.id}>
                                    <div className={styles.patientPhoto}>
                                        <img src={patient.photo || noPatientPhoto} alt='' />
                                    </div>
                                    <div className={styles.patientInfo}>
                                        <div className={styles.patientName}>
                                            {patient.lastName} {patient.firstName} {patient.middleName}
                                        </div>
                                        <div className={styles.patientBirthday}>{patient.birthday}</div>
                                        <div className={styles.patientEmail}>{patient.email}</div>
                                    </div>
                                    {!cartOrderType.reorder && !cartOrderType.paymentPreorder && (
                                        <div className={classNameSwitch} onClick={() => handleSelectPatient(patient)}>
                                            <div className={styles.switch} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </React.Fragment>
            );
        }
    }

    handleShowCreatePatientModal() {
        this.setState({
            showCreatePatientModal: true
        });
    }

    handleHideCreatePatientModal() {
        this.setState({
            showCreatePatientModal: false
        });
    }

    handleChangeRegistrationMode() {
        const { toggleOnlineRegistration, history } = this.props;
        toggleOnlineRegistration();
        history.replace('/cart');
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        cartOrderType: cartOrderTypeSelector(state),
        mainPatient: mainPatientSelector(state),
        onlineRegistrationEnabled: state.cart.onlineRegistrationEnabled,
        patients: cartPatientsSelector(state),
        reorderData: reorderDataSelector(state),
        selectedPatient: selectedPatientSelector(state)
    };
};

const mapDispatchToProps = {
    handleSelectPatient: SELECT_PATIENT.base,
    toggleOnlineRegistration: TOGGLE_ONLINE_REGISTRATION.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PatientBlock)));
