//@ts-nocheck
import * as orderClient from 'client/OrderClient';
import { request } from 'redux/helpers';
import { CLEAR, FETCH } from 'redux/orderDetails/actions';
import { all, put, takeEvery } from 'redux-saga/effects';

function* fetch(action) {
    yield request(FETCH, [orderClient.fetchOrderDetails, action.meta], action.meta);
}

function* clear(action) {
    yield put(CLEAR.success(null, action.meta));
}

export default function* orderDetailsSagas() {
    yield all([takeEvery(FETCH.BASE, fetch), takeEvery(CLEAR.BASE, clear)]);
}
