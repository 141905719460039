//@ts-nocheck
import { GET_EDNA_SETTINGS } from 'redux/chat/actions';

const initialState = {
    ednaSettings: null,
    initialized: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_EDNA_SETTINGS.SUCCEEDED:
        case GET_EDNA_SETTINGS.ERRORED: {
            if (action.type !== GET_EDNA_SETTINGS.ERRORED) {
                return {
                    ...state,
                    ednaSettings: { ...action.payload.data }
                };
            } else {
                return {
                    ...state,
                    ednaSettings: null
                };
            }
        }
        default: {
            return state;
        }
    }
}
