//@ts-nocheck
import {
    add as addFns,
    compareAsc as compareAscFns,
    compareDesc as compareDescFns,
    differenceInDays as differenceInDaysFns,
    differenceInYears as differenceInYearsFns,
    format,
    formatISO,
    fromUnixTime as fromUnixTimeFns,
    parse,
    parseISO as parseISOFns,
    startOfDay as startOfDayFns,
    startOfMonth as startOfMonthFns,
    startOfYear as startOfYearFns
} from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

const defaultLocale = enUS;
const locales = { en: enUS, ru };
Object.keys(locales).forEach((code) => registerLocale(code, locales[code]));

export function formatISODate(date) {
    if (!date) {
        return '';
    }
    return formatISO(date, { representation: 'date' });
}

export function formatISODateTime(date) {
    return formatISO(date, { format: 'basic' });
}

export function formatDate(date, locale, formatStr = 'P') {
    return format(date, formatStr, { locale: getLocaleObj(locale) });
}

export function getDateFormat(locale) {
    const localeObj = getLocaleObj(locale);
    return localeObj.formatLong.date({ width: 'short' });
}

export function formatDateTime(date, locale, formatStr = 'Pp') {
    return format(date, formatStr, { locale: getLocaleObj(locale) });
}

export function formatTime(time, formatStr = 'p') {
    return format(time, formatStr);
}

export function compareAsc(date1, date2) {
    return compareAscFns(parseISO(date1), parseISO(date2));
}

export function compareDesc(date1, date2) {
    return compareDescFns(parseISO(date1), parseISO(date2));
}

export function parseTime(time, formatStr = 'p') {
    return parse(time, formatStr, new Date());
}

export function parseISO(date) {
    return parseISOFns(date);
}

export function startOfDay(date) {
    return startOfDayFns(date);
}

export function startOfMonth(date) {
    return startOfMonthFns(date);
}

export function startOfYear(date) {
    return startOfYearFns(date);
}

export function fromUnixTime(time) {
    return fromUnixTimeFns(time / 1000);
}

export function differenceInYears(to, from) {
    return differenceInYearsFns(to, from);
}

export function differenceInDays(to, from) {
    return differenceInDaysFns(to, from);
}

export function age(birthday) {
    return differenceInYears(new Date(), birthday);
}

export function add(date, duration) {
    return addFns(date, duration);
}

function getLocaleObj(locale) {
    return locales[locale] || defaultLocale;
}

export const getPadTime = (time) => time.toString().padStart(2, '0');

export function getTimeToResend(data, otherData: any = null) {
    const timeToResend_1 = data?.response?.timeToResend || data?.error?.timeToResend || 0;
    const timeToResend_2 = otherData?.response?.timeToResend || otherData?.error?.timeToResend || 0;

    if (otherData) {
        return timeToResend_2 >= timeToResend_1 ? timeToResend_2 : timeToResend_1;
    } else {
        return timeToResend_1;
    }
}

export function getErrorsByTimestamp(data) {
    return data.sort((a, b) => compareDesc(a.timestamp, b.timestamp));
}
