import { defineMessages } from 'react-intl';

export default defineMessages({
    cardInfo: {
        defaultMessage: '<b>Regular cashback</b> — percentage increase in cashback based on frequency of purchase.',
        id: 'IKBServicesRegularPage.cardInfo'
    },
    cardInfo2: {
        defaultMessage: 'If you pay for medical services more than once within 30 calendar days, the amount of the cashback increases accordingly.',
        id: 'IKBServicesRegularPage.cardInfo2'
    },
    featuredCardTitle: {
        defaultMessage: '{count, plural, one {Basic} =2 {Increased} other {Maximum}} cashback',
        id: 'IKBServicesRegularPage.featuredCardTitle'
    },
    info: {
        defaultMessage: `<p>The Participant of the Bonus Program has the right to earn an increased number of INVITRO Bonus Roubles, determined by the Organizer, when ordering and paying for medical services.</p> <p> For this purpose, the Participant of the Bonus Programme can activate this option in the INVITRO Personal Office" service or in the INVITRO medical office/diagnostic centre".</p>`,
        id: 'IKBServicesRegularPage.info'
    },
    nextChangesAvailable: {
        defaultMessage: 'The next changes will be possible <mark>30 days</mark> after the date of pressing the "Confirm" button.',
        id: 'IKBServicesRegularPage.nextChangesAvailable'
    },
    purchasesPerMonth: {
        defaultMessage: '{count} {count, plural, one {order} other {orders}} per month',
        id: 'IKBServicesRegularPage.purchasesPerMonth'
    },
    takeNotice: {
        defaultMessage:
            'Certain tests may not be available at all medical offices. Before making your choice, please check the availability of the products you are interested in at a convenient medical office.',
        id: 'IKBServicesRegularPage.takeNotice'
    },
    takeNoticeNote: {
        defaultMessage: 'Please note!',
        id: 'IKBServicesRegularPage.takeNoticeNote'
    },
    title: {
        defaultMessage: 'Regular cashback',
        id: 'IKBServicesRegularPage.title'
    }
});
