export const OrderWarningType = {
    /**
     * Некорректный тип завершения заказа.
     */
    FINISH_TYPE_WRONG: 'FINISH_TYPE_WRONG',

    /**
     * Не заполнена требуемая медицинская информация.
     */
    MEDICAL_INFO_NOT_SET: 'MEDICAL_INFO_NOT_SET',

    /**
     * Необходимо подтвердить информацию о доставке результатов.
     */
    DELIVERY_CONFIRMATION_REQUIRED: 'DELIVERY_CONFIRMATION_REQUIRED',

    /**
     * Несовместимые продукты (например, сертификаты не могут продаваться с продуктами других типов).
     */
    PRODUCTS_INCOMPATIBLE: 'PRODUCTS_INCOMPATIBLE',

    /**
     * Биоматериал продукта не заполнен.
     */
    PRODUCT_BIOMATERIAL_NOT_SET: 'PRODUCT_BIOMATERIAL_NOT_SET',

    /**
     * Не указано кодовое слово.
     */
    DELIVERY_KEYWORD_NOT_SET: 'DELIVERY_KEYWORD_NOT_SET',

    /**
     * Услуга взятия не требуется ни для одного из продуктов заказа.
     */
    TAKE_SERVICE_NOT_REQUIRED: 'TAKE_SERVICE_NOT_REQUIRED',

    /**
     * Не указан ни один способ доставки.
     */
    DELIVERY_NOT_SET: 'DELIVERY_NOT_SET',

    /**
     * В заказе отсутствует требуемая услуга взятия.
     */
    PRODUCT_REQUIRED_SERVICE_ABSENT: 'PRODUCT_REQUIRED_SERVICE_ABSENT',

    /**
     * Пол указанный для продукта не соответствует полу пациента.
     */
    PRODUCT_GENDER_WRONG: 'PRODUCT_GENDER_WRONG',

    /**
     * Преаналитическое правило для продукта.
     */
    PRODUCT_PREANALYTIC_RULE: 'PRODUCT_PREANALYTIC_RULE',

    /**
     * Превышена тестоёмкость контейнера.
     */
    REORDER_CONTAINER_TEST_COUNT_EXCEEDED: 'REORDER_CONTAINER_TEST_COUNT_EXCEEDED',

    /**
     * Отсутствует контейнер, требуемый для дозаказа продукта.
     */
    REORDER_NO_CONTAINER_FOR_PRODUCT: 'REORDER_NO_CONTAINER_FOR_PRODUCT',

    /**
     * Превышен допустимый срок дозаказа продукта.
     */
    REORDER_PRODUCT_DAYS_LIMIT_EXCEEDED: 'REORDER_PRODUCT_DAYS_LIMIT_EXCEEDED'
};
