import { createRequestTypes } from 'redux/helpers';

export const FETCH = createRequestTypes('USER/FETCH');
export const UPDATE = createRequestTypes('USER/UPDATE');
export const UPDATE_PHONE = createRequestTypes('USER/UPDATE_PHONE');
export const UPDATE_EMAIL = createRequestTypes('USER/UPDATE_EMAIL');
export const PASSWORD = createRequestTypes('USER/PASSWORD');
export const DEACTIVATE = createRequestTypes('USER/DEACTIVATE');
export const FETCH_HISTORY = createRequestTypes('USER/HISTORY');

export const CLEAR = createRequestTypes('USER/CLEAR');
export const CLEAR_FETCH = createRequestTypes('USER/CLEAR_FETCH');
export const CLEAR_UPDATE = createRequestTypes('USER/CLEAR_UPDATE');
export const CLEAR_PASSWORD = createRequestTypes('USER/CLEAR_PASSWORD');
export const CLEAR_HISTORY = createRequestTypes('USER/CLEAR_HISTORY');
export const CLEAR_DEACTIVATE = createRequestTypes('USER/CLEAR_DEACTIVATE');

export const fetchHistory = (payload: { id: string; page: number; perPage: number }) => FETCH_HISTORY.base(payload);
export const clearHistory = () => CLEAR_HISTORY.base();
export const updatePassword = (payload: { id: string; oldPassword: string; password: string; passwordRepeat: string }) => PASSWORD.base(payload);
export const updatePasswordClear = () => CLEAR_PASSWORD.base();

export const updateUser = (payload: { id: string; nickname: string }) => UPDATE.base(payload);
export const updateUserEmail = (payload: { confirmedEmailToken: string; id: string; email: string; territory: string }) => UPDATE_EMAIL.base(payload);
export const updateUserPhone = (payload: { confirmedPhoneToken: string; id: string; phone: string; territory: string }) => UPDATE_PHONE.base(payload);

export const deactivate = (payload: { id: string; territory: string | null }) => DEACTIVATE.base(payload);
export const clearDeactivate = () => CLEAR_DEACTIVATE.base();
export const clearUpdate = () => CLEAR_UPDATE.base();
export const clearFetch = () => CLEAR_FETCH.base();
