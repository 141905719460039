//@ts-nocheck
import * as homeVisitClient from 'client/HomeVisitClient';
import { cartAnalyzesSelector, commonCalculationDataSelector } from 'redux/cart/selectors';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { CREATE } from 'redux/homeVisits/actions';
import { gmtAcceptedPurchase } from 'redux/orders/sagas';
import { all, select, takeLatest } from 'redux-saga/effects';
import GTMService, { Order, PAGE, PARAM, Product } from 'services/GTMService';

function* create(action) {
    yield request(CREATE, [homeVisitClient.create, action.meta], action.meta);
}

export function* gmtVndAcceptedPurchase() {
    try {
        let param, orderData, orderProducts;
        param = PARAM.VND;
        const cartAnalyzes = yield select(cartAnalyzesSelector);
        const { cost } = yield select(commonCalculationDataSelector);
        orderData = yield new Order(null, cost, null, null, null);
        orderProducts = yield cartAnalyzes?.products.map((product) => new Product(product.article, product.name, null, null, product.cost, 1, null));
        GTMService.purchase(PAGE.THANK_YOU, param, orderData, orderProducts);
    } catch (e) {
        console.error(e);
    }
}

export default function* homeVisitSagas() {
    yield all([takeLatest(CREATE.BASE, create), takeLatest(CREATE.SUCCEEDED, gmtVndAcceptedPurchase)]);
}
