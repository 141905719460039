import { DeliveryType } from 'constants/deliveryType';
import messages from 'page/cart/confirmation/checkupDeliveryBlock/CheckupDeliveryBlockMessages';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import styles from './CheckupDeliveryBlock.module.css';

interface IProps {
    checkupDelivery: {
        sendMethod: string;
        email: string;
        sms: string;
        errors: {
            sms?: boolean;
            email?: boolean;
        };
    };
}

const CheckupDeliveryBlock: FC<IProps> = ({ checkupDelivery }): JSX.Element => {
    const { formatMessage } = useIntl();
    const { sendMethod, email, sms } = checkupDelivery;
    const isEmail = sendMethod === DeliveryType.EMAIL;
    return (
        <div className={styles.container}>
            <h2>{formatMessage(messages.title)}</h2>
            <div className={styles.content}>
                <div className={styles.title}>{formatMessage(isEmail ? messages.email : messages.phone)}</div>
                <div className={styles.value}>{isEmail ? email : sms}</div>
            </div>
        </div>
    );
};

export default CheckupDeliveryBlock;
