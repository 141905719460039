import ButtonLink from 'components/button/link/ButtonLink';
import commonMessages from 'messages/CommonMessages';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const LoginButton = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const login = () => {
        navigate('/login');
    };

    return <ButtonLink text={intl.formatMessage(commonMessages.login)} onClick={login} />;
};

export default LoginButton;
