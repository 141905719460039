//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const RESULTS_URL = `${BASE_URL}/results`;

export const getPdf = (data) => {
    return apiClient.get(`${RESULTS_URL}/pdf?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const sendEmail = (data) => {
    return apiClient.post(`${RESULTS_URL}/send`, data);
};

export const addResult = (data) => {
    return apiClient.post(`${RESULTS_URL}`, data);
};

export const requestTranslation = (data) => {
    return apiClient.post(`${RESULTS_URL}/translate`, data);
};
