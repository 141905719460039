//@ts-nocheck
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRues } from '@formatjs/intl-pluralrules/should-polyfill';
import apiClient from 'client/ApiClient';
import { SUPPORTED_TERRITORIES } from 'redux/cities/constants';
import { CHANGE } from 'redux/i18n/actions';
import { LANGUAGE_HEADER, LOCALE_KEY_COOKIE, LOCALES_BY_DOMAIN, SUPPORTED_LOCALES } from 'redux/i18n/constants';
import { all, call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';
import { getHostname } from 'utils/browserUtils';

// Добавить полифилы Intl для поддержки локализации в старых версиях браузеров.
async function polyfill() {
    if (shouldPolyfillCanonicalLocales()) {
        // Если отсутствует модуль Intl.getCanonicalLocales - добавляем соответствующий polyfill
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (shouldPolyfillLocale()) {
        // Если отсутствует модуль Intl.Locale - добавляем соответствующий polyfill
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillPluralRues()) {
        // Если отсутствует модуль Intl.PluralRules - добавляем соответствующий polyfill
        // и правила английского языка по-умолчанию.
        await import('@formatjs/intl-pluralrules/polyfill');
        await import('@formatjs/intl-pluralrules/locale-data/en');
    }
}

polyfill().then(() => {});

function* changeLocale(action) {
    const meta = action.meta || {};
    const { start, success, error, cancel } = CHANGE;
    yield put(start(meta));
    try {
        const locale = meta.locale || getCurrentLocale();
        meta.locale = locale;
        const messagesCache = yield select((state) => state.i18n.messagesCache);
        let payload;
        if (messagesCache[locale]) {
            payload = { default: messagesCache[locale] };
        } else {
            payload = yield call(() => import(`i18n/messages/${locale}.json`));
            // При смене локали, необходимо убедиться, что загружены правила для поддержки множественного числа
            // в указанной локали
            yield call(() => import(`@formatjs/intl-pluralrules/locale-data/${locale}`));
        }
        yield put(success(payload, meta));
        return payload;
    } catch (caught) {
        return yield put(error(caught, meta));
    } finally {
        if (yield cancelled()) {
            yield put(cancel(meta));
        }
    }
}

function* handleLocaleChange(action) {
    const { locale } = action.meta;
    apiClient.setGlobalHeader(LANGUAGE_HEADER, locale);
    yield cookieService.set(LOCALE_KEY_COOKIE, locale);
}

export function getCurrentLocale() {
    const cookieLocale = cookieService.get(LOCALE_KEY_COOKIE);
    const browserLocale = getBrowserLocale();
    const hostNameLocale = getHostnameLocale(getHostname());

    let locale = cookieLocale || browserLocale || hostNameLocale;

    // Вне зоны Инвитро выставляем ru
    if (locale !== SUPPORTED_LOCALES.RUSSIAN && locale !== SUPPORTED_LOCALES.ENGLISH) {
        locale = SUPPORTED_LOCALES.RUSSIAN;
    }
    return locale;
}

export function getHostnameLocale(host) {
    const parts = host.split('.');
    const firstLevelDomain = parts[parts.length - 1];
    return LOCALES_BY_DOMAIN[firstLevelDomain];
}

function getBrowserLocale() {
    // Define user's language. Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    // Split locales with a region code
    return language.toLowerCase().split(/[_-]+/)[0];
}

export default function* languageSagas() {
    yield all([takeLatest(CHANGE.BASE, changeLocale), takeLatest(CHANGE.SUCCEEDED, handleLocaleChange)]);
}
