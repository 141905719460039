//@ts-nocheck
import { createSelector } from 'reselect';

export const officesByIdSelector = (state) => state.offices.entities;
const validationDataByOfficeIdSelector = (state) => state.offices.validationData;
const officesFilterSelector = (state) => state.offices.filter;
export const chosenOfficeIdSelector = (state) => state.offices.chosenId;

export const officesSelector = createSelector([officesByIdSelector], (entities) =>
    Object.values(entities)
        .map((item) => {
            return {
                ...item,
                office: {
                    ...item.office,
                    name: item.office?.name.replace(/МО /i, '')
                }
            };
        })
        .sort(function (a, b) {
            return a?.office?.name?.localeCompare(b?.office?.name);
        })
);

export const filteredOfficesSelector = createSelector([officesSelector, officesFilterSelector], (offices, filter) => {
    if (!filter.query) {
        return offices;
    }

    const upperQuery = filter.query.toUpperCase();
    return offices.filter(
        (cartOffice) => cartOffice.office.name.toUpperCase().indexOf(upperQuery) >= 0 || cartOffice.office.address.toUpperCase().indexOf(upperQuery) >= 0
    );
});

export const selectedOfficeSelector = createSelector([officesByIdSelector, (state) => state.offices.selectedId], (entities, id) => entities[id]);

export const chosenOfficeSelector = createSelector(
    [officesByIdSelector, validationDataByOfficeIdSelector, chosenOfficeIdSelector],
    (entities, validationData, id) => {
        const cartOffice = entities[id];
        if (!cartOffice) {
            return null;
        }
        return {
            ...cartOffice,
            validProductsIds: validationData[id]?.validProductsIds
        };
    }
);
