//@ts-nocheck
import { OrderType } from 'constants/orderType';
import messages from 'page/cart/accepted/CartAcceptedPageMessages';
import queryString from 'query-string';
import { PAYMENT_STATUS, POLLING_ERROR_STATUS } from 'redux/cart/constants';

import orderAcceptedIcon from '../page/cart/accepted/assets/acceptedIcon.svg';
import clockIcon from '../page/cart/accepted/assets/clockIcon.svg';
import errorIcon from '../shared/assets/errorIcon.svg';

const PAYMENT_TITLES = {
    [PAYMENT_STATUS.AWAITING]: messages.paymentAwaiting,
    [PAYMENT_STATUS.AUTHORIZED]: messages.paymentSuccess,
    [PAYMENT_STATUS.COMPLETED]: messages.paymentCompleted,
    [PAYMENT_STATUS.CANCELLED]: messages.paymentError,
    [PAYMENT_STATUS.DECLINED]: messages.paymentError
};

const PAYMENT_MESSAGES = {
    [PAYMENT_STATUS.AWAITING]: messages.paymentAwaitingMessage,
    [PAYMENT_STATUS.CANCELLED]: messages.paymentErrorMessage,
    [PAYMENT_STATUS.DECLINED]: messages.paymentErrorMessage
};

const isOnlineRegistration = (location) => {
    const query = queryString.parse(location.search);
    return query?.onlineRegistration === 'true';
};

const isOnlinePayment = (location) => {
    const query = queryString.parse(location.search);
    return query?.onlinePayment === 'true';
};

export class PaymentStatusService {
    constructor(intl, paymentStatus, location) {
        this.intl = intl;
        this.paymentStatus = paymentStatus;
        this.isOnlineRegistration = isOnlineRegistration(location);
        this.isOnlinePayment = isOnlinePayment(location);
    }

    getStatusIcon() {
        if (!this.isOnlinePayment) {
            return orderAcceptedIcon;
        }

        const { transactionStatus } = this.paymentStatus;

        switch (transactionStatus) {
            case PAYMENT_STATUS.AWAITING: {
                return clockIcon;
            }
            case PAYMENT_STATUS.AUTHORIZED:
            case PAYMENT_STATUS.COMPLETED: {
                return orderAcceptedIcon;
            }
            default: {
                return errorIcon;
            }
        }
    }

    getTitle() {
        if (!this.isOnlinePayment) {
            return this.intl.formatMessage(messages.orderAcceptedTitleOffice);
        }
        const { transactionStatus, type } = this.paymentStatus;

        if (type === POLLING_ERROR_STATUS.MAX_POLLING) {
            return this.intl.formatMessage(PAYMENT_TITLES[PAYMENT_STATUS.DECLINED]);
        }

        if (transactionStatus) {
            return this.intl.formatMessage(PAYMENT_TITLES[transactionStatus]);
        }
        return null;
    }

    getMessage() {
        if (!this.isOnlinePayment) {
            return null;
        }
        const { message, transactionStatus, type } = this.paymentStatus;
        if (transactionStatus) {
            return message ?? (PAYMENT_MESSAGES[transactionStatus] ? this.intl.formatMessage(PAYMENT_MESSAGES[transactionStatus]) : null);
        }
        if (type === POLLING_ERROR_STATUS.MAX_POLLING) {
            return this.intl.formatMessage(messages.paymentRequestsLimit);
        }
    }

    hasOverdueNotice(orderType) {
        const { transactionStatus } = this.paymentStatus;
        return this.isOnlinePayment && orderType === OrderType.PREORDER && transactionStatus === PAYMENT_STATUS.AUTHORIZED;
    }

    hasDocumentNotice(passportRequired) {
        return passportRequired && !this.isOnlineRegistration;
    }

    hasOnlineRegistration(acceptedInfo) {
        return this.isOnlineRegistration && acceptedInfo;
    }
}
