import { defineMessages } from 'react-intl';

export default defineMessages({
    cities: {
        defaultMessage: 'Cities',
        id: 'ChangeCityForm.cities'
    },
    countries: {
        defaultMessage: 'Countries',
        id: 'ChangeCityForm.countries'
    },
    search: {
        defaultMessage: 'City search',
        id: 'ChangeCityForm.search'
    },
    searchMessage: {
        defaultMessage: 'Nothing found',
        id: 'ChangeCityForm.searchMessage'
    }
});
