import { defineMessages } from 'react-intl';

export default defineMessages({
    statusCancelled: {
        defaultMessage: 'Order cancelled',
        id: 'OrderStatus.statusCancelled'
    },
    statusCompleted: {
        defaultMessage: 'Order ready',
        id: 'OrderStatus.statusCompleted'
    },
    statusCompletedResultsReady: {
        defaultMessage: ', order results completed',
        id: 'OrderStatus.statusCompletedResultsReady'
    },
    statusError: {
        defaultMessage: 'Error while processing order, please call contact center',
        id: 'OrderStatus.statusError'
    },
    statusErrorWithSupportPhone: {
        defaultMessage: 'Error while processing order, please call <phone></phone>',
        id: 'OrderStatus.statusErrorWithSupportPhone'
    },
    statusNew: {
        defaultMessage: 'Order in work, result would be ready at {date} including',
        id: 'OrderStatus.statusNew'
    },
    statusNewUnknownDate: {
        defaultMessage: 'Order in work, results not completed yet',
        id: 'OrderStatus.statusNewUnknownDate'
    },
    statusNotPaid: {
        defaultMessage: 'Order not paid',
        id: 'OrderStatus.statusNotPaid'
    },
    statusPaid: {
        defaultMessage: 'Order paid',
        id: 'OrderStatus.statusPaid'
    },
    statusPreorder: {
        defaultMessage: 'Result prepared, waiting you in BO "{office}"',
        id: 'OrderStatus.statusPreorder'
    },
    statusPartial: {
        defaultMessage: 'Order is partially ready, check order results',
        id: 'OrderStatus.statusPartial'
    },
    statusPreorderUnknownOffice: {
        id: 'OrderStatus.statusPreorderUnknownOffice',
        defaultMessage: 'Result prepared, waiting you in selected office'
    },
    statusPaymentInProgress: {
        defaultMessage: 'The order is in the process of payment',
        id: 'OrderStatus.statusPaymentInProgress'
    }
});
