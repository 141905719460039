export const DeliveryType = {
    /**
     * Email
     */
    EMAIL: 'EMAIL',

    /**
     * Sms
     */
    SMS: 'SMS'
};
