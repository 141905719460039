//@ts-nocheck
import * as client from 'client/DynamicsClient';
import { FETCH_ALL, SEND, UPDATE_COMMENT } from 'redux/dynamics/actions';
import { request } from 'redux/helpers';
import { all, takeLatest } from 'redux-saga/effects';

function* fetchDynamics(action) {
    yield request(FETCH_ALL, [client.fetchAll, action.meta]);
}

function* updateComment(action) {
    yield request(UPDATE_COMMENT, [client.updateComment, action.meta], action.meta);
}

function* send(action) {
    yield request(SEND, [client.send, action.meta]);
}

export default function* patientSagas() {
    yield all([takeLatest(FETCH_ALL.BASE, fetchDynamics), takeLatest(UPDATE_COMMENT.BASE, updateComment), takeLatest(SEND.BASE, send)]);
}
