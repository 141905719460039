//@ts-nocheck
import { downloadFile } from 'client/OrderClient';
import { loadBlobFile, printBlobFile } from 'utils/fileUtils';

class OrderService {
    async handlePdfDownload(patient, order, locale) {
        const response = await this.getResponse(patient, order, locale, true);
        loadBlobFile(response);
    }

    async handlePdfPrint(patient, order, locale) {
        const response = await this.getResponse(patient, order, locale, false);
        printBlobFile(response);
    }

    getResponse(patient, order, locale, download) {
        return downloadFile({
            download,
            lang: locale,
            orderId: order.id,
            orderType: order.orderType,
            patientId: patient.id
        });
    }
}

export default new OrderService();
