/**
 * Перечисление типов статусов бонусного счета
 */
export const IKBBonusAccountStatus = {
    /**
     * Активный (активирован)
     */
    ACTIVE: 'ACTIVE',

    /**
     * Базовый (не активирован)
     */
    BASE: 'BASE'
};
