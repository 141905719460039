export function getOrigin() {
    return window.location.origin;
}

export function getHostname() {
    return window.location.hostname;
}

export function goTo(url: any, newTab = false) {
    if (url) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location = url;
        }
    }
}

export function goBack() {
    window.history.back();
}

export function reload() {
    window.location.reload();
}

export function print() {
    window.print();
}

export function getCookie(name: string) {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getBackRefUrl(location: Location = window.location, afterrefister: boolean = false) {
    return `${decodeURIComponent(location.pathname)}${decodeURIComponent(location.search)}${afterrefister ? '&afterrefister=true' : ''}`;
}
