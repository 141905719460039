import classNames from 'classnames/bind';
import React, { FC, memo } from 'react';
import { Row, useBlockLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';

import styles from './Table.module.css';
const cx = classNames.bind(styles);

type TProps = { columns: any; data: any; emptyText?: string; className: string; onRowClick?: (row: Row) => void };

const Table: FC<TProps> = ({ columns, data, emptyText, className, onRowClick }) => {
    const defaultColumn = { width: '100%' };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, defaultColumn }, useBlockLayout, useSticky);

    return (
        <div {...getTableProps()} className={cx(styles.table, styles.sticky, className)}>
            <div className={styles.header}>
                {headerGroups.map((headerGroup, index) => (
                    <div {...headerGroup.getHeaderGroupProps({ key: index })} key={index} className={styles.tr} style={{ display: 'inline-flex' }}>
                        {headerGroup.headers.map((column) => (
                            <div {...column.getHeaderProps()} key={`${column.id}${index}`} className={styles.th}>
                                {column.render('Header')}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <div {...getTableBodyProps()} className={styles.body}>
                {!rows || rows.length === 0
                    ? emptyText
                    : rows.map((row, i) => {
                          prepareRow(row);
                          if (row.values.name !== '') {
                              return (
                                  <div key={i} className={styles.tr} onClick={() => onRowClick && onRowClick(row)} style={{ display: 'inline-flex' }}>
                                      {row.cells.map((cell, index) => {
                                          return (
                                              <div {...cell.getCellProps({ key: index })} key={`${cell.column.id}${index}`} className={styles.td}>
                                                  {cell.render('Cell')}
                                              </div>
                                          );
                                      })}
                                  </div>
                              );
                          }
                      })}
            </div>
        </div>
    );
};

export default memo(Table);
