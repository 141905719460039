import { defineMessages } from 'react-intl';

export default defineMessages({
    orderContentsLinkHide: {
        defaultMessage: 'Hide order contents',
        id: 'ProductList.orderContentsLinkHide'
    },
    orderContentsLinkShow: {
        defaultMessage: 'Show order contents',
        id: 'ProductList.orderContentsLinkShow'
    },
    reorderContentsLinkHide: {
        defaultMessage: 'Hide reorder contents',
        id: 'ProductList.reorderContentsLinkHide'
    },
    reorderContentsLinkShow: {
        defaultMessage: 'Show reorder contents',
        id: 'ProductList.reorderContentsLinkShow'
    }
});
