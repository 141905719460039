//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import { OfficeType } from 'constants/officeType';
import { OrderType } from 'constants/orderType';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/cart/accepted/CartAcceptedPage.module.css';
import messages from 'page/cart/accepted/CartAcceptedPageMessages';
import CartAcceptedContent from 'page/cart/accepted/components/CartAcceptedContent';
import queryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FETCH_PAYMENT_STATUS, SET_PAYMENT_STATUS } from 'redux/cart/actions';
import { CLEAR } from 'redux/homeVisits/actions';
import { CLEAR_ACCEPTED_INFO, FETCH_ACCEPTED_INFO } from 'redux/orders/actions';
import { acceptedInfoSelector } from 'redux/orders/selectors';
import OrderService from 'services/OrderService';
import { PaymentStatusService } from 'services/PaymentService';

const CartAcceptedOfficePage = (props) => {
    const { intl, location, navigate, fetchAcceptedInfo, fetchPaymentStatus, clearAcceptedInfo, paymentStatus, paymentSettings, acceptedInfo, success } = props;

    const getParams = useCallback(() => {
        const query = queryString.parse(location.search, { parseBooleans: true });
        return {
            createdTime: acceptedInfo?.createdTime,
            id: query?.id,
            officeAddress: acceptedInfo?.officeAddress,
            onlinePayment: query?.onlinePayment,
            orderId: acceptedInfo?.orderId,
            orderNumber: acceptedInfo?.orderNumber,
            orderType: query?.orderType,
            passportRequired: acceptedInfo?.passportRequired,
            patientId: query?.patientId,
            paymentType: query?.source,
            source: acceptedInfo?.source
        };
    }, [location, acceptedInfo]);
    useEffect(() => {
        const { id, patientId, orderType } = getParams();
        if (id && patientId) {
            fetchAcceptedInfo({
                orderId: id,
                orderType,
                patientId
            });
        }
    }, []);

    useEffect(() => {
        if (success && getParams()?.onlinePayment) {
            handleFetchPaymentStatus();
        }
    }, [success]);

    useEffect(() => {
        if (paymentStatus.status === 403) {
            const timeoutId = setTimeout(() => navigate('/cart'), 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [paymentStatus]);

    useEffect(() => {
        return () => {
            clearAcceptedInfo();
        };
    }, []);

    const handleFetchPaymentStatus = () => {
        const { id, patientId, orderType, paymentType } = getParams();
        fetchPaymentStatus({
            orderId: id,
            orderType,
            patientId,
            paymentType,
            pollingCount: paymentSettings?.poolingCount,
            pollingDelay: paymentSettings?.poolingDelay
        });
    };

    const handlePrint = () => {
        const { locale } = props;
        const { patientId, id, orderType } = getParams();
        OrderService.handlePdfPrint({ id: patientId }, { id, orderType }, locale);
    };

    const handleDownload = () => {
        const { locale } = props;
        const { patientId, id, orderType } = getParams();
        OrderService.handlePdfDownload({ id: patientId }, { id, orderType }, locale);
    };

    const { orderNumber, orderId, patientId, orderType, source, passportRequired } = getParams();

    const renderOrderNumber = (
        <Link to={`/orders/${orderId}?patientId=${patientId}&source=${source}`} className={styles.orderNumberLink}>
            {orderType === OrderType.REORDER ? (
                intl.formatMessage(commonMessages.reorderNumberTitle, {
                    orderNumber: () => orderNumber
                })
            ) : (
                <FormattedMessage {...messages.orderNumberLinkOffice} values={{ number: orderNumber }} />
            )}
        </Link>
    );

    const paymentService = new PaymentStatusService(intl, paymentStatus, location);
    const statusIcon = paymentService.getStatusIcon();
    const title = paymentService.getTitle();
    const message = paymentService.getMessage();
    const hasDocumentNotice = paymentService.hasDocumentNotice(passportRequired);
    const hasOverdueNotice = paymentService.hasOverdueNotice(orderType);
    const hasOnlineRegistration = paymentService.hasOnlineRegistration(acceptedInfo);

    return (
        <CartAcceptedContent
            title={title}
            message={message}
            params={getParams()}
            orderType={orderType}
            orderNumber={renderOrderNumber}
            officeType={OfficeType.OFFICE}
            print={handlePrint}
            download={handleDownload}
            hasRequiredParameters={orderId && patientId}
            iconSrc={statusIcon}
            hasDocumentNotice={hasDocumentNotice}
            hasOverdueNotice={hasOverdueNotice}
            hasOnlineRegistration={hasOnlineRegistration}
        />
    );
};

const mapStateToProps = (state) => ({
    acceptedInfo: acceptedInfoSelector(state),
    loading: state.orders.fetchAcceptedInfo?.loading,
    paymentSettings: state.startup.settings.paymentSettings,
    paymentStatus: state.cart.paymentStatus,
    success: state.orders.fetchAcceptedInfo?.success
});

const mapDispatchToProps = {
    clearAcceptedInfo: CLEAR_ACCEPTED_INFO.base,
    clearHomeVisit: CLEAR.base,
    fetchAcceptedInfo: FETCH_ACCEPTED_INFO.base,
    fetchPaymentStatus: FETCH_PAYMENT_STATUS.base,
    setPaymentStatus: SET_PAYMENT_STATUS.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartAcceptedOfficePage)));
