//@ts-nocheck
import { CLEAR_SEND, FETCH_ALL, FILTER, SELECT, SEND, UPDATE_COMMENT } from 'redux/dynamics/actions';
import { basicReducer } from 'redux/helpers';
import { parseISO, startOfDay } from 'utils/timeUtils';

const initialState = {
    comment: {},
    entities: {},
    filter: {},
    selected: [],
    send: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED: {
            return {
                ...initialState,
                loading: true
            };
        }
        case FETCH_ALL.SUCCEEDED: {
            const tests = action.payload.data;
            const entities = tests.reduce((map, test) => {
                if (test.time) {
                    const code = test.code;
                    map[code] = map[code] || {};
                    const time = parseISO(test.time);
                    const date = startOfDay(time).getTime();
                    test.time = time.getTime();
                    map[code][date] = map[code][date] || [];
                    map[code][date].push(test);
                }
                return map;
            }, {});
            return {
                ...state,
                entities,
                loading: false
            };
        }
        case FETCH_ALL.ERRORED: {
            return {
                ...state,
                error: action.payload?.response?.data || true,
                loading: false
            };
        }
        case SELECT.BASE:
            return {
                ...state,
                selected: action.meta.selected
            };
        case FILTER.BASE:
            const filter = action.meta;
            const startTime = filter.startTime;
            const endTime = filter.endTime;
            const filteredCodes = Object.values(state.entities)
                .flatMap((value) => Object.entries(value))
                .filter(([key, value]) => (!startTime || startTime <= key) && (!endTime || endTime >= key))
                .flatMap(([key, value]) => value)
                .map((test) => test.code);
            const selected = state.selected.filter((s) => filteredCodes.includes(s.value));
            return {
                ...state,
                filter,
                selected
            };
        case UPDATE_COMMENT.SUCCEEDED:
        case UPDATE_COMMENT.STARTED:
        case UPDATE_COMMENT.ERRORED:
            const entities = state.entities;
            if (action.type === UPDATE_COMMENT.SUCCEEDED) {
                const request = action.meta;
                const testsByDate = entities[request.code];
                const test = Object.values(testsByDate)
                    .flatMap((tests) => tests)
                    .find((test) => test.inz === request.inz);
                if (test) {
                    test.userComment = request.comment;
                }
            }
            return {
                ...state,
                comment: basicReducer(state.comment, action),
                entities
            };
        case SEND.SUCCEEDED:
        case SEND.STARTED:
        case SEND.ERRORED:
            return {
                ...state,
                send: basicReducer(state.send, action)
            };
        case CLEAR_SEND.BASE:
            return {
                ...state,
                send: {}
            };
        default:
            return state;
    }
}
