import { defineMessages } from 'react-intl';

export default defineMessages({
    clientLabelFormat: {
        defaultMessage: 'Client: {client}',
        id: 'ResultsByKeyPage.clientLabelFormat'
    },
    gotAccount: {
        defaultMessage: 'Got an account?',
        id: 'ResultsByKeyPage.gotAccount'
    },
    title: {
        defaultMessage: 'Tests results',
        id: 'ResultsByKeyPage.title'
    }
});
