//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import Button from 'components/button/Button';
import slideArrowRightIcon from 'page/error/assets/slideArrowRightIcon.svg';
import messages from 'page/error/NotFoundPageMessages';
import Footer from 'page/layout/footer/Footer';
import PageLayout from 'page/layout/PageLayout';
import React from 'react';
import { injectIntl } from 'react-intl';

import notFoundPageIcon from './assets/notFoundPageIcon.svg';
import styles from './NotFoundPage.module.css';

class NotFoundPage extends React.PureComponent {
    render() {
        const { intl, history } = this.props;

        return (
            <PageLayout
                main={
                    <React.Fragment>
                        <div className={styles.container}>
                            <img alt='' className={styles.notFoundPageIcon} src={notFoundPageIcon} />
                            <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
                            <div className={styles.text}>{intl.formatMessage(messages.text)}</div>
                            <div className={styles.buttonWrapper}>
                                <Button onClick={() => navigate('/')}>
                                    <img alt='' className={styles.slideArrowRightIcon} src={slideArrowRightIcon} />
                                    {intl.formatMessage(messages.buttonText)}
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                }
                footer={<Footer />}
            />
        );
    }
}

export default withRouter(injectIntl(NotFoundPage));
