import { defineMessages } from 'react-intl';

export default defineMessages({
    accountDeactivated: {
        defaultMessage: 'Your personal account has been deactivated',
        id: 'LoginConfirmPage.accountDeactivated'
    },
    errorEmailNotFound: {
        defaultMessage: 'This email is not registered',
        id: 'LoginConfirmPage.errorEmailNotFound'
    },
    pageTitle: {
        defaultMessage: 'Account confirmation',
        id: 'LoginConfirmPage.pageTitle'
    },
    pleaseRegister: {
        defaultMessage: 'Please register.',
        id: 'LoginConfirmPage.pleaseRegister'
    },
    recover: {
        defaultMessage: 'Recover',
        id: 'LoginConfirmPage.recover'
    },
    youCanRestore: {
        defaultMessage: 'You can restore it within 14 days.',
        id: 'LoginConfirmPage.youCanRestore'
    }
});
