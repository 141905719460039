import { defineMessages } from 'react-intl';

export default defineMessages({
    birthday: {
        defaultMessage: 'Date of Birth:',
        id: 'IKBPatientsPage.birthday'
    },
    button: {
        defaultMessage: 'Choose',
        id: 'IKBPatientsPage.button'
    },
    gender: {
        defaultMessage: 'Gender:',
        id: 'IKBPatientsPage.gender'
    },
    navigationTitle: {
        defaultMessage: 'Select patient',
        id: 'IKBPatientsPage.navigationTitle'
    }
});
