import { MaskitoOptions } from '@maskito/core';
import { useMaskito } from '@maskito/react';
import classNames from 'classnames/bind';
import React, { FC, forwardRef, InputHTMLAttributes, memo, useEffect, useImperativeHandle, useRef } from 'react';

import styles from './Input.module.css';
const cx = classNames.bind(styles);

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    ref?: any;
    resetStyles?: boolean;
    error?: boolean | string;
    autoFocus?: boolean;
    maskOptions?: MaskitoOptions;
}

const Input: FC<IProps> = forwardRef(({ autoFocus, error, className, maskOptions, type = 'text', resetStyles = false, ...rest }, ref) => {
    const maskedInputRef = useMaskito({ options: maskOptions });
    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                focus() {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }
            };
        },
        []
    );

    useEffect(() => {
        if (autoFocus) {
            inputRef?.current?.focus();
        }
    });

    return (
        <input
            ref={(node) => {
                maskedInputRef(node);
                inputRef.current = node;
            }}
            type={type}
            className={cx(
                {
                    error,
                    input: !resetStyles && true
                },
                className
            )}
            {...rest}
        />
    );
});

export default memo(Input);
