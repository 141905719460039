import { defineMessages } from 'react-intl';

export default defineMessages({
    applyCertificate: {
        defaultMessage: 'Apply',
        id: 'Certificates.applyCertificate'
    },
    certificateHint: {
        defaultMessage: 'You can apply multiple certificates in one order.',
        id: 'Certificates.certificateHint'
    },
    certificateNumber: {
        defaultMessage: 'Certificate {certificate} for {cost}',
        id: 'Certificates.certificateNumber'
    },
    certificatePlaceholder: {
        defaultMessage: 'certificate number',
        id: 'Certificates.certificatePlaceholder'
    },
    certificateWarning: {
        defaultMessage: 'The remainder of the {cost} certificate will be burned after the order is paid.',
        id: 'Certificates.certificateWarning'
    },
    formLabel: {
        defaultMessage: 'Certificate',
        id: 'Certificates.formLabel'
    }
});
