import { defineMessages } from 'react-intl';

export default defineMessages({
    codeWord: {
        defaultMessage: 'Code word',
        id: 'DeliveryBlock.codeWord'
    },
    hint: {
        defaultMessage: 'Specify at least one of the contacts to get results',
        id: 'DeliveryBlock.hint'
    },
    title: {
        defaultMessage: 'Contacts to send test results',
        id: 'DeliveryBlock.title'
    }
});
