export const ResultStatus = {
    /**
     * Готов
     */
    COMPLETED: 'COMPLETED',

    /**
     * Не готов
     */
    NOT_COMPLETED: 'NOT_COMPLETED',

    /**
     * Частично готов
     */
    PARTIAL: 'PARTIAL'
};

export const EpguDeliveryStatuses = {
    /**
     * Успешно отправлено в ЕПГУ, не требуется повторная отправка
     */
    ALREADY_SENT: 'ALREADY_SENT',

    /**
     * Не отправлено, либо частично отправлено в ЕПГУ, может быть отправлено или отправлено повторно
     */
    CAN_BE_SENT: 'CAN_BE_SENT',
    /**
     * Не подлежит отправке в ЕПГУ (нет ковидных тестов в заявке).
     */
    NOT_FOR_EPGU: 'NOT_FOR_EPGU'
};
