import { defineMessages } from 'react-intl';

export default defineMessages({
    formLabel: {
        defaultMessage: 'Specify the number of points to be debited:',
        id: 'LoyaltySummary.formLabel'
    },
    notice: {
        defaultMessage: 'No more than 50% of the total order value',
        id: 'LoyaltySummary.notice'
    },
    title: {
        defaultMessage: 'Points balance',
        id: 'LoyaltySummary.title'
    },
    writeOff: {
        defaultMessage: 'Available for write-off',
        id: 'LoyaltySummary.writeOff'
    }
});
