import { defineMessages } from 'react-intl';

export default defineMessages({
    cardDescription: {
        defaultMessage: 'Come up with a new password',
        id: 'ReactivationPage.cardDescription'
    },
    cardTitle: {
        defaultMessage: 'The patient has been found, come up with a new password',
        id: 'ReactivationPage.cardTitle'
    },
    pageTitle: {
        defaultMessage: 'Account activation',
        id: 'ReactivationPage.pageTitle'
    }
});
