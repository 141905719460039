import { defineMessages } from 'react-intl';

export default defineMessages({
    comment: {
        defaultMessage: 'Comment',
        id: 'TestResultsTable.comment'
    },
    inProgressText: {
        defaultMessage: 'Processing',
        id: 'TestResultsTable.inProgressText'
    },
    noResults: {
        defaultMessage: 'There are no results.',
        id: 'TestResultsTable.noResults'
    },
    referenceValues: {
        defaultMessage: 'The reference values',
        id: 'TestResultsTable.referenceValues'
    },
    result: {
        defaultMessage: 'Result',
        id: 'TestResultsTable.result'
    },
    study: {
        defaultMessage: 'Study',
        id: 'TestResultsTable.study'
    }
});
