export const ikbCashbackType = {
    /**
     * Отдельные услуги
     */
    INDIVIDUAL_SERVICES: 'INDIVIDUAL_SERVICES',

    /**
     * Готовые решения
     */
    READY_SOLUTIONS: 'READY_SOLUTIONS',

    /**
     * Регулярный кешбэк
     */
    REGULAR_CASHBACK: 'REGULAR_CASHBACK'
};
