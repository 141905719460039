import { useAppSelector } from 'app/Hooks';
import GroupSwitch, { Button } from 'components/groupswitch/GroupSwitch';
import { OnlinePaymentType, PaymentType } from 'constants/paymentType';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { onlinePaymentTypesSelector } from 'redux/cart/selectors';
import { chosenOfficeSelector } from 'redux/offices/selectors';
import { OnlinePayment, Payment as TPayment } from 'types/common';

import alfaPay from '../assets/alfaPay.svg';
import card from '../assets/card.svg';
import office from '../assets/office.svg';
import sberPay from '../assets/sberPay.svg';
import styles from './Payment.module.css';
import messages from './PaymentMessages';
const { OFFICE } = PaymentType;
const { CLOUD_PAYMENTS, PSB, SBER_PAY, ALFA_PAY, LOYALTY } = OnlinePaymentType;

type TProps = {
    paymentTypes: TPayment[];
    onlinePaymentTypes: OnlinePayment[];
    selectedPaymentType: TPayment | OnlinePayment;
    handleSelectPaymentType: (value: TPayment) => void;
};

const Payment: FC<TProps> = ({ paymentTypes, selectedPaymentType, handleSelectPaymentType }) => {
    const onlinePaymentTypes = useAppSelector(onlinePaymentTypesSelector);
    const chosenOffice = useAppSelector(chosenOfficeSelector);
    const { formatMessage } = useIntl();

    if (selectedPaymentType === LOYALTY) {
        return null;
    }

    return (
        <div>
            <h2 className={styles.paymentBlockTitle}>{formatMessage(messages.title)}</h2>
            <GroupSwitch onChange={(value) => handleSelectPaymentType(value)}>
                <Button
                    active={onlinePaymentTypes[CLOUD_PAYMENTS]}
                    icon={card}
                    value={CLOUD_PAYMENTS}
                    title={formatMessage(messages.online)}
                    description={formatMessage(messages.onlineDescription)}
                    selected={selectedPaymentType === CLOUD_PAYMENTS}
                />
                <Button
                    active={onlinePaymentTypes[PSB]}
                    icon={card}
                    value={PSB}
                    title={formatMessage(messages.online)}
                    description={formatMessage(messages.onlineDescription)}
                    selected={selectedPaymentType === PSB}
                />
                <Button
                    active={onlinePaymentTypes[SBER_PAY]}
                    icon={sberPay}
                    title={formatMessage(messages.sberPay)}
                    value={SBER_PAY}
                    description={formatMessage(messages.sberPayDescription)}
                    selected={selectedPaymentType === SBER_PAY}
                />
                <Button
                    active={onlinePaymentTypes[ALFA_PAY]}
                    icon={alfaPay}
                    title={formatMessage(messages.alfaPay)}
                    value={ALFA_PAY}
                    description={formatMessage(messages.alfaPayDescription)}
                    selected={selectedPaymentType === ALFA_PAY}
                />
                <Button
                    active={paymentTypes.includes(OFFICE)}
                    icon={office}
                    value={OFFICE}
                    title={formatMessage(messages.office)}
                    description={formatMessage(messages.officeDescription, { officeName: chosenOffice?.office?.name })}
                    selected={selectedPaymentType === OFFICE}
                />
            </GroupSwitch>
        </div>
    );
};

export default memo(Payment);
