import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        defaultMessage: 'Cancel',
        id: 'ChangeProductsCartModal.cancel'
    },
    close: {
        defaultMessage: 'Close',
        id: 'ChangeProductsCartModal.close'
    },
    codeError: {
        defaultMessage: 'The code is invalid or our of date',
        id: 'ChangeProductsCartModal.codeError'
    },
    merge: {
        defaultMessage: 'Merge',
        id: 'ChangeProductsCartModal.merge'
    },
    replace: {
        defaultMessage: 'Replace',
        id: 'ChangeProductsCartModal.replace'
    },
    retry: {
        defaultMessage: 'Retry',
        id: 'ChangeProductsCartModal.retry'
    },
    serverError: {
        defaultMessage: 'An error occurred. Please, try again.',
        id: 'ChangeProductsCartModal.serverError'
    },
    title: {
        defaultMessage: 'Confirm the replacement of the current bucket?',
        id: 'ChangeProductsCartModal.title'
    }
});
