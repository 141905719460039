import { useAppSelector } from 'app/Hooks';
import commonMessages from 'messages/CommonMessages';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import React, { FC, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { solutionsSelector } from 'redux/loyaltyIKB/selectors';
import { IKBProduct } from 'types/common';

import styles from './IKBProductDetailsPage.module.css';

const IKBProductDetailsPage: FC = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const { formatMessage } = useIntl();
    const solutions = useAppSelector(solutionsSelector);

    useEffect(() => {
        if (solutions && solutions.length <= 0) {
            navigate('/loyalty');
        }
    }, [solutions]);

    const renderContentColumn = (product: IKBProduct) => {
        return (
            <div key={product.productId} className={styles.card}>
                <div className={styles.infoBlock}>
                    <h3 className={styles.title}>{product.name}</h3>
                    <p className={styles.description} dangerouslySetInnerHTML={{ __html: product.description }} />
                </div>
            </div>
        );
    };

    const renderContent = () => {
        const product = solutions.flatMap((solution) => solution.products).find((product) => product.productId === productId);

        if (product) {
            return (
                <Fragment>
                    <IKBNavigationBar
                        title={formatMessage(commonMessages.numberFormat, {
                            number: product.article
                        })}
                        back={true}
                    />
                    <div className={styles.container}>
                        <div className={styles.cards}>{renderContentColumn(product)}</div>
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    };

    return renderContent();
};

export default IKBProductDetailsPage;
