import { createSelector } from 'reselect';
import { Store } from 'types/store';

export const authSelector = (state: Store.App.State) => state.auth;

export const loginSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.login
    };
});

export const loginConfirmSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.loginConfirm
    };
});

export const loginPasswordSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.loginPassword
    };
});

export const registrationSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.registration
    };
});

export const recoverySelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.recovery
    };
});

export const recoveryConfirmByCodeSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.recoveryConfirmByCode
    };
});

export const recoveryConfirmByINZSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.recoveryConfirmByINZ
    };
});

export const recoverySetPasswordSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.recoverySetPassword
    };
});

export const resetPasswordSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.resetPassword
    };
});

export const reactivationSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.reactivation
    };
});

export const reactivationConfirmSelector = createSelector([authSelector], (auth) => {
    return {
        ...auth.reactivationConfirm
    };
});

export const logoutSelector = createSelector([authSelector], (auth) => ({
    error: auth.logout?.error,
    inProgress: auth.logout?.loading,
    success: auth.logout?.success
}));
