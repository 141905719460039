//@ts-nocheck
import * as loyaltyClient from 'client/LoyaltyClient';
import { request } from 'redux/helpers';
import { SET_CASHBACK_STATUS } from 'redux/loyaltyIKB/actions';
import { APPLY_COUPON, CLEAR_ALL, FETCH, GET_CASHBACK_STATUS } from 'redux/loyaltyStatus/actions';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

function* fetch(action) {
    return yield request(FETCH, [loyaltyClient.fetchLoyaltyStatus, action.meta], action.meta);
}

function* clear() {
    yield put(CLEAR_ALL.success());
}

function* applyCoupon(action) {
    yield request(APPLY_COUPON, [loyaltyClient.applyCoupon, action.meta], action.meta);
}

function* getCashbackStatus(action) {
    const { territory } = yield select(currentTerritorySettingsSelector);
    const result = yield* fetch({
        meta: {
            patientId: action.meta,
            territory
        }
    });
    const loyaltyStatus = result?.data;
    if (loyaltyStatus) {
        yield put(SET_CASHBACK_STATUS.base(loyaltyStatus?.bonusProgramStatus?.bonusStatus));
    }
}

export default function* loyaltyStatusSagas() {
    yield all([
        takeEvery(FETCH.BASE, fetch),
        takeLatest(CLEAR_ALL.BASE, clear),
        takeLatest(APPLY_COUPON.BASE, applyCoupon),
        takeLatest(GET_CASHBACK_STATUS.BASE, getCashbackStatus)
    ]);
}
