import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import iconReorder from 'page/orders/assets/details/result/iconReorder.svg';
import ActionCard from 'page/orders/component/actionCard/ActionCard';
import ReorderModal from 'page/orders/component/reorderModal/ReorderModal';
import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { logout } from 'redux/auth/actions';
import { authSelector } from 'redux/auth/selectors';
import { clearCart } from 'redux/cart/actions';
import { INVITRO_CART_COOKIE_KEY } from 'redux/cart/constants';
import { cartDataProductsSelector } from 'redux/cart/selectors';
import { CITY_GUID_COOKIE_KEY } from 'redux/cities/constants';
import { fetchPatients } from 'redux/patient/actions';
import { patientFetchAllSelector, sortedByMainPatientsSelector } from 'redux/patient/selectors';
import { checkReorderAvailability } from 'redux/result/actions';
import { resultSelector } from 'redux/result/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { currentSelector } from 'redux/user/selectors';
import cookieService from 'services/CookieService';
import { Localization } from 'types/common';
import { getBackRefUrl, goTo } from 'utils/browserUtils';
import { fullName } from 'utils/patientUtils';

import styles from './ReorderControl.module.css';
import messages from './ReorderControlMessages';

const ReorderControl: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const location: any = useLocation();
    const [searchParams] = useSearchParams();
    const user = useAppSelector(currentSelector);
    const { authenticated } = useAppSelector(authSelector);
    const afterrefister = searchParams.get('afterrefister');
    const patients = useAppSelector(sortedByMainPatientsSelector);
    const patientFetchAll = useAppSelector(patientFetchAllSelector);
    const cartProducts = useAppSelector(cartDataProductsSelector);
    const territorySettings = useAppSelector(currentTerritorySettingsSelector);
    const locale: Localization = useAppSelector((state) => state.i18n.currentLocale);
    const { search, reorderAvailable, checkReorderAvailable } = useAppSelector(resultSelector);
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    const [showNoPatientModal, setShowNoPatientModal] = useState(false);
    const [showReorderModal, setShowReorderModal] = useState<boolean>(false);
    const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(false);
    const [showReorderNotAvailableModal, setShowReorderNotAvailableModal] = useState(false);

    useEffect(() => {
        const inz = search.data?.result?.inz;

        if (inz) {
            dispatch(
                checkReorderAvailability({
                    inz
                })
            );
        }
    }, [search]);

    useEffect(() => {
        if (patientFetchAll.success && afterrefister && hasAuthorized() && hasPatient() && hasAvailable()) {
            setShowReorderModal(true);
        }
    }, [patientFetchAll, authenticated, patients, reorderAvailable]);

    useEffect(() => {
        if (checkReorderAvailable.success && reorderAvailable && user) {
            dispatch(fetchPatients({ userId: user.id }));
        }
    }, [checkReorderAvailable, user]);

    const hasAuthorized = () => {
        setShowNotAuthorizedModal(!authenticated);
        return authenticated;
    };

    const hasPatient = () => {
        const patient = search.data?.result?.patient;
        const findPatient = patients.find((patientItem) => patientItem.contactId === patient.id);
        setShowNoPatientModal(!findPatient && reorderAvailable && authenticated);
        return findPatient && reorderAvailable && authenticated;
    };

    const hasAvailable = () => {
        setShowReorderNotAvailableModal(!reorderAvailable && authenticated);
        return reorderAvailable && authenticated;
    };

    const handleReorder = () => {
        setShowReorderModal(false);
        setIsRedirecting(true);
        if (cartProducts.length > 0) {
            dispatch(clearCart());
        }
        const { orderId, officeId, cityId } = search.data;
        let cart: Record<string, unknown> = {};
        cart = { officeId, orderId };
        cookieService.set(CITY_GUID_COOKIE_KEY, cityId, null);
        cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart), null);
        goTo(territorySettings.reorderProductsLink.uri);
    };

    const renderModal = ({
        show,
        title,
        content,
        textButton,
        onClick,
        onClose
    }: {
        show: boolean;
        title: string;
        content: ReactNode;
        textButton: string;
        onClick: () => void;
        onClose: () => void;
    }) => {
        return (
            <Modal closable={true} onClose={onClose} show={show} className={styles.modal}>
                <div className={styles.container}>
                    <Fragment>
                        <div className={styles.top}>
                            <h3 className={styles.title}>{title}</h3>
                        </div>
                        <div className={styles.content}>{content}</div>
                        <Button onClick={onClick}>{textButton}</Button>
                    </Fragment>
                </div>
            </Modal>
        );
    };

    return (
        <Fragment>
            <ActionCard
                data={null}
                newFeature={true}
                icon={iconReorder}
                text={messages.reorder[locale]}
                loading={checkReorderAvailable.loading}
                show={reorderAvailable || checkReorderAvailable.loading}
                onClick={() => {
                    if (hasAuthorized() && hasPatient() && hasAvailable()) {
                        setShowReorderModal(true);
                    }
                }}
            />

            {renderModal({
                content: formatMessage(messages.reorderNoticeAuth, {
                    fullName: fullName(search.data?.result?.patient),
                    span: (...chunks: ReactNode[]) => <span>{chunks}</span>
                }),
                onClick: () => navigate(`/login?backRefUrl=${getBackRefUrl(location, true)}`),
                onClose: () => setShowNotAuthorizedModal(false),
                show: showNotAuthorizedModal,
                textButton: messages.loginOrRegister[locale],
                title: messages.makingReorder[locale]
            })}

            {renderModal({
                content: formatMessage(messages.reorderNoticePatient, {
                    fullName: fullName(search.data?.result?.patient),
                    span: (...chunks: ReactNode[]) => <span>{chunks}</span>
                }),
                onClick: () => {
                    dispatch(logout());
                    navigate(`/login?backRefUrl=${getBackRefUrl(location, true)}`);
                },
                onClose: () => setShowNoPatientModal(false),
                show: showNoPatientModal,
                textButton: messages.loginOrRegister[locale],
                title: messages.makingReorder[locale]
            })}

            {renderModal({
                content: messages.reorderNoticeNotAvailable[locale],
                onClick: () => setShowReorderNotAvailableModal(false),
                onClose: () => setShowReorderNotAvailableModal(false),
                show: showReorderNotAvailableModal,
                textButton: messages.close[locale],
                title: messages.reorderTitleNotAvailable[locale]
            })}

            <LoaderModal show={isRedirecting} />
            <ReorderModal handleReorder={handleReorder} onClose={() => setShowReorderModal(false)} showModal={showReorderModal} />
        </Fragment>
    );
};

export default ReorderControl;
