import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import commonMessages from 'messages/CommonMessages';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import confirmationContactIcon from 'page/loyalty/ikb/confirmation/assets/confirmationContactIcon.svg';
import { MOBILE_PHONE } from 'page/patients/constants/constants';
import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { clearSendActivationPin, sendActivationPin, setActivation } from 'redux/loyaltyIKB/actions';
import { activationSelector, sendActivationPinSelector } from 'redux/loyaltyIKB/selectors';
import { fetchPatients } from 'redux/patient/actions';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { currentSelector } from 'redux/user/selectors';
import { IKBLoyalty, IPatient } from 'types/common';
import { getLoyaltyErrorText } from 'utils/messageUtils';
import { getPhones } from 'utils/patientUtils';
import { validatePhoneNumber } from 'utils/validators';

import styles from './IKBPhoneEntryPage.module.css';
import messages from './IKBPhoneEntryPageMessages';

type TProps = {
    selected: IPatient;
    loyaltyStatus: IKBLoyalty.Status;
    territory: string;
};

type TState = {
    phones: string[] | [];
    phone: string;
    error: null | { errorCode: number; message: string };
};

const IKBPhoneEntryPage: FC<TProps> = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const territorySettings = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { formatMessage } = useIntl();
    const activation = useAppSelector((state) => activationSelector(state));
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const user = useAppSelector((state) => currentSelector(state));
    const sendActivationPinResponse = useAppSelector((state) => sendActivationPinSelector(state));

    const [state, setState] = useState<TState>({
        error: null,
        phone: '',
        phones: []
    });

    useEffect(() => {
        if (!activation || !activation.accountNumber) {
            navigate('/loyalty');
        } else {
            if (user) {
                dispatch(fetchPatients({ userId: user.id }));
            }
        }
        return () => {
            dispatch(clearSendActivationPin());
        };
    }, []);

    useEffect(() => {
        if (sendActivationPinResponse?.success) {
            goToCodeEntryPage();
        }
    }, [sendActivationPinResponse?.success]);

    useEffect(() => {
        if (sendActivationPinResponse?.error) {
            setState((prevState) => ({ ...prevState, error: sendActivationPinResponse.error }));
        }
    }, [sendActivationPinResponse?.error]);

    useEffect(() => {
        const phones = getPhones(selectedPatient, MOBILE_PHONE);
        const phone = phones.length === 1 ? phones[0] : '';
        setState((prevState) => ({ ...prevState, phone, phones: getPhones(selectedPatient, MOBILE_PHONE) }));
    }, [selectedPatient?.id]);

    const goToCodeEntryPage = () => {
        dispatch(
            setActivation({
                ...activation,
                phone: state.phone
            })
        );
        navigate('/loyalty/ikb/cashback/activation/code');
    };

    const handleSendActivationPin = () => {
        dispatch(
            sendActivationPin({
                number: activation.accountNumber,
                patientId: selectedPatient.id,
                phone: state.phone,
                territory
            })
        );
    };

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({ ...prevState, phone: event.target.value }));
    };

    const createNotice = (text: ReactNode[]) => {
        const { ikbLoyaltyRulesLink } = territorySettings;

        return (
            <a href={ikbLoyaltyRulesLink?.uri} target='_blank' rel='noopener noreferrer'>
                {text}
            </a>
        );
    };

    const renderError = () => {
        const { error } = state;
        if (error) {
            return error ? <AlertWarning content={getLoyaltyErrorText(intl, error.errorCode, error.message)} /> : null;
        }
        return null;
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.cashbackActivation)} back={true} />
            <div className={styles.container}>
                <div
                    className={classNames({
                        [styles.card]: true,
                        [styles.existsContacts]: state.phones
                    })}
                >
                    <div className={styles.top}>
                        <div className={styles.info}>{formatMessage(messages.info)}</div>
                        <div className={styles.contacts}>
                            {state.phones.map((phone: string, i: number) => (
                                <div key={i} className={styles.contact}>
                                    <h6>{phone}</h6>
                                    <button onClick={() => setState((prevState) => ({ ...prevState, phone }))}>
                                        <img alt='' src={confirmationContactIcon} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        {renderError()}
                        <div className={styles.inner}>
                            <div
                                className={classNames({
                                    [styles.phone]: true,
                                    [styles.error]: !validatePhoneNumber(state.phone),
                                    [styles.valid]: validatePhoneNumber(state.phone)
                                })}
                            >
                                <label>{formatMessage(messages.phone)}</label>
                                <Input
                                    value={state.phone}
                                    disabled={true}
                                    onChange={(event) => handleChangePhone(event)}
                                    placeholder={formatMessage(messages.placeholder)}
                                />
                            </div>
                            <Button disabled={!validatePhoneNumber(state.phone) || sendActivationPinResponse.loading} onClick={handleSendActivationPin}>
                                {formatMessage(commonMessages.confirm)}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.notice}>
                        {formatMessage(commonMessages.IKBNoticeRules, {
                            link: (...chunks: ReactNode[]) => createNotice(chunks),
                            span: (...chunks: ReactNode[]) => <span>{chunks}</span>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(IKBPhoneEntryPage);
