import mastercard from './mastercard.svg';
import mir from './mir.svg';
import pci from './pci.svg';
import visa from './visa.svg';

export const paymentSystemsIcons = [
    {
        alt: 'mir',
        id: 1,
        src: mir
    },
    {
        alt: 'pci',
        id: 2,
        src: pci
    },
    {
        alt: 'mastercard',
        id: 3,
        src: mastercard
    },
    {
        alt: 'visa',
        id: 4,
        src: visa
    }
];
