//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { CLEAR, FETCH } from 'redux/orderDetails/actions';

const initialState = {
    entities: {},
    fetch: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH.STARTED:
        case FETCH.SUCCEEDED:
        case FETCH.ERRORED: {
            const { orderId } = action.meta;
            const entities = state.entities;
            if (action.type === FETCH.SUCCEEDED) {
                entities[orderId] = action.payload.data;
            }

            return {
                ...state,
                entities,
                fetch: basicReducer(state.fetch, action)
            };
        }
        case CLEAR.SUCCEEDED: {
            const { orderId } = action.meta;
            const entities = { ...state.entities };
            delete entities[orderId];
            return {
                ...state,
                entities
            };
        }
        default:
            return state;
    }
}
