import { defineMessages } from 'react-intl';

export default defineMessages({
    error: {
        defaultMessage: 'Wrong code entered',
        id: 'IKBCashbackPhoneEntryPage.error'
    },
    info: {
        defaultMessage: 'To continue, enter the phone number that you want to link to your bonus account',
        id: 'IKBCashbackPhoneEntryPage.info'
    },
    newPhone: {
        defaultMessage: 'Add new phone number',
        id: 'IKBCashbackPhoneEntryPage.newPhone'
    },
    phone: {
        defaultMessage: 'Phone number',
        id: 'IKBCashbackPhoneEntryPage.phone'
    },
    placeholder: {
        defaultMessage: '+7 999 999 99 99',
        id: 'IKBCashbackPhoneEntryPage.placeholder'
    },
    sendCode: {
        defaultMessage: 'Resend code',
        id: 'IKBCashbackPhoneEntryPage.sendCode'
    }
});
