/**
 * Перечисление типов коммуникаций
 */
export const CommunicationType = {
    /**
     * Feedback
     */
    CONTACT_LINK: 'CONTACT_LINK',

    /**
     * Email
     */
    EMAIL: 'EMAIL',

    /**
     * Телефон
     */
    PHONE: 'PHONE',

    /**
     * WhatsApp
     */
    WHATS_APP: 'WHATS_APP'
};
