import viewSvg from 'components/view/view.svg';
import commonMessages from 'messages/CommonMessages';
import ActionCard from 'page/orders/component/actionCard/ActionCard';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';

type TProps = {
    text: string;
    onClick: () => void;
};

const ButtonView: FC<TProps> = ({ text, ...rest }) => {
    const { formatMessage } = useIntl();
    return <ActionCard icon={`${viewSvg}#icon-view`} text={text || formatMessage(commonMessages.view)} {...rest} />;
};

export default memo(ButtonView);
