//@ts-nocheck
import * as loyaltyClient from 'client/LoyaltyClient';
import { ACTIVATE_CHECKUP, FETCH_CHECKUPS } from 'redux/checkups/actions';
import { request } from 'redux/helpers';
import { all, takeLatest } from 'redux-saga/effects';

function* fetchCheckups(action) {
    yield request(FETCH_CHECKUPS, [loyaltyClient.fetchCheckups, action.meta], action.meta);
}

function* activateCheckup(action) {
    yield request(ACTIVATE_CHECKUP, [loyaltyClient.activateCheckup, action.meta]);
}

export default function* checkupsSagas() {
    yield all([takeLatest(FETCH_CHECKUPS.BASE, fetchCheckups), takeLatest(ACTIVATE_CHECKUP.BASE, activateCheckup)]);
}
