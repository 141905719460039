//@ts-nocheck
import FormField from 'components/form/field/FormField';
import InputValid from 'components/input/valid/InputValid';
import isEqual from 'lodash.isequal';
import commonMessages from 'messages/CommonMessages';
import Popup from 'page/cart/components/cartSummary/components/popup/Popup';
import styles from 'page/cart/confirmation/deliveryBlock/DeliveryBlock.module.css';
import messages from 'page/cart/confirmation/deliveryBlock/DeliveryBlockMessages';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UPDATE_DELIVERY_DATA } from 'redux/cart/actions';
import { getOnlineRegistrationData } from 'redux/patient/selectors';
import FormHelper from 'utils/FormHelper';
import { validateEmail, validateNotBlank, validatePhoneNumber } from 'utils/validators';

class DeliveryBlock extends React.Component {
    constructor(props) {
        super(props);
        this.today = new Date();
        this.fieldNames = ['onlineRegistrationData.delivery.codeWord', 'onlineRegistrationData.delivery.email', 'onlineRegistrationData.delivery.phone'];
        this.state = this.initialState();
        this.validators = {
            'onlineRegistrationData.delivery.codeWord': validateNotBlank,
            'onlineRegistrationData.delivery.email': (email) =>
                (!email && validateNotBlank(this.state['onlineRegistrationData.delivery.phone'])) || validateEmail(email),
            'onlineRegistrationData.delivery.phone': (phone) =>
                (!phone && validateNotBlank(this.state['onlineRegistrationData.delivery.email'])) || validatePhoneNumber(phone)
        };
        this.formHelper = new FormHelper(this, this.validators);
    }

    initialState() {
        const state = {
            errors: {}
        };
        this.fieldNames.forEach((name) => (state[name] = ''));
        return state;
    }

    componentDidMount() {
        if (this.props.deliveryData.errors) {
            this.setState(this.props.deliveryData);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { creationError, updateDeliveryData } = this.props;
        if (!isEqual(prevState, this.state)) {
            updateDeliveryData({
                data: {
                    ...this.state,
                    'onlineRegistrationData.delivery.phone': this.state['onlineRegistrationData.delivery.phone'],
                    valid: this.validate()
                }
            });
        }

        if (prevProps.creationError !== creationError && creationError?.errors) {
            const errorsState = {};
            const noDelivery = creationError.errors.some(
                (error) => error.field === 'onlineRegistrationData' || error.field === 'onlineRegistrationData.delivery'
            );
            creationError.errors.forEach((error) => (errorsState[error.field] = true));
            if (noDelivery) {
                this.fieldNames.forEach((name) => (errorsState[name] = true));
            }

            this.setState({ errors: errorsState });
        }

        const data = this.props.patientOnlineRegistrationData;
        if (data.delivery && !isEqual(prevProps.patientOnlineRegistrationData, data)) {
            const delivery = {};
            for (const [key, value] of Object.entries(data.delivery)) {
                delivery[`onlineRegistrationData.delivery.${key}`] = value || '';
            }
            this.setState(delivery);
        }
    }

    render() {
        const { intl } = this.props;
        const codeWord = this.props.patientOnlineRegistrationData.delivery && this.props.patientOnlineRegistrationData.delivery.codeWord;

        return (
            <div>
                <h2 className={styles.title}>
                    <FormattedMessage {...messages.title} />
                </h2>
                {!this.isValid() ? <Popup text={intl.formatMessage(messages.hint)} position='top' className={styles.popup} /> : null}
                <form>
                    {codeWord ? null : (
                        <FormField label={intl.formatMessage(messages.codeWord)}>
                            <InputValid
                                name='onlineRegistrationData.delivery.codeWord'
                                value={this.state['onlineRegistrationData.delivery.codeWord']}
                                onChange={(event) => this.formHelper.handleValueChange(event)}
                                error={this.state.errors['onlineRegistrationData.delivery.codeWord']}
                                valid={this.isValid('onlineRegistrationData.delivery.codeWord')}
                            />
                        </FormField>
                    )}
                    <FormField label={intl.formatMessage(commonMessages.email)}>
                        <InputValid
                            name='onlineRegistrationData.delivery.email'
                            value={this.state['onlineRegistrationData.delivery.email']}
                            placeholder={intl.formatMessage(commonMessages.emailPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.delivery.email']}
                            valid={this.isValid('onlineRegistrationData.delivery.email')}
                        />
                    </FormField>
                    <FormField label={intl.formatMessage(commonMessages.phone)}>
                        <InputValid
                            name='onlineRegistrationData.delivery.phone'
                            value={this.state['onlineRegistrationData.delivery.phone']}
                            placeholder={intl.formatMessage(commonMessages.phonePlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.delivery.phone']}
                            valid={this.isValid('onlineRegistrationData.delivery.phone')}
                        />
                    </FormField>
                </form>
            </div>
        );
    }

    isValid(fieldName) {
        if (fieldName) {
            return this.formHelper.isValid([fieldName]);
        } else {
            return this.formHelper.isValid(this.fieldNames);
        }
    }

    validate() {
        return this.formHelper.validate(this.fieldNames);
    }
}

DeliveryBlock.propTypes = {
    creationError: PropTypes.any,
    deliveryData: PropTypes.object,
    patientOnlineRegistrationData: PropTypes.object,
    updateDeliveryData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    const create = state.orders.create;
    return {
        creationError: create.error,
        deliveryData: state.cart.deliveryData,
        patientOnlineRegistrationData: getOnlineRegistrationData(state)
    };
};

const mapDispatchToProps = {
    updateDeliveryData: UPDATE_DELIVERY_DATA.base
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeliveryBlock));
