import classNames from 'classnames/bind';
import IconWarning from 'components/icon/warning/IconWarning';
import Timer from 'components/timer/Timer';
import commonMessages from 'messages/CommonMessages';
import React, { FC, memo, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import styles from './AlertWarning.module.css';
const cx = classNames.bind(styles);

type TProps = {
    show?: boolean;
    className?: string;
    content: string | ReactElement | ReactNode;
    timeToResend?: number;
    onEnd?: () => void;
};

const AlertWarning: FC<TProps> = ({ content, className, show = true, timeToResend, onEnd }) => {
    const { formatMessage } = useIntl();

    return show && content ? (
        <div className={cx(styles.error, className)}>
            <IconWarning />
            <div>
                {content}
                {timeToResend && (
                    <Timer className={styles.timer} seconds={timeToResend} onEnd={onEnd}>
                        {({ formatted }: never) => {
                            return formatMessage(commonMessages.afterTime, { time: formatted });
                        }}
                    </Timer>
                )}
            </div>
        </div>
    ) : null;
};

export default memo(AlertWarning);
