//@ts-nocheck
import { SET_SCROLLBAR_POSITION } from 'redux/headerBottomMenu/actions';

const initialState = {
    scrollbarPosition: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SCROLLBAR_POSITION.BASE: {
            const params = action?.meta;
            return {
                ...state,
                scrollbarPosition: params
            };
        }
        default:
            return state;
    }
}
