import { useAppSelector } from 'app/Hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authSelector } from 'redux/auth/selectors';

const useNavigateRoot = (options: { isUnauthorized: boolean } = { isUnauthorized: false }) => {
    const navigate = useNavigate();
    const { authenticated } = useAppSelector(authSelector);

    useEffect(() => {
        if (options.isUnauthorized && !authenticated) {
            navigate('/', { replace: true });
        }
    }, [authenticated]);

    return {
        navigateRoot: (condition?: boolean) => {
            if (condition) {
                navigate('/', { replace: true });
            }
        }
    };
};

export default useNavigateRoot;
