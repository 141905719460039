import { useAppDispatch, useAppSelector } from 'app/Hooks';
import cn from 'classnames/bind';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/button/Button';
import ButtonLinkSvg from 'components/button/link/svg/ButtonLinkSvg';
import Hint from 'components/hint/Hint';
import Input from 'components/input/Input';
import commonMessages from 'messages/CommonMessages';
import closeSvg from 'page/orders/assets/componentImg/close.svg';
import React, { FC, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { setSaleAction } from 'redux/cart/actions';
import {
    cartAnalyzesSelector,
    cartConfirmationErrorSelector,
    commonCalculationDataSelector,
    couponsDataSelector,
    saleActionSelector
} from 'redux/cart/selectors';
import { clearDataState } from 'redux/orders/actions';
import { Coupon } from 'types/common';

import styles from './Coupons.module.css';
import messages from './CouponsMessages';
const classNames = cn.bind(styles);

type TProps = {
    handleCoupons: (data?: Coupon[]) => void;
};

const Coupons: FC<TProps> = ({ handleCoupons }) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const calculationData = useAppSelector(commonCalculationDataSelector);
    const saleAction = useAppSelector(saleActionSelector);
    const couponsData = useAppSelector(couponsDataSelector);
    const cartAnalyzes = useAppSelector(cartAnalyzesSelector);
    const cartConfirmationError = useAppSelector(cartConfirmationErrorSelector);
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [currentCoupon, setCurrentCoupon] = useState<string>('');
    const [notAppliedCurrentCoupon, setNotAppliedCurrentCoupon] = useState(false);

    useEffect(() => {
        setCoupons(couponsData.filter((coupon: Coupon) => coupon.number) || []);
    }, [couponsData]);

    useEffect(() => {
        const isAppliedCoupon = coupons?.find((el: Coupon) => el.number === currentCoupon)?.applied;
        if (isAppliedCoupon) {
            setCurrentCoupon('');
        }
    }, [coupons]);

    useEffect(() => {
        const getCurrentCoupon = coupons?.filter((el: Coupon) => el.number === currentCoupon)[0];
        if (!getCurrentCoupon?.applied && getCurrentCoupon?.number) {
            setNotAppliedCurrentCoupon(true);
        } else {
            setNotAppliedCurrentCoupon(false);
        }

        handleClearDataState();
    }, [coupons, currentCoupon]);

    const renderCoupons = () => {
        return coupons.map((coupon, index) => {
            const { number, applied, errorDescription } = coupon;

            return (
                number &&
                number !== currentCoupon && (
                    <div key={number} className={styles.appliedCoupons}>
                        <div className={styles.appliedCoupon}>
                            <div className={classNames({ coupon: true, inapplicable: !applied })}>
                                {formatMessage(messages.couponNumber, { coupon: number })}
                            </div>
                            <ButtonLinkSvg className={styles.closeBtn} svg={`${closeSvg}#icon-close`} onClick={() => removeCoupon(index)} />
                        </div>
                        {!applied && (
                            <div key={number} className={styles.inapplicableHint}>
                                {formatMessage(commonMessages.couponNotApplicable)}
                                {errorDescription && <Hint className={styles.hint}>{errorDescription}</Hint>}
                            </div>
                        )}
                    </div>
                )
            );
        });
    };

    const removeCoupon = (index: number) => {
        const data = [...coupons?.filter((_el, idx) => idx !== index)];
        handleCoupons(data);
    };

    const handleSetSaleAction = () => {
        const coupons = couponsData.filter((coupon: Coupon) => coupon.number !== currentCoupon);
        dispatch(setSaleAction({ calculationData, data: { coupons } }));
    };

    const applyCoupon = () => {
        const data = [
            { number: currentCoupon },
            ...coupons
                ?.filter((coupon: Coupon) => coupon.number !== currentCoupon)
                .map((item: Coupon) => {
                    return {
                        number: item?.number
                    };
                })
        ];
        handleCoupons(data);
    };

    const renderError = (couponNumber: string) => {
        const coupon = couponsData.find((el: Coupon) => el.number === couponNumber);

        if (!coupon?.applied && coupon?.errorDescription) {
            return <AlertWarning className={styles.errorText} content={coupon.errorDescription} />;
        }
    };

    const handleClearDataState = () => {
        if (cartConfirmationError) {
            dispatch(clearDataState());
        }
    };

    return (
        <>
            <div className={styles.wrapperForm}>
                {!cartAnalyzes.isPrepaid && (
                    <>
                        <div className={styles.labelWrapper}>
                            <label>{formatMessage(messages.formLabel)}</label>
                            <Hint className={styles.hint}>{formatMessage(messages.couponHint)}</Hint>
                        </div>
                        <Input
                            type={'text'}
                            name={'coupon'}
                            value={currentCoupon}
                            placeholder={formatMessage(messages.couponPlaceholder)}
                            className={classNames({ error: notAppliedCurrentCoupon, input: true })}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (!saleAction && event.target.value.length < 1) {
                                    handleCoupons();
                                }
                                if (notAppliedCurrentCoupon) {
                                    handleSetSaleAction();
                                }
                                setCoupons((prevState) => {
                                    const coupons = prevState?.filter((coupon: Coupon) => coupon.number !== currentCoupon);
                                    const hasCouponsNotApplied = coupons.filter((coupon) => !coupon.applied && coupon.number).length > 0;

                                    if (notAppliedCurrentCoupon && hasCouponsNotApplied) {
                                        handleCoupons(coupons);
                                    }

                                    return coupons;
                                });
                                setCurrentCoupon(event.target.value.replace(/\s/g, ''));
                            }}
                        />
                    </>
                )}
                {renderError(currentCoupon)}
            </div>
            {currentCoupon && <Button className={styles.applyButton} text={formatMessage(messages.applyCoupon)} onClick={applyCoupon} />}
            {couponsData.length > 0 && <div>{renderCoupons()}</div>}
        </>
    );
};

export default memo(Coupons);
