//@ts-nocheck
import classNames from 'classnames/bind';
import React, { forwardRef } from 'react';

interface ButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    size?: '2x' | '3x';
    type?: 'outline' | 'link';
    inline?: boolean;
    active?: boolean;
    disable?: boolean;
    text?: string;
    className?: string;
}

const Button = forwardRef<HTMLAnchorElement, ButtonProps>(({ size, type, inline = true, active, disable, text, className, children, ...rest }, ref) => {
    return (
        <a
            className={classNames(
                size ? `ds_b_${size}` : 'ds_b_3x',
                type ? `ds_b_${type}` : '',
                inline ? `ds_b_inline` : '',
                active ? `ds_b_active` : '',
                disable ? `ds_b_disable` : '',
                className
            )}
            ref={ref}
            {...rest}
        >
            <span>{children || text}</span>
        </a>
    );
});

Button.displayName = 'Button';

export default Button;
