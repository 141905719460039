import React, { FC, memo } from 'react';

type TProps = {
    className?: string;
};

const IconSuccess: FC<TProps> = ({ className }) => {
    return (
        <svg width='65' height='65' viewBox='0 0 42 41' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            <path
                d='M39.5894 16.5C39.8585 17.7911 40 19.129 40 20.5C40 31.2696 31.2696 40 20.5 40C9.73045 40 1 31.2696 1 20.5C1 9.73045 9.73045 1 20.5 1C26.2752 1 31.4641 3.51063 35.0347 7.5'
                stroke='#0097B3'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M8.5 18L20 29.5L41 8.5' stroke='#FF6A13' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default memo(IconSuccess);
