import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import Birthday from 'page/partial/patient/card/birthday/Birthday';
import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { localeSelector } from 'redux/i18n/selectors';
import { fetchPatients, select as selectPatient } from 'redux/patient/actions';
import { sortedByMainPatientsSelector } from 'redux/patient/selectors';
import { currentSelector } from 'redux/user/selectors';
import { getGenderMessage } from 'utils/messageUtils';
import { fullName } from 'utils/patientUtils';

import patientIcon from './assets/patientIcon.svg';
import styles from './IKBPatientsPage.module.css';
import messages from './IKBPatientsPageMessages';

const IKBPatientsPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector((state) => localeSelector(state));
    const { formatMessage } = useIntl();
    const user = useAppSelector((state) => currentSelector(state));
    const patients = useAppSelector((state) => sortedByMainPatientsSelector(state));

    useEffect(() => {
        handleFetchPatients();
    }, []);

    const handleFetchPatients = () => {
        if (user) {
            dispatch(fetchPatients({ userId: user.id }));
        }
    };

    const handleSelectPatient = (id: string) => {
        dispatch(selectPatient(id));
        navigate('/loyalty');
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(messages.navigationTitle)} back={true} />
            <div className={styles.container}>
                <div className={styles.patients}>
                    {patients &&
                        patients.map((patient) => (
                            <div className={styles.card} key={patient.id}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.info}>
                                        <h3 className={styles.title}>{fullName(patient)}</h3>
                                        <span>
                                            {formatMessage(messages.birthday)}{' '}
                                            <Birthday
                                                // @ts-ignore
                                                birthday={patient.birthday}
                                                locale={locale}
                                            />
                                        </span>
                                        <span>
                                            {formatMessage(messages.gender)} {formatMessage(getGenderMessage(patient.gender))}
                                        </span>
                                    </div>
                                    <img className={styles.patientIcon} alt='' src={patientIcon} />
                                </div>
                                <Button onClick={() => handleSelectPatient(patient.id)}>{formatMessage(messages.button)}</Button>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default memo(IKBPatientsPage);
