import classNames from 'classnames';
import Button from 'components/button/Button';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/loyalty/ikb/services/IKBServicesPageMessages';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { IKBProduct, IKBService } from 'types/common';

import clearAllServiceIcon from '../services/assets/clearAllServiceIcon.svg';
import clearServiceIcon from '../services/assets/clearServiceIcon.svg';
import styles from '../services/IKBServicesPage.module.css';

type TProps = {
    categories: any;
    maxCountSelected: number;
    cashbackType: string;
    onConfirm: () => void;
    onClear?: () => void;
    onCategoryClear?: (service: IKBService) => void;
};

const CategoriesPanel: FC<TProps> = ({ categories, maxCountSelected, cashbackType, onConfirm, onClear, onCategoryClear }) => {
    const { formatMessage } = useIntl();

    const isServicesCashbackType = () => {
        return ikbCashbackType.INDIVIDUAL_SERVICES === cashbackType;
    };

    const categoryNameByType = (category: IKBProduct) => {
        return isServicesCashbackType()
            ? formatMessage(commonMessages.numberFormat, {
                  number: category.article
              })
            : category.name;
    };

    const renderCategoriesPanelContent = () => {
        if (maxCountSelected) {
            const content = [];

            for (let i = 0; i < maxCountSelected; i++) {
                const category = categories[i];

                if (category) {
                    content.push(
                        <div
                            key={i}
                            className={classNames({
                                [styles.serviceBoxPanel]: true,
                                [styles.selectedServicesBoxPanel]: true
                            })}
                        >
                            <span>{categoryNameByType(category)}</span>
                            {isServicesCashbackType() && (
                                <img
                                    onClick={() => onCategoryClear && onCategoryClear(category)}
                                    className={styles.removeServiceIcon}
                                    alt=''
                                    src={clearServiceIcon}
                                />
                            )}
                        </div>
                    );
                } else {
                    content.push(
                        <div
                            key={i}
                            className={classNames({
                                [styles.serviceBoxPanel]: true,
                                [styles.emptyServiceBoxPanel]: true
                            })}
                        >
                            {null}
                        </div>
                    );
                }
            }

            return content;
        }
    };

    return (
        <div className={styles.servicesPanelContainer}>
            <div className={styles.selectedServicesPanel}>
                <div className={styles.selectedServicesBox}>{renderCategoriesPanelContent()}</div>
                <div className={styles.servicesPanelButtons}>
                    {isServicesCashbackType() && (
                        <Button onClick={onClear} className={styles.clearAllServiceButton} disabled={!categories.length}>
                            {formatMessage(messages.clear)} <img alt='' src={clearAllServiceIcon} />
                        </Button>
                    )}
                    <Button disabled={!categories.length} onClick={onConfirm} className={styles.continueButton}>
                        {formatMessage(messages.continue)}
                        <span className={styles.maxCountSelectedServices}>0{categories.length}</span>
                        <span className={styles.countSelectedServices}> / 0{maxCountSelected}</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default memo(CategoriesPanel);
