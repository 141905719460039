import { createRequestTypes } from 'redux/helpers';

export const FETCH_BONUS_HISTORY = createRequestTypes('LOYALTY_HISTORY/FETCH_BONUS_HISTORY');
export const FETCH_DISCOUNT_HISTORY = createRequestTypes('LOYALTY_HISTORY/FETCH_DISCOUNT_HISTORY');
export const CLEAR_HISTORY = createRequestTypes('LOYALTY_HISTORY/CLEAR_BONUS_HISTORY');

export const fetchBonusHistory = (payload: { territory: string; patientId: string; dateFrom: string; dateUntil: string }) => FETCH_BONUS_HISTORY.base(payload);

export const fetchDiscountHistory = (payload: { territory: string; patientId: string; dateFrom: string; dateUntil: string }) =>
    FETCH_DISCOUNT_HISTORY.base(payload);

export const clearHistory = () => CLEAR_HISTORY.base();
