export const OfficeType = {
    /**
     * Выезд на дом.
     */
    HOME_VISIT: 'HOME_VISIT',

    /**
     * Медицинский офис.
     */
    OFFICE: 'OFFICE'
};
