import { defineMessages } from 'react-intl';

export default defineMessages({
    back: {
        defaultMessage: 'Back',
        id: 'IKBCashbackPage.back'
    },
    fetchError: {
        defaultMessage: 'An error occurred while loading data',
        id: 'IKBCashbackPage.fetchError'
    },
    notice: {
        defaultMessage: 'The rest of the services will be credited with {percent}% cashback',
        id: 'IKBCashbackPage.notice'
    },
    regularCardDescription: {
        defaultMessage: `We will increase cashback to {first}% for those who regularly take tests`,
        id: 'IKBCashbackPage.regularCardDescription'
    },
    regularCardFooterTitle: {
        defaultMessage: `Cashback is credited for all services in the orders.`,
        id: 'IKBCashbackPage.regularCardFooterTitle'
    },
    regularOrdersPerMonth: {
        defaultMessage: `{order} {order, plural, one {order} other {orders}} per month {percent}%`,
        id: 'IKBCashbackPage.regularOrdersPerMonth'
    },
    servicesDescription: {
        defaultMessage:
            'For those who want to create an individual accrual program for themselves, we give the opportunity to choose up to three separate services with a 10% cashback.',
        id: 'IKBCashbackPage.servicesDescription'
    },
    solutionsDescription: {
        defaultMessage: 'Want to get 15% cashback? Connect one of the thematic complexes that we have collected taking into account the most common needs.',
        id: 'IKBCashbackPage.solutionDescription'
    }
});
