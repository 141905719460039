//@ts-nocheck
import { createSelector } from 'reselect';

export const orderIdSelector = (state) => state.cart?.data?.orderId;

export const checkReorderAvailabilitySelector = (state) => state.cart.checkReorderAvailability;

export const reorderDataSelector = (state) => state.cart.reorderData;

export const reorderPatientSelector = (state) => state.cart.reorderData.patient;

export const reorderCalculationSelector = (state) => state.cart.calculateReorder;

export const reorderValidAnalyzesIdsSelector = createSelector(
    [reorderDataSelector],
    (reorderData) => reorderData?.orderProducts?.map((analysis) => analysis.product.id) || []
);
