import { useAppSelector } from 'app/Hooks';
import React, { FC, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cartSelector } from 'redux/cart/selectors';

import ChangeProductsCartModal from '../components/changeProductsCartModal/ChangeProductsCartModal';

const RepeatOrderPage: FC = () => {
    const navigate = useNavigate();
    const { repeatOrder, actionCart } = useAppSelector((state) => cartSelector(state));

    useEffect(() => {
        const prevProducts = repeatOrder?.prevProducts;
        if (prevProducts?.length < 1) {
            navigate('/cart');
        }
        if (!prevProducts) {
            navigate('/orders');
        }
    }, []);

    useEffect(() => {
        if (actionCart) {
            navigate('/cart');
        }
    }, [actionCart]);

    return (
        <ChangeProductsCartModal
            show={repeatOrder?.prevProducts?.length > 0}
            repeatPrevProducts={repeatOrder?.prevProducts}
            repeatOrderProducts={repeatOrder?.products}
        />
    );
};

export default memo(RepeatOrderPage);
