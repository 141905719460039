import { createSelector } from 'reselect';
import { Store } from 'types/store';
import { compareDesc } from 'utils/timeUtils';

export const bonusHistorySelector = (state: Store.App.State) => state.loyaltyHistory.bonusHistory;

export const discountHistorySelector = (state: Store.App.State) => state.loyaltyHistory.discountHistory;

export const filteredDiscountHistorySelector = createSelector([discountHistorySelector], (discountHistory) => {
    return discountHistory.sort((a: { date: string }, b: { date: string }) => compareDesc(a.date, b.date));
});
