import Loader from 'components/loader/Loader';
import Modal from 'components/modal/Modal';
import React, { FC, memo } from 'react';

import styles from './LoaderModal.module.css';

type TProps = { show: boolean };

const LoaderModal: FC<TProps> = ({ show = false }) => {
    return (
        <Modal show={show} overlayClassName={styles.overlay} className={styles.container}>
            <Loader />
        </Modal>
    );
};

export default memo(LoaderModal);
