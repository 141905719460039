//@ts-nocheck
import { createSelector } from 'reselect';
import { IPatient } from 'types/common';
import { Store } from 'types/store';
import { fullName } from 'utils/patientUtils';

export const patientSelector = (state) => state.patient;

export const patientFetchAllSelector = (state) => state.patient.fetchAll;

export const patientsByIdSelector = (state: Store.App.State) => state.patient.entities;

export const selectedPatientIdSelector = (state) => state.patient.selectedId;

export const patientByIdSelector = (state, props) => state.patient.entities[props.id];

const onlineRegistrationDataSelector = (state) => state.patient.onlineRegistrationData;

export const publishedPatientSelector = (state) => state.patient.fetchPublishedPatient;

export const patientsSelector = createSelector([patientsByIdSelector], (entities) => Object.values(entities));

export const sortedByMainPatientsSelector = createSelector([patientsSelector], (entities: IPatient[]) =>
    entities.sort((patient1, patient2) => {
        if (patient1.main !== patient2.main) {
            return patient2.main - patient1.main;
        } else {
            const patient1Name = fullName(patient1);
            const patient2Name = fullName(patient2);
            if (patient1Name < patient2Name) {
                return -1;
            } else if (patient1Name > patient2Name) {
                return 1;
            } else {
                return 0;
            }
        }
    })
);

export const mainPatientSelector = createSelector([patientsSelector], (patients) => patients.find((patient) => patient.main) as IPatient);

export const selectedPatientSelector = createSelector(
    [patientsByIdSelector, mainPatientSelector, selectedPatientIdSelector],
    (patientsById, main, selectedId) => {
        const selected = patientsById[selectedId];
        return selected ? selected : main;
    }
);

export const getOnlineRegistrationData = createSelector([mainPatientSelector, onlineRegistrationDataSelector], (mainPatient, onlineRegistrationData) => {
    if (mainPatient && onlineRegistrationData.patientId === mainPatient.id) {
        return onlineRegistrationData;
    } else {
        return {};
    }
});
