import { maskitoInitialCalibrationPlugin, MaskitoMaskExpression, MaskitoOptions } from '@maskito/core';
import Input from 'components/input/Input';
import SelectPicker from 'components/selectpicker/SelectPicker';
import styles from 'containers/input/phone/InputPhone.module.css';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import commonMessages from 'messages/CommonMessages';
import CommonMessages from 'messages/CommonMessages';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { InputPhoneData } from 'types/common';
import { getCountryCode } from 'utils/hostNameUtils';
import { validatePhoneNumber } from 'utils/validators';

import AM from './flags/armenia.svg';
import BY from './flags/belarus.svg';
import KZ from './flags/kazakhstan.svg';
import KG from './flags/kyrgyzstan.svg';
import RU from './flags/russia.svg';

type TProps = {
    name: string;
    value: string | undefined;
    onBlur?: () => void;
    onChange: (data: InputPhoneData) => void;
};

const masks: Record<string, MaskitoMaskExpression> = {
    AM: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    BY: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    KG: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    KZ: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
    RU: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
};

const InputPhone: FC<TProps> = ({ name, value = '', onChange, onBlur }) => {
    const { formatMessage } = useIntl();
    const ref = useRef<any>(null);
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(getCountryCode());
    const [maskOptions, setMaskOptions] = useState<MaskitoOptions>({ mask: masks[getCountryCode()], plugins: [maskitoInitialCalibrationPlugin()] });

    useEffect(() => {
        setMaskOptions({ mask: masks[selectedCountry], plugins: [maskitoInitialCalibrationPlugin()] });
        handleOnChange(value);
    }, [selectedCountry]);

    const renderFlag = (countryCode: string) => {
        return <img src={flags[countryCode]} alt={''} className={styles.flag} />;
    };

    const renderCode = (value: string) => {
        if (value) {
            return <div className={styles.code}>{countryCodes[value]}</div>;
        }
    };

    const countries: Record<string, { label: string; value: string }> = {
        AM: { label: formatMessage(CommonMessages.RepublicOfArmenia), value: 'AM' },
        BY: { label: formatMessage(CommonMessages.RepublicOfBelarus), value: 'BY' },
        KG: { label: formatMessage(CommonMessages.KyrgyzRepublic), value: 'KG' },
        KZ: { label: formatMessage(CommonMessages.RepublicOfKazakhstan), value: 'KZ' },
        RU: { label: formatMessage(CommonMessages.RussianFederation), value: 'RU' }
    };

    const flags: Record<string, string> = {
        AM,
        BY,
        KG,
        KZ,
        RU
    };

    const countryCodes: Record<string, string> = {
        AM: '+374',
        BY: '+375',
        KG: '+996',
        KZ: '+7',
        RU: '+7'
    };

    const placeholders: Record<string, string> = {
        AM: '(00) 000000',
        BY: '(00) 000 00 00',
        KG: '(000) 000 000',
        KZ: '(000) 000 00 00',
        RU: '(000) 000-00-00'
    };

    const handleFocus = () => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const handleOnChange = (value: string) => {
        const code = countryCodes[selectedCountry];
        const phoneNumber = parsePhoneNumberFromString(value || '', selectedCountry);
        const number = phoneNumber?.format('E.164');
        onChange({ code, isValid: validatePhoneNumber(number, [selectedCountry]) || false, notValid: false, number, value });
    };

    return (
        <div className={styles.container}>
            <SelectPicker
                label={formatMessage(commonMessages.phone)}
                value={{
                    label: (
                        <div className={styles.option}>
                            {renderFlag(selectedCountry)}
                            {renderCode(selectedCountry)}
                        </div>
                    ),
                    value: ''
                }}
                options={[countries.RU, countries.AM, countries.BY, countries.KZ, countries.KG].map(({ label, value }: { label: string; value: string }) => ({
                    label: (
                        <div className={styles.option}>
                            {renderFlag(value)}
                            {renderCode(value)}
                            <div className={styles.label}>{label}</div>
                        </div>
                    ),
                    value: value
                }))}
                notice={commonMessages.phoneRegistrationNotice}
                name={name}
                isClearable={false}
                isSearchable={false}
                onChange={({ value }) => {
                    handleFocus();
                    setSelectedCountry(value);
                }}
            />
            <Input
                ref={ref}
                value={value}
                onBlur={onBlur}
                autoComplete='tel'
                className={styles.input}
                maskOptions={maskOptions}
                placeholder={placeholders[selectedCountry]}
                onInput={({ currentTarget }) => handleOnChange(currentTarget.value)}
            />
        </div>
    );
};

export default memo(InputPhone);
