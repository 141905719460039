import classNames from 'classnames/bind';
import Hint from 'components/hint/Hint';
import React, { FC, memo, ReactElement, ReactNode } from 'react';

import styles from './FormField.module.css';
const cx = classNames.bind(styles);

type TProps = {
    show?: boolean;
    label: string | ReactElement;
    tip?: string;
    className?: string;
    children: ReactElement | ReactNode | ReactElement[];
};

const FormField: FC<TProps> = ({ show = true, label, tip, className, children }) => {
    return show ? (
        <div className={cx(styles.field, className)}>
            <label>{label}</label>
            {tip ? <Hint className={styles.tip}>{tip}</Hint> : null}
            {children}
        </div>
    ) : null;
};

export default memo(FormField);
