import Button from 'components/button/Button';
import FormField from 'components/form/field/FormField';
import Input from 'components/input/Input';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/results/key/modal/LasnNameModalMessages';
import React, { FC, memo, useState } from 'react';
import { useIntl } from 'react-intl';
import FC_FormHelper from 'utils/FC_FormHelper';
import { validateNotBlank } from 'utils/validators';

import styles from './LastNameModal.module.css';

type TProps = {
    show: boolean;
    confirm: (data: { lastName: string }) => void;
    loading: boolean;
    error: any;
};

type TState = {
    lastName: string;
    errors: { lastName: false };
};

const LastNameModal: FC<TProps> = ({ show, confirm, loading, error }) => {
    const { formatMessage } = useIntl();
    const [state, setState] = useState<TState>({ errors: { lastName: false }, lastName: '' });

    const validators = {
        lastName: validateNotBlank
    };

    const formHelper = new FC_FormHelper(state, setState, error, validators);

    const confirmLastName = () => {
        const { lastName } = state;
        if (formHelper.validate(['lastName'])) {
            confirm({ lastName });
        }
    };

    return (
        <Modal className={styles.modal} show={show} closable={true} onClose={() => confirm({ lastName: '' })}>
            <h2>{formatMessage(messages.title)}</h2>
            <p>{formatMessage(messages.description)}</p>
            <>{formHelper.renderError()}</>
            <FormField label={formatMessage(commonMessages.lastNameLabel)}>
                <Input
                    name='lastName'
                    placeholder={formatMessage(commonMessages.lastNamePlaceholder)}
                    onChange={formHelper.handleValueChange}
                    error={state.errors.lastName}
                />
            </FormField>
            <Button className={styles.confirmButton} onClick={confirmLastName} text={formatMessage(commonMessages.confirm)} />
            <LoaderModal show={loading} />
        </Modal>
    );
};

export default memo(LastNameModal);
