import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        defaultMessage: 'Cancel',
        id: 'IKBCashbackActivationPage.cancel'
    },
    iAgree: {
        defaultMessage: 'I agree',
        id: 'IKBCashbackActivationPage.iAgree'
    },
    notice: {
        defaultMessage: 'Your discount card with {cardNumber} will be blocked',
        id: 'IKBCashbackActivationPage.notice'
    },
    title: {
        defaultMessage: 'Confirm activation?',
        id: 'IKBCashbackActivationPage.title'
    }
});
