import { useAppDispatch, useAppSelector } from 'app/Hooks';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import React, { FC, Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSearchTextServices } from 'redux/loyaltyIKB/actions';
import { loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';

import styles from './IKBServiceDetailsPage.module.css';

const IKBServiceDetailsPage: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { searchTextServices } = useAppSelector(loyaltyIKBSelector);

    useEffect(() => {
        const { service } = location.state || {};
        if (!service) {
            navigate('/loyalty');
        }
    }, []);

    useEffect(() => {
        return () => {
            if (searchTextServices.length > 0) {
                dispatch(setSearchTextServices(''));
            }
        };
    }, []);

    const renderContent = () => {
        const { service } = location.state || {};

        return (
            <div>
                <Fragment>
                    <IKBNavigationBar title={`№ ${service.article}`} back={true} />
                    <div className={styles.container}>
                        <div className={styles.info}>
                            <h3 className={styles.description}>{service.name}</h3>
                            <p className={styles.detailedDescription} dangerouslySetInnerHTML={{ __html: service.description }} />
                        </div>
                    </div>
                </Fragment>
            </div>
        );
    };

    return renderContent();
};

export default IKBServiceDetailsPage;
