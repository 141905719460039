//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const PATIENTS_URL = `${BASE_URL}/patients`;

export const setEpguSendStartDate = (data) => {
    const { patientId, startDate } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/epgu/set?startDate=${startDate}`);
};

export const fetchAll = (data) => {
    return apiClient.get(`${PATIENTS_URL}?userId=${data.userId}`);
};

export const fetchOnlineRegistrationData = (data) => {
    const { patientId } = data;
    return apiClient.get(`${PATIENTS_URL}/${patientId}/online-registration-data`);
};

export const create = (data) => {
    return apiClient.post(`${PATIENTS_URL}`, data);
};

export const allowHistory = (data) => {
    const { patientId, contactType, territory } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/history/allow?${queryString.stringify({ contactType, territory })}`);
};

export const confirmHistory = (patientId, code, contactType) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/history/confirm?contactType=${contactType}&code=${code}`);
};

export const disallowHistory = (patientId) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/history/disallow`);
};

export const mainPatient = (data) => {
    const { patientId, contactType, territory } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/main?${queryString.stringify({ contactType, territory })}`);
};

export const confirmMainPatient = (patientId, code, contactType) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/main/confirm?contactType=${contactType}&code=${code}`);
};

export const allowLoyalty = (data) => {
    const { patientId, contactType, territory } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/loyalty/allow?${queryString.stringify({ contactType, territory })}`);
};

export const confirmLoyalty = (patientId, code, contactType) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/loyalty/confirm?contactType=${contactType}&code=${code}`);
};

export const disallowLoyalty = (patientId) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/loyalty/disallow`);
};

export const allowDocuments = (data) => {
    const { patientId, contactType, territory } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/documents/allow?contactType=${contactType}&territory=${territory}`);
};

export const confirmDocuments = (patientId, code, contactType) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/documents/confirm?contactType=${contactType}&code=${code}`);
};

export const disallowDocuments = (patientId) => {
    return apiClient.patch(`${PATIENTS_URL}/${patientId}/documents/disallow`);
};

export const deletePatient = (patientId) => {
    return apiClient.delete(`${PATIENTS_URL}/${patientId}`);
};

export const fetchPatient = (data) => {
    const { patientId } = data;
    return apiClient.get(`${PATIENTS_URL}/${patientId}`);
};

export const getEpguInzStatuses = (data) => {
    const { patientId, inzs } = data;
    return apiClient.get(`${PATIENTS_URL}/${patientId}/epgu/status?inzs=${inzs}`);
};

export const sendToEpgu = (data) => {
    const { patientId, inzs } = data;
    return apiClient.post(`${PATIENTS_URL}/${patientId}/epgu/send`, { inzs });
};

export const fetchSignedDocuments = (patientId) => {
    return apiClient.get(`${PATIENTS_URL}/${patientId}/documents`);
};

export const documentDownload = (patientId, documentId, data) => {
    return apiClient.get(`${PATIENTS_URL}/${patientId}/documents/${documentId}?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const publishedPatient = (patientId) => {
    return apiClient.get(`${PATIENTS_URL}/${patientId}/is-published`);
};
