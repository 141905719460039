import { defineMessages } from 'react-intl';

export default defineMessages({
    addPatientFillEmailAndPhone: {
        defaultMessage: "To add a patient, you must also fill in the patient's email and phone number.",
        id: 'AddPatientPage.addPatientFillEmailAndPhone'
    },
    description: {
        defaultMessage: 'This is necessary for the full use of the functionality of the personal account.',
        id: 'AddPatientPage.description'
    },
    patientNotFound: {
        defaultMessage: 'The patient has not been found. Please try to enter other data.',
        id: 'AddPatientPage.patientNotFound'
    },
    successTitle: {
        defaultMessage: 'A new patient has been successfully added!',
        id: 'AddPatientPage.successTitle'
    }
});
