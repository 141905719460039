import { defineMessages } from 'react-intl';

export default defineMessages({
    checkups: {
        defaultMessage: 'Health programs and subscriptions',
        id: 'HeaderBottomMenu.checkups'
    },
    conclusions: {
        defaultMessage: 'Medical card',
        id: 'HeaderBottomMenu.conclusions'
    },
    documents: {
        defaultMessage: 'Documents',
        id: 'HeaderBottomMenu.documents'
    },
    dynamics: {
        defaultMessage: 'Dynamics',
        id: 'HeaderBottomMenu.dynamics'
    },
    loyalty: {
        defaultMessage: 'Loyalty program',
        id: 'HeaderBottomMenu.loyalty'
    },
    orders: {
        defaultMessage: 'Orders',
        id: 'HeaderBottomMenu.orders'
    },
    patients: {
        defaultMessage: 'Patients',
        id: 'HeaderBottomMenu.patients'
    },
    settings: {
        defaultMessage: 'Settings',
        id: 'HeaderBottomMenu.settings'
    }
});
