//@ts-nocheck
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './Popup.module.css';

const cx = classNames.bind(styles);

interface PopupProps {
    text: string;
    position?: 'top' | 'bottom';
    className?: string;
}

const Popup: React.FC<PopupProps> = ({ text, position = 'bottom', className }) => {
    const defaultClassName = cx({
        bottom: position === 'bottom',
        popup: true,
        top: position === 'top'
    });

    return <div className={classNames(defaultClassName, className)}>{text}</div>;
};

export default Popup;
