//@ts-nocheck
import * as loyaltyClient from 'client/LoyaltyClient';
import IKBTooltipConstants from 'constants/ikbTooltipMenu';
import { request } from 'redux/helpers';
import {
    ACTIVATE_ACCOUNT,
    CHANGE_CATEGORY,
    FETCH_CATEGORIES,
    FETCH_MOTIVATING_TOOLTIP,
    FETCH_MULTIPLICATORS_CATEGORY,
    FETCH_SERVICES_CATEGORY,
    FETCH_SOLUTIONS_CATEGORY,
    SEARCH_SERVICES_CATEGORY,
    SEND_ACTIVATION_PIN
} from 'redux/loyaltyIKB/actions';
import { all, takeLatest } from 'redux-saga/effects';
import LocalStorageService from 'services/LocalStorageService';

function* sendActivationPin(action) {
    yield request(SEND_ACTIVATION_PIN, [loyaltyClient.sendActivationPin, action.meta], action.meta);
}

function* activateAccount(action) {
    yield request(ACTIVATE_ACCOUNT, [loyaltyClient.activateAccount, action.meta], action.meta);
}

function* fetchMultiplicatorsCategory(action) {
    yield request(FETCH_MULTIPLICATORS_CATEGORY, [loyaltyClient.fetchMultiplicatorsCategory, action.meta], action.meta);
}

function* fetchSolutionsCategory(action) {
    yield request(FETCH_SOLUTIONS_CATEGORY, [loyaltyClient.fetchSolutionsCategory, action.meta], action.meta);
}

function* fetchServicesCategory(action) {
    yield request(FETCH_SERVICES_CATEGORY, [loyaltyClient.fetchServicesCategory, action.meta], action.meta);
}

function* searchServicesCategory(action) {
    yield request(SEARCH_SERVICES_CATEGORY, [loyaltyClient.searchServicesCategory, action.meta], action.meta);
}

function* changeCategory(action) {
    yield request(CHANGE_CATEGORY, [loyaltyClient.changeCategory, action.meta], action.meta);
}

function* fetchMotivatingTooltip(action) {
    const { data } = yield request(FETCH_MOTIVATING_TOOLTIP, [loyaltyClient.fetchMotivatingTooltip, action.meta], action.meta);
    if (!data) {
        const tooltipDataStorage = yield JSON.parse(LocalStorageService.getItem(IKBTooltipConstants.LOCAL_STORAGE_KEY)) || [];
        LocalStorageService.setItem(
            IKBTooltipConstants.LOCAL_STORAGE_KEY,
            JSON.stringify([...tooltipDataStorage.filter((item) => item.patientId !== action.meta.patientId)])
        );
    }
}

export default function* loyaltyIKBSagas() {
    yield all([
        takeLatest(SEND_ACTIVATION_PIN.BASE, sendActivationPin),
        takeLatest(ACTIVATE_ACCOUNT.BASE, activateAccount),
        takeLatest(FETCH_MULTIPLICATORS_CATEGORY.BASE, fetchMultiplicatorsCategory),
        takeLatest(FETCH_SOLUTIONS_CATEGORY.BASE, fetchSolutionsCategory),
        takeLatest(FETCH_SERVICES_CATEGORY.BASE, fetchServicesCategory),
        takeLatest(SEARCH_SERVICES_CATEGORY.BASE, searchServicesCategory),
        takeLatest(CHANGE_CATEGORY.BASE, changeCategory),
        takeLatest(FETCH_MOTIVATING_TOOLTIP.BASE, fetchMotivatingTooltip)
    ]);
}
