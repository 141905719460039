import IconSuccess from 'components/icon/success/IconSuccess';
import Modal from 'components/modal/Modal';
import * as React from 'react';
import { FC, memo } from 'react';

import styles from './ModalSuccess.module.css';

type TProps = { show: boolean; onClose: () => void; message: string };

const ModalSuccess: FC<TProps> = ({ show = false, onClose, message }) => {
    return (
        <Modal closable={true} show={show} onClose={() => onClose()}>
            <div className={styles.modalSuccess}>
                <IconSuccess />
                <p>{message}</p>
            </div>
        </Modal>
    );
};

export default memo(ModalSuccess);
