import { defineMessages } from 'react-intl';

export default defineMessages({
    notice: {
        defaultMessage:
            'You can increase the amount of charges by choosing either <services>individual services</services> increased by the cashback ratio, or <solutions>ready-made solutions</solutions>.',
        id: 'IKBCashbackActivatedPage.notice'
    },
    noticeCalculated: {
        defaultMessage: 'Your charges {percent}%',
        id: 'IKBCashbackActivatedPage.noticeCalculated'
    },

    title: {
        defaultMessage: 'Cashback is activated!',
        id: 'IKBCashbackActivatedPage.info'
    }
});
