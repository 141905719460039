import { defineMessages } from 'react-intl';

export default defineMessages({
    addPatientButton: {
        defaultMessage: 'Add patient',
        id: 'PatientBlock.addPatientButton'
    },
    adultWarning: {
        defaultMessage: 'Online registration is available for persons over 18 years old',
        id: 'PatientBlock.adultWarning'
    },
    changeRegistrationMode: {
        defaultMessage: 'Change mode',
        id: 'PatientBlock.changeRegistrationMode'
    },
    nonAdultPatientWarning: {
        defaultMessage: 'Online registration allowed only for people above 18 y.o.',
        id: 'PatientBlock.nonAdultPatientWarning'
    },
    onlineRegistrationMainHint: {
        defaultMessage: 'Online registration is available only for the main patient',
        id: 'PatientBlock.onlineRegistrationMainHint'
    },
    title: {
        defaultMessage: 'Patient',
        id: 'PatientBlock.title'
    }
});
