//@ts-nocheck
import { CLEAR, SEND } from 'redux/code/actions';
import { basicReducer } from 'redux/helpers';

const initialState = {
    send: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND.STARTED:
        case SEND.SUCCEEDED:
        case SEND.ERRORED: {
            return {
                ...state,
                send: basicReducer(state.send, action, 'code')
            };
        }
        case CLEAR.BASE: {
            return initialState;
        }
        default:
            return state;
    }
}
