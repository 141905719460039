//@ts-nocheck
import classNames from 'classnames';
import { Icon, Input } from 'page/cart/confirmation/cardForm/components/index';
import React, { ReactNode } from 'react';

import cardStyle from '../CardForm.module.css';
import style from './InputBlock.module.css';

interface InputBlockProps {
    className: string;
    title: string;
    error?: string;
    children: ReactNode;
}

const InputBlock: React.FC<InputBlockProps> & {
    Input: typeof Input;
    Icon: typeof Icon;
} = ({ className, title, error, children }) => {
    return (
        <div className={classNames(className, { [cardStyle.error]: Boolean(error) })}>
            <h3 className={style.title}>{title}</h3>
            <div className={style.content}>{children}</div>
            {error && <span>{error}</span>}
        </div>
    );
};

InputBlock.Input = Input;
InputBlock.Icon = Icon;

export default InputBlock;
