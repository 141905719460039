//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import {
    ALLOW_DOCUMENTS,
    ALLOW_HISTORY,
    ALLOW_LOYALTY,
    CLEAR_CONFIRMATIONS,
    CLEAR_EPGU_SEND_START_DATE,
    CLEAR_ONLINE_REGISTRATION_DATA,
    CLEAR_PATIENTS,
    CONFIRM_DOCUMENTS,
    CONFIRM_HISTORY,
    CONFIRM_LOYALTY,
    CONFIRM_MAIN_PATIENT,
    CREATE,
    DELETE,
    DISALLOW_DOCUMENTS,
    DISALLOW_HISTORY,
    DISALLOW_LOYALTY,
    FETCH_ALL,
    FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION,
    FETCH_ONLINE_REGISTRATION_DATA,
    FETCH_PATIENT,
    FETCH_REGIONS,
    GET_EPGU_INZ_STATUSES,
    MAIN_PATIENT,
    PUBLISHED_PATIENT,
    SELECT,
    SEND_TO_EPGU,
    SET_EPGU_SEND_START_DATE
} from 'redux/patient/actions';
import { ADD_RESULT } from 'redux/result/actions';

const initialState: any = {
    allowDocuments: {},
    allowHistory: {},
    allowLoyalty: {},
    allowedCountries: [],
    confirmDocuments: {},
    confirmHistory: {},
    confirmLoyalty: {},
    confirmMain: {},
    create: {},
    delete: {},
    disallowDocuments: {},
    disallowHistory: {},
    disallowLoyalty: {},
    entities: {},
    epguInzStatuses: {},
    fetchAll: {},
    fetchAllowedCountriesForOnlineRegistration: {},
    fetchOnlineRegistrationData: {},
    fetchPatient: {},
    fetchPublishedPatient: {},
    fetchRegions: {},
    makeMain: {},
    onlineRegistrationData: {},
    regions: [],
    selectedId: null,
    setEpguSendStartDate: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED:
        case FETCH_ALL.SUCCEEDED:
        case FETCH_ALL.ERRORED: {
            let entities = state.entities;
            if (action.type === FETCH_ALL.SUCCEEDED) {
                const patients = action.payload.data;
                entities = patients.reduce((map, patient) => {
                    map[patient.id] = patient;
                    return map;
                }, {});
            }
            return {
                ...state,
                entities,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        }
        case FETCH_ONLINE_REGISTRATION_DATA.STARTED:
        case FETCH_ONLINE_REGISTRATION_DATA.SUCCEEDED:
        case FETCH_ONLINE_REGISTRATION_DATA.ERRORED: {
            let onlineRegistrationData = state.onlineRegistrationData;
            if (action.type === FETCH_ONLINE_REGISTRATION_DATA.SUCCEEDED) {
                onlineRegistrationData = {
                    patientId: action.meta.patientId,
                    ...action.payload.data
                };
            }
            return {
                ...state,
                fetchOnlineRegistrationData: basicReducer(state.fetchOnlineRegistrationData, action),
                onlineRegistrationData
            };
        }
        case FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.STARTED:
        case FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.SUCCEEDED:
        case FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.ERRORED: {
            let allowedCountries = state.allowedCountries;
            if (action.type === FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.SUCCEEDED) {
                allowedCountries = [...action.payload.data];
            }
            return {
                ...state,
                allowedCountries,
                fetchAllowedCountriesForOnlineRegistration: basicReducer(state.fetchAllowedCountriesForOnlineRegistration, action)
            };
        }
        case FETCH_REGIONS.STARTED:
        case FETCH_REGIONS.SUCCEEDED:
        case FETCH_REGIONS.ERRORED: {
            let regions = state.regions;
            if (action.type === FETCH_REGIONS.SUCCEEDED) {
                regions = [...action.payload.data];
            }
            return {
                ...state,
                fetchRegions: basicReducer(state.fetchRegions, action),
                regions
            };
        }
        case CLEAR_ONLINE_REGISTRATION_DATA.BASE: {
            return {
                ...state,
                fetchAllowedCountriesForOnlineRegistration: {},
                fetchOnlineRegistrationData: {},
                fetchRegions: {}
            };
        }
        case ALLOW_HISTORY.STARTED:
        case ALLOW_HISTORY.SUCCEEDED:
        case ALLOW_HISTORY.ERRORED: {
            return {
                ...state,
                allowHistory: basicReducer(state.allowHistory, action, 'code'),
                confirmHistory: {}
            };
        }
        case CONFIRM_HISTORY.STARTED:
        case CONFIRM_HISTORY.SUCCEEDED:
        case CONFIRM_HISTORY.ERRORED: {
            return {
                ...state,
                confirmHistory: basicReducer(state.confirmHistory, action)
            };
        }
        case DISALLOW_HISTORY.STARTED:
        case DISALLOW_HISTORY.SUCCEEDED:
        case DISALLOW_HISTORY.ERRORED: {
            return {
                ...state,
                disallowHistory: basicReducer(state.disallowHistory, action)
            };
        }
        case MAIN_PATIENT.STARTED:
        case MAIN_PATIENT.SUCCEEDED:
        case MAIN_PATIENT.ERRORED: {
            return {
                ...state,
                confirmMain: {},
                makeMain: basicReducer(state.makeMain, action, 'code')
            };
        }
        case CONFIRM_MAIN_PATIENT.STARTED:
        case CONFIRM_MAIN_PATIENT.SUCCEEDED:
        case CONFIRM_MAIN_PATIENT.ERRORED: {
            const entities = { ...state.entities };
            if (action.type === CONFIRM_MAIN_PATIENT.SUCCEEDED) {
                const mainPatientId = Object.values(entities)
                    .filter((p) => p.main === true)
                    .map((p) => p.id);
                const mainPatient = entities[mainPatientId];
                entities[mainPatientId] = {
                    ...mainPatient,
                    main: false
                };
                entities[action.meta.patientId] = {
                    ...entities[action.meta.patientId],
                    main: true
                };
            }
            return {
                ...state,
                confirmMain: basicReducer(state.makeMain, action),
                entities
            };
        }
        case ALLOW_LOYALTY.STARTED:
        case ALLOW_LOYALTY.SUCCEEDED:
        case ALLOW_LOYALTY.ERRORED: {
            return {
                ...state,
                allowLoyalty: basicReducer(state.allowLoyalty, action, 'code'),
                confirmLoyalty: {}
            };
        }
        case CONFIRM_LOYALTY.STARTED:
        case CONFIRM_LOYALTY.SUCCEEDED:
        case CONFIRM_LOYALTY.ERRORED: {
            return {
                ...state,
                confirmLoyalty: basicReducer(state.confirmLoyalty, action)
            };
        }
        case DISALLOW_LOYALTY.STARTED:
        case DISALLOW_LOYALTY.SUCCEEDED:
        case DISALLOW_LOYALTY.ERRORED: {
            return {
                ...state,
                disallowLoyalty: basicReducer(state.disallowLoyalty, action)
            };
        }
        case ALLOW_DOCUMENTS.STARTED:
        case ALLOW_DOCUMENTS.SUCCEEDED:
        case ALLOW_DOCUMENTS.ERRORED: {
            return {
                ...state,
                allowDocuments: basicReducer(state.allowDocuments, action, 'code'),
                confirmDocuments: {}
            };
        }
        case CONFIRM_DOCUMENTS.STARTED:
        case CONFIRM_DOCUMENTS.SUCCEEDED:
        case CONFIRM_DOCUMENTS.ERRORED: {
            return {
                ...state,
                confirmDocuments: basicReducer(state.confirmDocuments, action)
            };
        }
        case DISALLOW_DOCUMENTS.STARTED:
        case DISALLOW_DOCUMENTS.SUCCEEDED:
        case DISALLOW_DOCUMENTS.ERRORED: {
            return {
                ...state,
                disallowDocuments: basicReducer(state.disallowDocuments, action)
            };
        }
        case CREATE.STARTED:
        case CREATE.SUCCEEDED:
        case CREATE.ERRORED: {
            const entities = { ...state.entities };
            if (action.type === CREATE.SUCCEEDED) {
                const patient = action.payload.data;
                entities[patient.id] = patient;
            }
            return {
                ...state,
                create: basicReducer(state.create, action),
                entities
            };
        }
        case ADD_RESULT.SUCCEEDED: {
            const response = action.payload.data;
            const patient = response.patient;
            let entities = state.entities;
            if (!entities[patient.id]) {
                entities = {
                    ...entities,
                    [patient.id]: patient
                };
            }
            return {
                ...state,
                entities,
                selectedId: patient.id
            };
        }
        case DELETE.STARTED:
        case DELETE.SUCCEEDED:
        case DELETE.ERRORED: {
            const entities = { ...state.entities };
            if (action.type === DELETE.SUCCEEDED) {
                const patientId = action.meta;
                delete entities[patientId];
            }
            return {
                ...state,
                delete: basicReducer(state.delete, action),
                entities
            };
        }
        case SELECT.BASE: {
            return {
                ...state,
                selectedId: action.meta
            };
        }
        case CLEAR_CONFIRMATIONS.BASE: {
            return {
                ...state,
                allowDocuments: {},
                allowHistory: {},
                allowLoyalty: {},
                confirmDocuments: {},
                confirmHistory: {},
                confirmLoyalty: {},
                confirmMain: {},
                makeMain: {}
            };
        }
        case SET_EPGU_SEND_START_DATE.STARTED:
        case SET_EPGU_SEND_START_DATE.SUCCEEDED:
        case SET_EPGU_SEND_START_DATE.ERRORED: {
            return {
                ...state,
                setEpguSendStartDate: basicReducer(state.setEpguSendStartDate, action)
            };
        }
        case CLEAR_EPGU_SEND_START_DATE.BASE: {
            return {
                ...state,
                setEpguSendStartDate: {}
            };
        }
        case FETCH_PATIENT.STARTED:
        case FETCH_PATIENT.SUCCEEDED:
        case FETCH_PATIENT.ERRORED: {
            const entities = { ...state.entities };
            if (action.type === FETCH_PATIENT.SUCCEEDED) {
                const { patientId } = action.meta;
                const patient = action.payload?.data;
                entities[patientId] = {
                    ...patient
                };
            }
            return {
                ...state,
                entities,
                fetchPatient: basicReducer(state.fetchPatient, action)
            };
        }
        case CLEAR_PATIENTS.BASE: {
            return {
                ...state,
                entities: {},
                fetchAll: {},
                fetchPatient: {}
            };
        }
        case GET_EPGU_INZ_STATUSES.STARTED:
        case GET_EPGU_INZ_STATUSES.SUCCEEDED:
        case GET_EPGU_INZ_STATUSES.ERRORED: {
            let epguInzStatuses = {};
            if (action.type === GET_EPGU_INZ_STATUSES.SUCCEEDED) {
                epguInzStatuses = { ...action.payload.data?.inzStatuses };
            }
            return {
                ...state,
                epguInzStatuses: epguInzStatuses || {}
            };
        }
        case SEND_TO_EPGU.SUCCEEDED: {
            return {
                ...state
            };
        }
        case PUBLISHED_PATIENT.STARTED:
        case PUBLISHED_PATIENT.SUCCEEDED:
        case PUBLISHED_PATIENT.ERRORED: {
            return {
                ...state,
                fetchPublishedPatient: basicReducer(state.fetchPublishedPatient, action)
            };
        }
        default:
            return state;
    }
}
