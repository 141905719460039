//@ts-nocheck
import * as chatClient from 'client/ChatClient';
import { ACCESS_TOKEN } from 'redux/auth/constants';
import { GET_EDNA_SETTINGS } from 'redux/chat/actions';
import { ednaSettingsSelector, webChatSettingsSelector } from 'redux/chat/selectors';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { CLEAR, FETCH } from 'redux/user/actions';
import { currentSelector } from 'redux/user/selectors';
import { put, select, takeLatest } from 'redux-saga/effects';
import CookieService from 'services/CookieService';
import { removeEdnaScript, setEdnaScript } from 'utils/chatUtils';

function* getEdnaSettings() {
    const access_token = CookieService.get(ACCESS_TOKEN);
    const territory = yield select(currentTerritorySelector);
    const { webChatEnabled } = yield select(webChatSettingsSelector);

    if (webChatEnabled) {
        yield request(GET_EDNA_SETTINGS, [chatClient.getEdnaSettings, { access_token, territory }]);
    }
}

function* ednaInit() {
    const access_token = CookieService.get(ACCESS_TOKEN);
    const settings = yield select(ednaSettingsSelector);
    const territory = yield select(webChatSettingsSelector);
    const user = yield select(currentSelector);

    if (territory.webChatEnabled && settings && (!access_token || user?.id)) {
        yield removeEdnaScript();
        yield setEdnaScript(settings, user);
    }
}

export default function* chatWatcher() {
    yield takeLatest(GET_EDNA_SETTINGS.BASE, getEdnaSettings);
    yield takeLatest(GET_EDNA_SETTINGS.SUCCEEDED, ednaInit);
    yield takeLatest(FETCH.SUCCEEDED, ednaInit);
    yield takeLatest(CLEAR.SUCCEEDED, ednaInit);
}
