import classNames from 'classnames/bind';
import React, { FC, memo } from 'react';

import styles from './InputRadio.module.css';
const cx = classNames.bind(styles);

type TProps = {
    name?: string;
    value?: string;
    checked?: boolean;
    onChange: (event: any) => void;
    label?: string;
    className?: string;
    error?: boolean;
    disabled?: boolean;
};

const InputRadio: FC<TProps> = ({ onChange, label, className, error, ...rest }) => {
    return (
        <label
            className={cx(
                {
                    container: true,
                    error: error
                },
                className
            )}
        >
            <input type='radio' {...rest} onChange={(event) => onChange && onChange(event)} />
            <span className={styles.checkmark} />
            <div className={styles.content}>{label}</div>
        </label>
    );
};

export default memo(InputRadio);
