import { defineMessages } from 'react-intl';

export default defineMessages({
    amountDays: {
        defaultMessage: '{amount} days',
        id: 'IKBServicesConfirmationPage.amountDays'
    },
    confirmSelection: {
        defaultMessage: 'Confirm selection',
        id: 'IKBServicesConfirmationPage.confirmSelection'
    },
    notice: {
        defaultMessage: 'The following changes will be possible <span></span> after the date the button "Confirm selection" was pressed.',
        id: 'IKBServicesConfirmationPage.notice'
    },
    requestError: {
        defaultMessage: 'Change favorite products error',
        id: 'IKBServicesConfirmationPage.requestError'
    }
});
