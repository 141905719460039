//@ts-nocheck
import styles from 'page/layout/header/bottom/HeaderBottom.module.css';
import HeaderBottomMenu from 'page/layout/header/bottom/HeaderBottomMenu';
import * as React from 'react';

const HeaderBottom = () => {
    return (
        <div className={styles.container}>
            <HeaderBottomMenu />
        </div>
    );
};
export default HeaderBottom;
