import { useAppSelector } from 'app/Hooks';
import { useEffect, useState } from 'react';
import { authSelector } from 'redux/auth/selectors';
import { currentSelector } from 'redux/user/selectors';
import { FlocktoryAction } from 'types/common';
declare const window: any;

const useFlocktory = () => {
    window.flocktory = window.flocktory || [];
    const SCRIPT_ID = 'flocktory';
    const DATA_USER_EMAIL = 'data-fl-user-email';
    const DATA_FL_ACTION_EXCHANGE = 'data-fl-action="exchange"';
    const { authenticated } = useAppSelector(authSelector);
    const currentUser = useAppSelector(currentSelector);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setUserEmail(currentUser);
    }, [authenticated, currentUser]);

    useEffect(() => {
        removeUserEmail();
    }, [authenticated]);

    const init = (siteId: number) => {
        if (!ready && !document.getElementById(SCRIPT_ID)) {
            const script = document.createElement('script');
            script.src = `https://api.flocktory.com/v2/loader.js?site_id=${siteId}`;
            script.id = SCRIPT_ID;
            script.async = true;
            document.head.appendChild(script);
            script.onload = () => {
                setReady(true);
            };
            script.onerror = (event) => {
                console.error(event);
            };
        }
    };

    const setUserEmail = (currentUser: any) => {
        const value = currentUser?.email?.length > 0 ? currentUser?.email : `${currentUser?.phone}@unknown.email`;

        if (authenticated && currentUser && getUserEmail() !== value) {
            removeUserEmail();
            document.body.insertAdjacentHTML('afterbegin', `<div class="i-flocktory" ${DATA_USER_EMAIL}="${value}"></div>`);
        }
    };

    const getUserEmail = () => {
        return document.querySelector(`div[${DATA_USER_EMAIL}]`)?.getAttribute(DATA_USER_EMAIL);
    };

    const removeUserEmail = () => {
        if (!authenticated) {
            document.querySelector(`div[${DATA_USER_EMAIL}]`)?.remove();
        }
    };

    const setExchangeUserEmail = () => {
        const value = currentUser?.email?.length > 0 ? currentUser?.email : `xname@flocktory.com`;
        removeExchangeUserEmail();
        document.body.insertAdjacentHTML('afterbegin', `<div class="i-flocktory" ${DATA_FL_ACTION_EXCHANGE} ${DATA_USER_EMAIL}="${value}"></div>`);
    };

    const removeExchangeUserEmail = () => {
        document.querySelector(`div[${DATA_FL_ACTION_EXCHANGE}]`)?.remove();
    };

    const push = (action: FlocktoryAction, data: object) => {
        window.flocktory.push([action, data]);
    };

    return {
        init: (siteId: number) => init(siteId),
        push: (action: FlocktoryAction, data: any) => push(action, data),
        removeExchangeUserEmail: () => removeExchangeUserEmail(),
        setExchangeUserEmail: () => setExchangeUserEmail()
    };
};

export default useFlocktory;
