import classNames from 'classnames/bind';
import messages from 'page/layout/footer/middle/UpButtonMessages';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './UpButton.module.css';

const cx = classNames.bind(styles);

const UpButton = () => {
    const scrollToTop = () => {
        window.scroll({ behavior: 'smooth', left: 0, top: 0 });
    };

    return (
        <button className={cx(styles.upButton, 'up-button')} onClick={scrollToTop}>
            <span className={styles.upButtonText}>
                <FormattedMessage {...messages.text} />
            </span>
        </button>
    );
};

export default UpButton;
