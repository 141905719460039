//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const DYNAMICS_URL = `${BASE_URL}/dynamics`;

export const fetchAll = (data) => {
    return apiClient.get(`${DYNAMICS_URL}?${queryString.stringify(data)}`);
};

export const updateComment = (data) => {
    const { patientId, ...request } = data;
    return apiClient.patch(`${BASE_URL}/patients/${patientId}/tests`, request);
};

export const send = (data) => {
    return apiClient.post(`${DYNAMICS_URL}/send`, data);
};

export const download = (data) => {
    return apiClient.get(`${DYNAMICS_URL}/download?${queryString.stringify(data)}`, { responseType: 'blob' });
};
