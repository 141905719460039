import { defineMessages } from 'react-intl';

export default defineMessages({
    confirmSelection: {
        defaultMessage: 'Confirm selection',
        id: 'IKBSolutionsConfirmationPage.confirmSelection'
    },
    notice: {
        defaultMessage:
            'The finished solution is active until <span></span> After the expiration, the cashback value will be {percent}% for all services, except for promotions',
        id: 'IKBSolutionsConfirmationPage.notice'
    },
    requestError: {
        defaultMessage: 'Change category error',
        id: 'IKBSolutionsConfirmationPage.requestError'
    }
});
