//@ts-nocheck
import { CLEAR, FETCH_ALL } from 'redux/watcher/actions';

const initialState = {
    entities: {},
    error: null,
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED:
            return {
                ...initialState,
                loading: true
            };
        case FETCH_ALL.SUCCEEDED:
            const watchers = action.payload.data;
            const entities = {};
            watchers.forEach((watcher) => (entities[watcher.id] = watcher));
            return {
                ...state,
                entities,
                loading: false
            };
        case FETCH_ALL.ERRORED: {
            return {
                ...state,
                error: action.payload?.response?.data || true,
                loading: false
            };
        }
        case CLEAR.BASE:
            return initialState;
        default:
            return state;
    }
}
