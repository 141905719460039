import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames/bind';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import { CityFilterType } from 'constants/cityFilterType';
import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
    changeCheckupCity,
    changeCity,
    fetchListCity,
    setFilter,
    showChangeCityForm,
    showChangeCityFormCheckup,
    updateCityLetters
} from 'redux/cities/actions';
import { citiesSelector } from 'redux/cities/selectors';
import { City } from 'types/common';

import clearSearchStringIcon from './assets/clearSearchStringIcon.svg';
import searchBlueIcon from './assets/searchBlue.svg';
import searchCloseMobileIcon from './assets/searchCloseMobile.svg';
import styles from './ChangeCityForm.module.css';
import messages from './ChangeCityFormMessages';
const cx = classNames.bind(styles);

type TProps = {
    isCheckup?: boolean;
};

const ChangeCityForm: FC<TProps> = ({ isCheckup = false }) => {
    const dispatch = useAppDispatch();
    const { outputListCity, cityLetters, filter, loading } = useAppSelector(citiesSelector);
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(fetchListCity());
    }, [isCheckup]);

    useEffect(() => {
        dispatch(updateCityLetters(outputListCity));
    }, [filter.territory]);

    const renderCitiesTab = () => {
        if (filter.searchString) {
            return null;
        }

        return (
            <div className={styles.changeCityTabContent}>
                <div className={styles.cityNav}>
                    <div className={styles.cityNavButtons}>{renderLetters()}</div>
                </div>
                {renderCities()}
            </div>
        );
    };

    const renderCities = () => {
        if (!(outputListCity?.length > 0)) {
            return null;
        }
        const sortedCities = sortForColumns(outputListCity);
        return (
            <div className={styles.cities}>
                {sortedCities.map((city) => {
                    return (
                        <a
                            key={city.id}
                            onClick={(event) => {
                                event.preventDefault();
                                dispatch(isCheckup ? changeCheckupCity(city) : changeCity(city));
                            }}
                            href='/'
                            className={styles.selectBasketCityItem}
                        >
                            {getName(city)}
                        </a>
                    );
                })}
            </div>
        );
    };

    const renderLetters = () => {
        if (!cityLetters) {
            return null;
        }
        return (
            <React.Fragment>
                {cityLetters.map((letter: string) => {
                    return (
                        <a
                            key={letter}
                            onClick={(event) => {
                                event.preventDefault();
                                dispatch(
                                    setFilter({
                                        param: letter,
                                        type: CityFilterType.LETTER
                                    })
                                );
                            }}
                            href='/'
                            className={cx({
                                active: filter.letter === letter,
                                letterTab: true
                            })}
                        >
                            {letter}
                        </a>
                    );
                })}
            </React.Fragment>
        );
    };

    const renderSearchResult = () => {
        if (!(filter.searchString?.length > 0) || !outputListCity) {
            return null;
        }
        return (
            <div className={styles.searchResult}>
                {outputListCity.length === 0 ? (
                    <div className={styles.searchNoResult}>{formatMessage(messages.searchMessage)}</div>
                ) : (
                    <ul>
                        {outputListCity.map((city) => {
                            const regExp = new RegExp(filter.searchString, 'i');
                            const name = getName(city);
                            const modifiedCityName = name.replace(regExp, (name) => `<strong>${name}</strong>`);
                            return (
                                <li key={city.id} onClick={() => dispatch(isCheckup ? changeCheckupCity(city) : changeCity(city))}>
                                    <div>
                                        <a
                                            onClick={(event) => {
                                                event.preventDefault();
                                                dispatch(isCheckup ? changeCheckupCity(city) : changeCity(city));
                                            }}
                                            href='/'
                                            className={styles.selectBasketCityItem}
                                            dangerouslySetInnerHTML={{ __html: modifiedCityName || name }}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    };

    const getName = (city: City) => {
        const regionName = city.region?.name;
        return city.name + (city.showRegion && regionName ? ` (${regionName})` : '');
    };

    const sortForColumns = (cities: City[]) => {
        let columnsCount = 4;
        if (window.innerWidth <= 480) {
            columnsCount = 2;
        } else if (window.innerWidth <= 1024) {
            columnsCount = 3;
        }

        const columnSize = Math.floor(cities.length / columnsCount);
        const citiesReminder = cities.length % columnsCount;

        const columns = [];
        let startPosition = 0;
        for (let i = 0; i < columnsCount; i++) {
            const currentColumnSize = columnSize + (i < citiesReminder ? 1 : 0);
            columns.push(cities.slice(startPosition, startPosition + currentColumnSize));
            startPosition = startPosition + currentColumnSize;
        }

        const sortedCities = [];
        for (let i = 0; i < cities.length; i++) {
            const row = Math.floor(i / columnsCount);
            const column = columns[i % columnsCount];
            sortedCities.push(column[row]);
        }

        return sortedCities;
    };

    const handleSearch = (value: string) => {
        dispatch(setFilter({ param: value, type: CityFilterType.SEARCH_STRING }));
    };

    return (
        <div>
            <Modal
                show={true}
                closable={true}
                onClose={() => dispatch(isCheckup ? showChangeCityFormCheckup(false) : showChangeCityForm(false))}
                className={styles.modal}
            >
                <form>
                    <div className={styles.searchCity}>
                        <div className={styles.changeCitySearch}>
                            <span
                                className={styles.searchCloseMobile}
                                onClick={() => dispatch(isCheckup ? showChangeCityFormCheckup(false) : showChangeCityForm(false))}
                            >
                                <img src={searchCloseMobileIcon} alt='' />
                            </span>
                            <input
                                onChange={(event) => handleSearch(event.target.value)}
                                className={styles.changeCitySearchInput}
                                type='text'
                                value={filter.searchString}
                                placeholder={formatMessage(messages.search)}
                            />
                            <span onClick={() => handleSearch('')} className={styles.clearSearchStringIcon}>
                                <img src={clearSearchStringIcon} alt='' />
                            </span>
                            <span className={styles.searchStringIcon}>
                                <img src={searchBlueIcon} alt='' />
                            </span>
                        </div>
                    </div>
                    {renderSearchResult()}
                    {renderCitiesTab()}
                </form>
            </Modal>
            <LoaderModal show={loading} />
        </div>
    );
};

export default memo(ChangeCityForm);
