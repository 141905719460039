import { CommunicationType } from 'constants/communicationType';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { createSelector } from 'reselect';
import { Store } from 'types/store';

export const settingsSelector = (state: Store.App.State) => state.startup.settings;
export const territorySettingsByTerritorySelector = (state: Store.App.State) => state?.startup?.settings?.territorySettings;
export const startupSettingsSelector = (state: Store.App.State) => state.startup.settings;

export const currentTerritorySettingsSelector = createSelector(
    [territorySettingsByTerritorySelector, currentTerritorySelector],
    (settingsByTerritory, territory) => settingsByTerritory[territory as string]
);

export const supportPhoneSelector = createSelector([currentTerritorySettingsSelector], (settings) => {
    const phones = settings?.communications?.filter((communication) => communication.type === CommunicationType.PHONE);
    if (!phones || phones.length === 0) {
        return null;
    } else if (phones?.length === 1) {
        return phones[0];
    } else {
        const freePhones = phones.filter((phone) => phone.free);
        return freePhones.length > 0 ? freePhones[0] : phones[0];
    }
});
