export const cardFormConfig = {
    cardNumber: {
        autoComplete: 'cc-number',
        mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
        name: 'cardnumber',
        placeholder: '0000 0000 0000 0000',
        type: 'text'
    },
    cvv: {
        autoComplete: 'cc-csc',
        mask: [/\d/, /\d/, /\d/],
        name: 'cvc',
        placeholder: '000',
        type: 'password'
    },
    expDate: {
        autoComplete: 'cc-exp',
        mask: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/],
        placeholder: '00 / 00',
        type: 'text'
    }
};

export const INPUTS_LIST = {
    CARD_NUMBER: 'cardNumber',
    CVV: 'cvv',
    EXP_DATE: 'expDate'
};

export const INPUT_ERRORS_CODES = {
    CardNumber_Empty: 'CardNumber_Empty',
    CardNumber_Invalid: 'CardNumber_Invalid',
    CardNumber_Length: 'CardNumber_Length',
    Cvv_Empty: 'Cvv_Empty',
    Cvv_Invalid: 'Cvv_Invalid',
    ExpDateMonthYear_Empty: 'ExpDateMonthYear_Empty',
    ExpDateMonthYear_Invalid: 'ExpDateMonthYear_Invalid',
    ExpDateMonth_Empty: 'ExpDateMonth_Empty',
    ExpDateMonth_Invalid: 'ExpDateMonth_Invalid',
    ExpDateYear_Empty: 'ExpDateYear_Empty',
    ExpDateYear_Invalid: 'ExpDateYear_Invalid',
    Name_Empty: 'Name_Empty',
    Name_Invalid: 'Name_Invalid',
    Name_TooLong: 'Name_TooLong',
    Name_TooShort: 'Name_TooShort'
};

export const NUMBER = '+7 (800) 200-363-0';
