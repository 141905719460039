import React from 'react';

import styles from './Email.module.css';
interface IEmailProps {
    data: {
        email: string;
    };
}
const Email = ({ data }: IEmailProps) => {
    const { email } = data;
    return (
        <div key={email} className={styles.email}>
            <a href={`mailto:${data.email}`}>{email}</a>
        </div>
    );
};

export default Email;
