import { createRequestTypes } from 'redux/helpers';

export const FETCH = createRequestTypes('LOYALTY_STATUS/FETCH');
export const CLEAR_ALL = createRequestTypes('LOYALTY_STATUS/CLEAR_ALL');
export const CLEAR_BY_PATIENT_ID = createRequestTypes('LOYALTY_STATUS/CLEAR_BY_PATIENT_ID');

export const clearAllLoyaltyStatus = () => CLEAR_ALL.base();
export const APPLY_COUPON = createRequestTypes('LOYALTY_IKB/APPLY_COUPON');
export const GET_CASHBACK_STATUS = createRequestTypes('LOYALTY_IKB/GET_CASHBACK_STATUS');

export const fetchLoyaltyStatus = (payload: any) => FETCH.base(payload);
export const clearLoyaltyStatus = () => CLEAR_ALL.base();

export const clearByPatientId = (patientId: string) => CLEAR_BY_PATIENT_ID.base(patientId);

export const applyCoupon = (payload: { patientId: string; territory: string; number: string }) => APPLY_COUPON.base(payload);
export const getCashbackStatus = (payload: string) => GET_CASHBACK_STATUS.base(payload);
