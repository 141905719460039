import React, { FC, memo } from 'react';

import styles from './RegularPageConfirmCard.module.css';

type TProps = {
    message: string;
    percent: string;
};

const RegularPageConfirmCard: FC<TProps> = ({ message, percent }) => {
    return (
        <div className={styles.card}>
            <span className={styles.message}>{message}</span>
            <span className={styles.percent}>{percent}%</span>
        </div>
    );
};

export default memo(RegularPageConfirmCard);
