import { combineReducers } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import auth from 'redux/auth/reducer';
import cart from 'redux/cart/reducer';
import chat from 'redux/chat/reducer';
import checkups from 'redux/checkups/reducer';
import cities from 'redux/cities/reducer';
import code from 'redux/code/reducer';
import conclusions from 'redux/conclusions/reducer';
import documents from 'redux/documents/reducer';
import dynamics from 'redux/dynamics/reducer';
import headerBottomMenu from 'redux/headerBottomMenu/reducer';
import homeVisits from 'redux/homeVisits/reducer';
import i18n from 'redux/i18n/reducer';
import loyaltyHistory from 'redux/loyaltyHistory/reducer';
import loyaltyIKB from 'redux/loyaltyIKB/reducer';
import loyaltyStatus from 'redux/loyaltyStatus/reducer';
import offices from 'redux/offices/reducer';
import orderDetails from 'redux/orderDetails/reducer';
import orders from 'redux/orders/reducer';
import ordersCount from 'redux/ordersCount/reducer';
import patient from 'redux/patient/reducer';
import products from 'redux/products/reducer';
import result from 'redux/result/reducer';
import startup from 'redux/startup/reducer';
import user from 'redux/user/reducer';
import watcher from 'redux/watcher/reducer';
import { RouterState } from 'redux-first-history';

const createRootReducer = (routerReducer: Reducer<RouterState>) =>
    combineReducers({
        auth,
        i18n,
        loyaltyHistory,
        loyaltyIKB,
        dynamics,
        loyaltyStatus,
        code,
        patient,
        cities,
        router: routerReducer,
        cart,
        startup,
        headerBottomMenu,
        documents,
        user,
        chat,
        orderDetails,
        checkups,
        orders,
        conclusions,
        ordersCount,
        homeVisits,
        result,
        offices,
        watcher,
        products
    });

export default createRootReducer;
