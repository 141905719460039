import { createRequestTypes } from 'redux/helpers';
import { Certificate, Coupon, IPatient } from 'types/common';

export const FETCH = createRequestTypes('CART/FETCH');
export const CLEAR = createRequestTypes('CART/CLEAR');
export const CLEAR_REORDER_CART = createRequestTypes('CART/CLEAR_REORDER_CART');
export const RESET_CART_STATE = createRequestTypes('CART/RESET_CART_STATE');

export const UPDATE_OFFICE_TYPE = createRequestTypes('CART/UPDATE_OFFICE_TYPE');
export const UPDATE_HOME_VISIT_DATA = createRequestTypes('CART/UPDATE_HOME_VISIT_DATA');
export const UPDATE_ONLINE_REGISTRATION_DATA = createRequestTypes('CART/UPDATE_ONLINE_REGISTRATION_DATA');
export const CLEAR_ONLINE_REGISTRATION_CART_DATA = createRequestTypes('CART/CLEAR_ONLINE_REGISTRATION_CART_DATA');
export const UPDATE_DELIVERY_DATA = createRequestTypes('CART/UPDATE_DELIVERY_DATA');
export const SELECT_PATIENT = createRequestTypes('CART/SELECT_PATIENT');
export const SELECT_PAYMENT_TYPE = createRequestTypes('CART/SELECT_PAYMENT_TYPE');

export const ADD_PRODUCT = createRequestTypes('CART/ADD_PRODUCT');
export const DELETE_PRODUCT = createRequestTypes('CART/DELETE_PRODUCT');
export const RESTORE_PRODUCT = createRequestTypes('CART/RESTORE_PRODUCT');
export const DELETE_INVALID_PRODUCTS = createRequestTypes('CART/DELETE_INVALID_PRODUCTS');
export const DELETE_INVALID_CHECKUPS = createRequestTypes('CART/REMOVE_INVALID_CHECKUPS');
export const DELETE_INVALID_ANALYZES = createRequestTypes('CART/REMOVE_INVALID_ANALYZES');
export const REMOVE_APPOINTMENT_REQUIRED_PRODUCTS = createRequestTypes('CART/REMOVE_APPOINTMENT_REQUIRED_PRODUCTS');
export const CHECK_REORDER_AVAILABILITY = createRequestTypes('CART/CHECK_REORDER_AVAILABILITY');
export const CALCULATE_REORDER = createRequestTypes('CART/CALCULATE_REORDER');
export const CALCULATE_OFFICE = createRequestTypes('CART/CALCULATE_OFFICE');
export const CALCULATE_CHECKUPS = createRequestTypes('CART/CALCULATE_CHECKUPS');
export const CALCULATE_HOME_VISIT = createRequestTypes('CART/CALCULATE_HOME_VISIT');
export const SET_PAYMENT_PREORDER_ID = createRequestTypes('CART/SET_PAYMENT_PREORDER_ID');
export const CALCULATE_PAYMENT_PREORDER = createRequestTypes('CART/CALCULATE_PAYMENT_PREORDER');
export const RETRY_OFFICE_CALCULATION = createRequestTypes('CART/RETRY_OFFICE_CALCULATION');
export const RETRY_HOME_VISIT_CALCULATION = createRequestTypes('CART/RETRY_HOME_VISIT_CALCULATION');
export const TOGGLE_ONLINE_REGISTRATION = createRequestTypes('CART/TOGGLE_ONLINE_REGISTRATION');
export const CLEAR_OFFICE_DATA = createRequestTypes('CART/CLEAR_OFFICE_DATA');
export const CLEAR_HOME_VISIT_DATA = createRequestTypes('CART/CLEAR_HOME_VISIT_DATA');

export const CLEAR_CHECKUPS_DATA = createRequestTypes('CART/CLEAR_CHECKUPS_DATA');

export const SHOW_BIOMATERIAL_MODAL = createRequestTypes('CART/SHOW_BIOMATERIAL_MODAL');
export const SET_BIOMATERIALS = createRequestTypes('CART/SET_BIOMATERIALS');

export const CHECK_APPOINTMENT = createRequestTypes('CART/CHECK_APPOINTMENT');
export const SET_APPOINTMENT = createRequestTypes('CART/SET_APPOINTMENT');
export const CLEAR_APPOINTMENT = createRequestTypes('CART/CLEAR_APPOINTMENT');

export const SHARE_CART = createRequestTypes('CART/SHARE_CART');
export const RECEIVED_CART = createRequestTypes('CART/RECEIVED_CART');
export const CLEAR_RECEIVED_CART = createRequestTypes('CART/CLEAR_RECEIVED_CART');

export const FETCH_CART_BY_CODE = createRequestTypes('CART/FETCH_CART_BY_CODE');
export const MERGE_CART = createRequestTypes('CART/MERGE_CART');
export const REPLACE_CART = createRequestTypes('CART/REPLACE_CART');

export const REPEAT_ORDER = createRequestTypes('CART/REPEAT_ORDER');

export const ADD_RECOMMENDED_PRODUCT = createRequestTypes('CART/ADD_RECOMMENDED_PRODUCT');
export const ADD_RECOMMENDED_PROFILE = createRequestTypes('CART/ADD_RECOMMENDED_PROFILE');

export const CHECK_PRODUCTS_AVAILABLE = createRequestTypes('CART/CHECK_PRODUCTS_AVAILABLE');

export const FETCH_PAYMENT_STATUS = createRequestTypes('CART/FETCH_PAYMENT_STATUS');
export const SET_PAYMENT_STATUS = createRequestTypes('CART/SET_PAYMENT_STATUS');

export const SET_CART_ORDER_TYPE = createRequestTypes('CART/SET_CART_ORDER_TYPE');
export const UPDATE_CHECKUP_DELIVERY_VALUE = createRequestTypes('CART/UPDATE_CHECKUP_DELIVERY_VALUE');

export const CLEAR_ACTION_CART = createRequestTypes('CART/CLEAR_ACTION_CART');
export const SET_SALE_ACTION = createRequestTypes('CART/SET_SALE_ACTION');

export const resetCartState = () => RESET_CART_STATE.base();
export const updateCheckupDeliveryValue = (payload: any) => UPDATE_CHECKUP_DELIVERY_VALUE.base(payload);
export const clearCart = () => CLEAR.base();
export const clearReorderCart = (payload: { articles: string[] }) => CLEAR_REORDER_CART.base(payload);
export const setSaleAction = (payload: { calculationData: any; data: { coupons?: Coupon[]; certificates?: Certificate[] } }) => SET_SALE_ACTION.base(payload);
export const clearAppointment = () => CLEAR_APPOINTMENT.base();
export const selectPatient = (payload: IPatient) => SELECT_PATIENT.base(payload);
export const calculateOffice = (payload: any) => CALCULATE_OFFICE.base(payload);
export const calculateReorder = (payload: any) => CALCULATE_REORDER.base(payload);
export const calculateCheckups = () => CALCULATE_CHECKUPS.base();
export const setPaymentPreorderId = (payload: any) => SET_PAYMENT_PREORDER_ID.base(payload);
export const calculatePaymentPreorder = (payload: any) => CALCULATE_PAYMENT_PREORDER.base(payload);
export const setCartOrderType = (payload: any) => SET_CART_ORDER_TYPE.base(payload);
export const deleteProduct = (payload: { id: string; article: string }) => DELETE_PRODUCT.base(payload);
export const restoreProduct = (payload: { article: string }) => RESTORE_PRODUCT.base(payload);
export const deleteInvalidCheckups = () => DELETE_INVALID_CHECKUPS.base();
export const deleteInvalidAnalyzes = () => DELETE_INVALID_ANALYZES.base();
export const removeAppointmentRequiredProducts = () => REMOVE_APPOINTMENT_REQUIRED_PRODUCTS.base();
export const retryOfficeCalculation = () => RETRY_OFFICE_CALCULATION.base();
export const retryHomeVisitCalculation = () => RETRY_HOME_VISIT_CALCULATION.base();
export const showBiomaterialModal = (payload: { show: boolean; productsIds: string[] }) => SHOW_BIOMATERIAL_MODAL.base(payload);
export const mergeCart = (payload: { repeatOrderProducts: { article: string }[] }) => MERGE_CART.base(payload);
export const replaceCart = (payload: { repeatOrderProducts: { article: string }[] }) => REPLACE_CART.base(payload);
export const shareCart = (payload: { territory: string; productsIds: string[] }) => SHARE_CART.base(payload);
export const fetchCartByCode = (payload: { code: string }) => FETCH_CART_BY_CODE.base(payload);
export const clearActionCart = () => CLEAR_ACTION_CART.base();
