//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const HOME_VISIT_URL = `${BASE_URL}/home-visits`;

export const create = (data) => {
    return apiClient.post(`${HOME_VISIT_URL}`, data);
};

export const calculate = (data) => {
    return apiClient.post(`${HOME_VISIT_URL}/calculate`, data);
};
