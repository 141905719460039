import { MaskitoOptions } from '@maskito/core';
import classNames from 'classnames/bind';
import Input from 'components/input/Input';
import React, { FC, memo } from 'react';

import styles from './InputValid.module.css';
const cx = classNames.bind(styles);

type TProps = {
    valid?: boolean;
    className?: string;
    onChange: () => void;
    maskOptions?: MaskitoOptions;
};

const InputValid: FC<TProps> = ({ valid, className, onChange, ...rest }) => {
    return (
        <div className={styles.container}>
            <Input
                onChange={onChange}
                onInput={onChange}
                className={cx(
                    {
                        valid: valid
                    },
                    className
                )}
                {...rest}
            />
            {valid ? <span className={styles.validIcon} /> : null}
        </div>
    );
};

export default memo(InputValid);
