//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getBackRefUrl } from 'utils/browserUtils';

const ProtectedRoute = ({ authenticated, location, children }) => {
    return authenticated ? children : <Navigate replace to={'/login'} state={{ referrer: `${getBackRefUrl(location)}` }} />;
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated
    };
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
