import { useAppSelector } from 'app/Hooks';
import { ReactNode, useMemo } from 'react';
import { Localization } from 'types/common';

export type Messages = {
    [key: string]: {
        [locale: string]: string;
    };
};

type ReplacementFunction = (...chunks: ReactNode[]) => ReactNode;

interface Values {
    [key: string]: ReactNode | ReplacementFunction;
}

function useLocalization() {
    const locale: Localization = useAppSelector((state) => state.i18n.currentLocale);
    return (messages: Messages, id: keyof Messages, values: Values = {}): ReactNode => {
        const message = messages[id]?.[locale];

        if (!message) return '';

        return useMemo(() => {
            if (typeof message !== 'string') return '';
            const parts = message.split(/(<\w+>.*?<\/\w+>)/g);

            return parts
                .map((part) => {
                    const match = part.match(/<(\w+)>(.*?)<\/\1>/);
                    if (match) {
                        const key = match[1];
                        const content = match[2];
                        const value = values[key];

                        if (typeof value === 'function') {
                            return value(content);
                        }
                        return value !== undefined ? value : '';
                    }
                    return part;
                })
                .filter(Boolean);
        }, [message, values]);
    };
}

export default useLocalization;
