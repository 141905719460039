import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const WEB_CHAT_URL = `${BASE_URL}/settings/frontend/web-chat`;

export const getEdnaSettings = ({ access_token, territory }: { access_token: string; territory: string }) => {
    if (access_token) {
        return apiClient.get(`${WEB_CHAT_URL}/${territory}/authorized`);
    } else {
        return apiClient.get(`${WEB_CHAT_URL}/${territory}`);
    }
};
