//@ts-nocheck
import Cost from 'containers/const/Cost';
import styles from 'page/cart/confirmation/orderBlock/orderList/OrderList.module.css';
import messages from 'page/cart/confirmation/orderBlock/orderList/OrderListMessages';
import * as React from 'react';
import { injectIntl } from 'react-intl';

class OrderList extends React.PureComponent {
    render() {
        const { currency, intl, cartOrderType, services, cost, loading } = this.props;

        return (
            <React.Fragment>
                <div className={styles.orderListItems}>
                    <div className={styles.orderListItem}>
                        <div className={styles.orderListItemTitle}>
                            {intl.formatMessage(!cartOrderType.checkups ? messages.itemTitle : messages.checkupTitle)}
                        </div>
                        {!loading && (
                            <div className={styles.orderListItemPrice}>
                                <Cost defaultValue={'-'} amount={cost.productsCost} currency={currency} />
                            </div>
                        )}
                    </div>
                    {services?.map((product) => (
                        <div className={styles.orderListItem} key={product.id}>
                            <div className={styles.orderListItemTitle}>{product.name}:</div>
                            {!loading && (
                                <div className={styles.orderListItemPrice}>
                                    <Cost defaultValue={'-'} amount={product.price} currency={currency} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(OrderList);
