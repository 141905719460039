import AlertWarning from 'components/alert/AlertWarning';
import Timer from 'components/timer/Timer';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/auth/Common.module.css';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getErrorsByTimestamp } from 'utils/timeUtils';

type TProps = {
    error: any;
    show?: boolean;
    attemptsNotice?: any;
    timeToResend?: boolean;
};

const Error: FC<TProps> = ({ show = true, error, attemptsNotice, timeToResend }) => {
    const { formatMessage } = useIntl();
    const [isTimerEnd, setIsTimerEnd] = useState(false);

    useEffect(() => {
        setIsTimerEnd(false);
    }, [timeToResend]);
    if (Array.isArray(error)) {
        error = getErrorsByTimestamp(error)[0];
    }

    if ((!error?.message && !error?.errors && !error?.attempts) || !show) {
        return null;
    }

    return (
        !isTimerEnd && (
            <AlertWarning
                show={error}
                content={
                    <Fragment>
                        <div>
                            {error.errors?.length
                                ? error.errors.map((error: any) => {
                                      return (
                                          <div key={error.code}>
                                              {error.message}
                                              <br />
                                          </div>
                                      );
                                  })
                                : error.message}

                            {timeToResend && error.timeToResend && (
                                <Timer className={styles.timer} seconds={error.timeToResend} onEnd={() => setIsTimerEnd(true)}>
                                    {({ formatted }: any) => {
                                        return formatMessage(commonMessages.afterTime, { time: formatted });
                                    }}
                                </Timer>
                            )}
                        </div>
                        {error.attempts && attemptsNotice && formatMessage(attemptsNotice, { attempts: error.attempts })}
                    </Fragment>
                }
                className={styles.alertWarning}
            />
        )
    );
};

export default memo(Error);
