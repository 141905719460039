import { defineMessages } from 'react-intl';

export default defineMessages({
    activate: {
        defaultMessage: 'Activate',
        id: 'IKBCashbackCodeEntryPage.activate'
    },
    code: {
        defaultMessage: 'SMS code',
        id: 'IKBCashbackCodeEntryPage.code'
    },
    error: {
        defaultMessage: 'Wrong SMS code entered',
        id: 'IKBCashbackCodeEntryPage.error'
    },
    info: {
        defaultMessage: 'Phone confirmation',
        id: 'IKBCashbackCodeEntryPage.info'
    },
    sendCode: {
        defaultMessage: 'Send SMS again',
        id: 'IKBCashbackCodeEntryPage.sendCode'
    }
});
