import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { useTimer } from 'hooks/useTimer';
import commonMessages from 'messages/CommonMessages';
import TopNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { clearConfirmData } from 'redux/loyaltyIKB/actions';
import { loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import { allowLoyalty, clearConfirmations, confirmLoyalty } from 'redux/patient/actions';
import { patientSelector } from 'redux/patient/selectors';

import styles from './IKBCodeEntryPage.module.css';
import messages from './IKBCodeEntryPageMessages';

const IKBCodeEntryPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const patientState = useAppSelector(patientSelector);
    const sendCodeState = patientState.allowLoyalty;
    const confirmState = patientState.confirmLoyalty;
    const timeToResend = sendCodeState.code?.timeToResend || sendCodeState.error?.timeToResend || 0;
    const { confirmData } = useAppSelector(loyaltyIKBSelector);

    const [state, setState] = useState<{ code: string; error: { message: string | null }; seconds: number }>({
        code: '',
        error: { message: null },
        seconds: 0
    });
    const [isCodeSent, setCodeSent] = useState<boolean>(false);
    const hideTimer = (): void => {
        setCodeSent(false);
        setState((prevState) => ({ ...prevState, seconds: 0 }));
    };
    const secondsLeft = useTimer(isCodeSent, hideTimer, state.seconds);

    useEffect(() => {
        if (!sendCodeState || !confirmData?.patientId || sendCodeState.request !== confirmData?.patientId) {
            navigate('/loyalty');
        } else {
            setState((prevState) => ({ ...prevState, code: '' }));
            resetError();
        }
    }, []);

    useEffect(() => {
        if (sendCodeState.success) {
            resetTimer();
        }
    }, [sendCodeState.success]);

    useEffect(() => {
        if (confirmState && confirmState.success === true && !confirmState.error) {
            navigate('/loyalty');
            clear();
        } else if (sendCodeState.error || confirmState.error) {
            setState((prevState) => ({ ...prevState, error: sendCodeState.error || confirmState.error }));
        }
    }, [sendCodeState.error, confirmState.success, confirmState.error]);

    useEffect(() => {
        if (state.seconds > 0) {
            setCodeSent(true);
        }
    }, [state.seconds]);

    const { code, error } = state;

    const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
        const pattern = /^[0-9\b]+$/;

        if (event.target.value === '' || pattern.test(event.target.value)) {
            setState((prevState) => ({ ...prevState, code: event.target.value }));
        }
    };

    const handleAllowLoyalty = () => {
        dispatch(
            allowLoyalty({
                ...confirmData
            })
        );

        resetError();
    };

    const handleConfirmLoyalty = () => {
        dispatch(
            confirmLoyalty({
                ...confirmData,
                code: state.code
            })
        );

        setState((prevState) => ({ ...prevState, code: '' }));
    };

    const createNoticeSendCode = (seconds: number) => {
        return <span key={seconds}>{seconds}</span>;
    };

    const resetTimer = () => {
        setState((prevState) => ({ ...prevState, seconds: timeToResend }));
    };

    const resetError = () => {
        setState((prevState) => ({ ...prevState, error: { message: null } }));
    };

    const renderError = () => {
        const { error } = state;
        if (error?.message) {
            return <AlertWarning content={error.message} />;
        }
        return null;
    };

    const clear = () => {
        dispatch(clearConfirmData());
        dispatch(clearConfirmations());
    };

    return (
        <div>
            <TopNavigationBar title={formatMessage(messages.navigationTitle)} back={true} />
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.info}>{formatMessage(messages.info)}</div>
                    {renderError()}
                    <div className={styles.inner}>
                        <div
                            className={classNames({
                                [styles.code]: true,
                                [styles.error]: error
                            })}
                        >
                            <label className={styles.labelCode}>{formatMessage(messages.code)}</label>
                            <Input value={code} onChange={(event) => handleChangeCode(event)} placeholder={'000000'} autoComplete={'off'} maxLength={6} />
                        </div>
                        <Button disabled={code.length < 4} onClick={() => handleConfirmLoyalty()}>
                            {formatMessage(commonMessages.confirm)}
                        </Button>
                    </div>
                    <div className={styles.notice}>
                        {secondsLeft > 0 && (
                            <div>
                                {formatMessage(messages.noticeSendCode, {
                                    span: () => createNoticeSendCode(secondsLeft)
                                })}
                            </div>
                        )}
                        {secondsLeft === 0 && (
                            <div className={styles.sendCode} onClick={handleAllowLoyalty}>
                                {formatMessage(messages.sendCode)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(IKBCodeEntryPage);
