/**
 * Перечисление типов действий над участием в лояльности
 */
export const IKBContactOperation = {
    /**
     * Активировать бонусный счет
     */
    BONUS_ACTIVATION_REQUEST: 'BONUS_ACTIVATION_REQUEST',

    /**
     * Сменить бонусную категорию
     */
    BONUS_CATEGORY_CHANGE_REQUEST: 'BONUS_CATEGORY_CHANGE_REQUEST'
};
