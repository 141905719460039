import { defineMessages } from 'react-intl';

export default defineMessages({
    checkupDescription: {
        defaultMessage:
            'You can return the health program at any medical office according to the rules for the treatment of health programs.{br}{br}The cost of the health program is fixed for the duration of the product (within 1 calendar year).',
        id: 'DescriptionBlock.checkupDescription'
    },
    firstListItem: {
        defaultMessage: 'Cancel in your account in the "Orders" section with the "Cancel" button.',
        id: 'DescriptionBlock.firstItemList'
    },
    secondListItem: {
        defaultMessage: 'Change with the administrator at the medical office.',
        id: 'DescriptionBlock.secondListItem'
    },
    text: {
        defaultMessage: 'The cost of the order is calculated according to the price list valid at the time of the change.',
        id: 'DescriptionBlock.text'
    },
    title: {
        defaultMessage: 'The placed order can be changed / canceled:',
        id: 'DescriptionBlock.title'
    }
});
