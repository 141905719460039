import { defineMessages } from 'react-intl';

export default defineMessages({
    applyCoupon: {
        defaultMessage: 'Apply',
        id: 'Coupons.applyCoupon'
    },
    couponHint: {
        defaultMessage: 'You can apply multiple coupons in one order.',
        id: 'Coupons.couponHint'
    },
    couponNumber: {
        defaultMessage: 'Coupon {coupon}',
        id: 'Coupons.couponNumber'
    },
    couponPlaceholder: {
        defaultMessage: 'coupon number',
        id: 'Coupons.couponPlaceholder'
    },
    formLabel: {
        defaultMessage: 'Coupon',
        id: 'Coupons.formLabel'
    }
});
