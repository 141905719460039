import { createRequestTypes } from 'redux/helpers';
export const FETCH_ALL = createRequestTypes('OFFICE/FETCH_ALL');
export const FILTER = createRequestTypes('OFFICE/FILTER');
export const CHOOSE_OFFICE = createRequestTypes('OFFICE/CHOOSE_OFFICE');
export const SELECT_OFFICE = createRequestTypes('OFFICE/SELECT_OFFICE');
export const CLEAR_OFFICE = createRequestTypes('OFFICE/CLEAR_OFFICE');
export const SET_ADAPTIVE_MODE = createRequestTypes('OFFICE/SET_ADAPTIVE_MODE');
export const SHOW_MODAL = createRequestTypes('OFFICE/SHOW_MODAL');
export const CHOOSE_DEFAULT_OFFICE = createRequestTypes('OFFICE/CHOOSE_DEFAULT_OFFICE');

export const showOfficesModal = (payload: { show: boolean }) => SHOW_MODAL.base(payload);
