import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import { IKBContactOperation } from 'constants/ikbContactOperation';
import commonMessages from 'messages/CommonMessages';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import React, { FC, memo, useEffect } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { setCashbackStatus } from 'redux/loyaltyIKB/actions';
import { activateAccountSelector, activationSelector } from 'redux/loyaltyIKB/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { IKBLoyalty, IPatient } from 'types/common';

import messages from './IKBCashbackActivatedMessages';
import styles from './IKBCashbackActivatedPage.module.css';

type TProps = {
    selected: IPatient;
    loyaltyStatus: IKBLoyalty.Status;
    territory: string;
};

const IKBCashbackActivatedPage: FC<TProps> = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const activateAccountResponse = useAppSelector((state) => activateAccountSelector(state));
    const activation = useAppSelector((state) => activationSelector(state));
    const loyaltyStatus = useAppSelector(() => activation.status?.data);

    useEffect(() => {
        if (!activateAccountResponse || activateAccountResponse.success === false) {
            navigate('/loyalty');
        }
    }, []);

    const createNotice = (text: string) => {
        return <strong>{text}</strong>;
    };

    const handleChangeCategory = () => {
        dispatch(setCashbackStatus(loyaltyStatus?.bonusProgramStatus?.bonusStatus));
        navigate('/loyalty/ikb/cashback');
    };

    const renderContent = () => {
        let charge = 0;
        let canChangeCategory = false;
        const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);

        if (loyaltyStatus) {
            charge = loyaltyStatus.bonusProgramStatus.bonusStatus.category.charge;
            canChangeCategory = loyaltyStatus.contactOptions.some(
                (option: IKBLoyalty.ContactOption) => option.contactOperation === IKBContactOperation.BONUS_CATEGORY_CHANGE_REQUEST
            );
        }

        return (
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
                        </div>
                        <img className={styles.activatedCashbackIcon} alt='' src={ikbLoyaltyService.getActivatedCashbackIcon()} />
                    </div>
                    <div className={styles.noticeCalculated}>
                        {formatMessage(messages.noticeCalculated, { percent: <FormattedNumber maximumFractionDigits={0} value={charge} /> })}
                    </div>
                    {canChangeCategory && (
                        <div className={styles.notice}>
                            <p>
                                {formatMessage(messages.notice, {
                                    services: () => createNotice(formatMessage(commonMessages.individualServices).toLowerCase()),
                                    solutions: () => createNotice(formatMessage(commonMessages.readySolutions).toLowerCase())
                                })}
                            </p>
                        </div>
                    )}
                    <div className={styles.buttons}>
                        <Button onClick={() => navigate('/')}>{formatMessage(commonMessages.personalArea)}</Button>
                        {canChangeCategory && <Button onClick={() => handleChangeCategory()}>{formatMessage(commonMessages.increaseCashback)}</Button>}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <IKBNavigationBar link={'/loyalty'} title={formatMessage(commonMessages.cashbackActivation)} back={true} />
            {renderContent()}
        </div>
    );
};

export default memo(IKBCashbackActivatedPage);
