//@ts-nocheck
import { getByPatientId } from 'redux/loyaltyStatus/selectors';
import { createSelector } from 'reselect';
import { Store } from 'types/store';

export const loyaltyIKBSelector = (state: Store.App.State) => state.loyaltyIKB;

export const sendActivationPinSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.sendActivationPin);

export const activationSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.activation);

export const activateAccountSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.activateAccount);

export const multiplicatorsCategoryDataSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.multiplicatorsCategoryData);

export const multiplicatorsCategorySelector = createSelector([multiplicatorsCategoryDataSelector], (multiplicatorsCategoryData) => [
    ...(multiplicatorsCategoryData.categories || [])
]);

export const fetchMultiplicatorsCategorySelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.fetchMultiplicatorsCategory);

export const solutionsCategoryDataSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.solutionsCategoryData);

export const solutionsSelector = createSelector([solutionsCategoryDataSelector], (solutionsCategoryData) => [...(solutionsCategoryData.categories || [])]);

export const fetchSolutionsCategorySelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.fetchSolutionsCategory);

export const servicesCategoryDataSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.servicesCategoryData);

export const servicesSelector = createSelector([servicesCategoryDataSelector], (servicesCategoryData) => [...(servicesCategoryData.categories || [])]);

export const fetchServicesCategorySelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.fetchServicesCategory);

export const cashbackStatusSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.cashbackStatus);

export const dataLoyaltyIKBSelector = createSelector([getByPatientId], (patientEntities) => ({
    errorLoyaltyStatus: patientEntities?.error,
    loadingLoyaltyStatus: patientEntities?.loading,
    loyaltyStatus: patientEntities?.loyaltyStatus
}));
