//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import ButtonLink from 'components/button/link/ButtonLink';
import LanguageSelector from 'components/language/LanguageSelector';
import ChangeCityForm from 'containers/changeCityForm/ChangeCityForm';
import Cities from 'containers/cities/Cities';
import loginIcon from 'page/layout/assets/loginIcon.svg';
import HeaderTopMenu from 'page/layout/header/top/HeaderTopMenu';
import LoginButton from 'page/layout/header/top/LoginButton';
import LogoutButton from 'page/layout/header/top/LogoutButton';
import * as React from 'react';
import { connect } from 'react-redux';
import { logoutSelector } from 'redux/auth/selectors';
import { CHANGE as LOCALE_CHANGE } from 'redux/i18n/actions';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { currentSelector } from 'redux/user/selectors';

import styles from './HeaderTop.module.css';

class HeaderTop extends React.PureComponent {
    render() {
        const { authenticated, user, territorySettings, locale } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.row}>
                    <HeaderTopMenu links={territorySettings?.links} locale={locale} />
                    <div className={styles.controls}>
                        <div>
                            <Cities />
                            {this.props.showChangeCityForm && <ChangeCityForm />}
                            {<img src={loginIcon} className={styles.loginIcon} alt='' />}
                            {authenticated ? this.renderUser(user) : <LoginButton />}
                        </div>
                        <div>
                            <LanguageSelector locale={this.props.locale} changeRequest={(locale) => this.props.changeLocale(locale)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderUser(user) {
        if (user) {
            return (
                <React.Fragment>
                    <ButtonLink
                        className={styles.buttonLink}
                        to={'/'}
                        text={user.nickname || user.phone || user.email}
                        onClick={() => this.props.navigate('/')}
                    />
                    <LogoutButton disabled={this.props.inProgress} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        inProgress: logoutSelector(state)?.inProgress,
        locale: state.i18n.currentLocale,
        showChangeCityForm: state.cities.showChangeCityForm,
        territorySettings: currentTerritorySettingsSelector(state),
        user: currentSelector(state)
    };
};

const mapDispatchToProps = {
    changeLocale: LOCALE_CHANGE.base
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderTop));
