//@ts-nocheck
import enMessages from 'i18n/messages/en.json';
import { CHANGE } from 'redux/i18n/actions';

const DEFAULT_LOCALE = 'en';
const DEFAULT_MESSAGES = enMessages;
const MESSAGES_CACHE = {};
MESSAGES_CACHE[DEFAULT_LOCALE] = DEFAULT_MESSAGES;

const initialState = {
    currentLocale: DEFAULT_LOCALE,
    currentMessages: DEFAULT_MESSAGES,
    defaultLocale: DEFAULT_LOCALE,
    defaultMessages: DEFAULT_MESSAGES,
    messagesCache: MESSAGES_CACHE
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE.STARTED:
            return {
                ...state,
                loading: true
            };
        case CHANGE.SUCCEEDED:
            const { locale } = action.meta;
            const messages = action.payload.default;
            const messagesCache = state.messagesCache;
            messagesCache[locale] = messages;
            return {
                ...state,
                currentLocale: locale,
                currentMessages: messages,
                loading: false,
                messagesCache: messagesCache
            };
        case CHANGE.ERRORED:
            return {
                ...state,
                currentLocale: DEFAULT_LOCALE,
                currentMessages: DEFAULT_MESSAGES,
                loading: false
            };
        default:
            return state;
    }
}
