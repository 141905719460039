export const PaymentType = {
    /**
     * В медицинском офисе.
     */
    OFFICE: 'OFFICE',

    /**
     * Картой онлайн.
     */
    ONLINE: 'ONLINE'
} as const;

export const OnlinePaymentType = {
    /**
     * Alfa pay.
     */
    ALFA_PAY: 'ALFA_PAY',

    /**
     * Cloud Payments.
     */
    CLOUD_PAYMENTS: 'CLOUD_PAYMENTS',

    /**
     * Вся сумма купонами/сертификатами
     */
    LOYALTY: 'LOYALTY',

    /**
     * PSB.
     */
    PSB: 'PSB',

    /**
     * Sber pay.
     */
    SBER_PAY: 'SBER_PAY'
} as const;
