import { useAppDispatch, useAppSelector } from 'app/Hooks';
import useChat from 'hooks/useChat';
import useFlocktory from 'hooks/useFlocktory';
import React, { FC, Fragment, memo, ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { initialize } from 'redux/startup/actions';

import styles from './Init.module.css';
import messages from './InitMessages';

const Init: FC<{ children: ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const webChat = useChat();
    const flocktory = useFlocktory();
    const error = useAppSelector((state) => state.startup.error);
    const initialized = useAppSelector((state) => state.startup.initialized);
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(initialize());
    }, []);

    useEffect(() => {
        flocktory.init(2752);
    }, []);

    const renderPage = () => {
        return <div className={styles.container}>{error ? <p>{formatMessage(messages.error)}</p> : <p>{formatMessage(messages.loading)}</p>}</div>;
    };

    return (
        <Fragment>
            {initialized ? children : renderPage()}
            {webChat}
        </Fragment>
    );
};

export default memo(Init);
