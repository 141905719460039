export const DocumentType = {
    /**
     * Согласие на проверку ВИЧ.
     */
    AGREEMENT_BLANK_TYPE_AIDS: 'AGREEMENT_BLANK_TYPE_AIDS',

    /**
     * Согласие на обработку персональных данных.
     */
    AGREEMENT_BLANK_TYPE_CONSENT: 'AGREEMENT_BLANK_TYPE_CONSENT',

    /**
     * Основной договор между пациентом и организацией.
     */
    AGREEMENT_BLANK_TYPE_GENERAL: 'AGREEMENT_BLANK_TYPE_GENERAL',

    /**
     * Согласие на мед. вмешательство.
     */
    AGREEMENT_BLANK_TYPE_INTERVENTION: 'AGREEMENT_BLANK_TYPE_INTERVENTION',

    /**
     * Согласие на участие в программе лояльности.
     */
    AGREEMENT_BLANK_TYPE_LOYALTY: 'AGREEMENT_BLANK_TYPE_LOYALTY',

    /**
     * Отказ от участия в ПЛ.
     */
    AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL: 'AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL',

    /**
     * Анализ на качественное определение антигенов.
     */
    ANTIGEN_TEST: 'ANTIGEN_TEST',

    /**
     * Направление на цитологическое исследование.
     */
    CYTOLOGY: 'CYTOLOGY',

    /**
     * Исследование качества воды и почвы.
     */
    ECOLOGY: 'ECOLOGY',

    /**
     * Фемофлор.
     */
    FEMOFLOR: 'FEMOFLOR',

    /**
     * Молекулярно-генетическое исследование.
     */
    GENETICS: 'GENETICS',

    /**
     * FIBROMAX.
     */
    FIBROMAX: 'FIBROMAX',

    /**
     * Направительный бланк.
     */
    MAIN_FORM: 'MAIN_FORM',

    /**
     * ANTROPOMETRY.
     */
    ANTROPOMETRY: 'ANTROPOMETRY',

    /**
     * Направление на гистологическое исследование.
     */
    HISTOLOGY: 'HISTOLOGY',

    /**
     * Интерфероновый статус.
     */
    INTERFERON_STATUS: 'INTERFERON_STATUS',

    /**
     * Высокотехнологичное исследование качества воды.
     */
    ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER: 'ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER	',

    /**
     * Направление на микробиологическое исследование.
     */
    MICRIBIOLOGY: 'MICRIBIOLOGY',

    /**
     * Микроскопическое исследование проб кожи и ногтей на присутствие клеток грибов.
     */
    MICROSCOPIC_STUDY_ON_FUNGAL_CELLS: 'MICROSCOPIC_STUDY_ON_FUNGAL_CELLS',

    /**
     * Радионуклидный анализ воды.
     */
    ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER: 'ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER',

    /**
     * ПЦР-диагностика инфекционных заболеваний.
     */
    PCR: 'PCR',

    /**
     * Информированное согласие на проведение обследования на ВИЧ-инфекцию.
     */
    AIDS: 'AIDS',

    /**
     * Исследование микроэлементов.
     */
    STUDIES_OF_TRACE_ELEMENTS: 'STUDIES_OF_TRACE_ELEMENTS',

    /**
     * Скрининговое химическое исследование воды.
     */
    ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER: 'ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER',

    /**
     * ПЦР DIGENE-TEST.
     */
    PCR_DIGENE_TEST: 'PCR_DIGENE_TEST',

    /**
     * Исследование качества воды и почвы.
     */
    ECOLOGY_SOIL: 'ECOLOGY_SOIL',

    /**
     * Исследование клеща.
     */
    ENCEPHALITIS: 'ENCEPHALITIS',

    /**
     * Гастропанель.
     */
    GLUCOSE_TUBES_OF_GRAY: 'GLUCOSE_TUBES_OF_GRAY',

    /**
     * Направительный бланк(смета).
     */
    ESTIMATE: 'ESTIMATE',

    /**
     * Анкета молекулярно-генетического исследования.
     */
    MOLECULAR_GENETIC: 'MOLECULAR_GENETIC',

    /**
     * Prisca - программа пренатального скрининга.
     */
    PRISCA: 'PRISCA',

    /**
     * Акт возврата.
     */
    REFUND_ACT: 'REFUND_ACT',

    /**
     * Исследование эякулята.
     */
    SPERMOGRAMM: 'SURVEYSPERMOGRAMM',

    /**
     * Обследования.
     */
    SURVEYS: 'SURVEYS',

    /**
     * Инфомированное добровольное согласие пациента на сложное медицинское вмешательство.
     */
    VOLUNTARY: 'VOLUNTARY'
};
