//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const CITIES_URL = `${BASE_URL}/cities`;

export const fetch = (data) => {
    const { cityId } = data;
    return apiClient.get(`${CITIES_URL}/${cityId}`);
};

export const fetchCapital = (data) => {
    const { territory } = data;
    return apiClient.get(`${CITIES_URL}/capital?territory=${territory}`);
};

export const fetchAll = () => {
    return apiClient.get(`${CITIES_URL}`);
};
