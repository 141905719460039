import FormField from 'components/form/field/FormField';
import React, { FC, memo, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';

import styles from './SelectPicker.module.css';

interface IProps {
    error?: boolean;
    name: string;
    label: string;
    options: any;
    notice?: any;
    className?: string;
    classNamePrefix?: string;
    placeholder?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    onChange: (event: any) => void;
    value: { value: string | number; label: Element | string | ReactElement };
}

const SelectPicker: FC<IProps> = ({
    error,
    name,
    label,
    value,
    notice,
    options,
    onChange,
    className,
    classNamePrefix = 'simple-selectpicker',
    placeholder = '',
    isClearable = false,
    isSearchable = false,
    isDisabled = false
}) => {
    const { formatMessage } = useIntl();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (notice) {
            document
                .querySelector('.simple-selectpicker__menu-list')
                ?.insertAdjacentHTML('beforeend', `<div class=${styles.phoneRegistrationNotice}>${formatMessage(notice)}</div>`);
        }
    }, [notice, menuIsOpen]);

    const onMenuClose = () => {
        setMenuIsOpen(false);
        setIsError(true);
    };

    const onMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const getSelectBorderClass = () => {
        if (error && isError && !value) {
            return styles.selectPickerError;
        }
        return '';
    };

    return (
        <FormField label={label} className={className}>
            <ReactSelect
                name={name}
                value={value}
                options={options}
                onChange={onChange}
                isDisabled={isDisabled}
                isClearable={isClearable}
                placeholder={placeholder}
                isSearchable={isSearchable}
                classNamePrefix={classNamePrefix}
                className={`${styles.selectPickerContainer} ${getSelectBorderClass()}`}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
            />
        </FormField>
    );
};

export default memo(SelectPicker);
