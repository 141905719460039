import Footer from 'page/layout/footer/Footer';
import Header from 'page/layout/header/Header';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import styles from './ReactivateInstructionPage.module.css';
import messages from './ReactivateInstructionPageMessages';

const ReactivateInstructionPage: FC = () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.pageContainer}>
            <Header />
            <div className={styles.outerContainer}>
                <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
                <p>{formatMessage(messages.note1)}</p>
                <p>{formatMessage(messages.note2)}</p>
                <ol className={styles.stepList}>
                    <li>
                        {formatMessage(messages.step1)}
                        &nbsp;
                        <a href='/login'>{formatMessage(messages.step1link)}</a>;
                    </li>
                    <li>{formatMessage(messages.step2)}</li>
                    <li>{formatMessage(messages.step3)}</li>
                    <li>{formatMessage(messages.step4)}</li>
                    <li>{formatMessage(messages.step5)}</li>
                </ol>
                <p>
                    {formatMessage(messages.note3)}
                    <a href='/recovery'>{formatMessage(messages.note3link)}</a>.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default ReactivateInstructionPage;
