//@ts-nocheck
import { OrderType } from 'constants/orderType';
import { compareDesc, parseISO } from 'date-fns';
import { createSelector } from 'reselect';
import { isCheckup } from 'utils/classifierTypeUtils';
import { isSolutionTypeAccepted } from 'utils/orderUtils';

export const ordersSelector = (state) => state.orders;

const ordersByIdSelector = (state) => state.orders.entities;

export const preorderPaySelector = (state) => state.orders.preorderPay;

export const ordersSortedSelector = createSelector([ordersByIdSelector], (entities) => sortOrders(Object.values(entities)));

export const acceptedInfoSelector = (state) => state.orders.acceptedInfo;

export const productsByAcceptSelector = createSelector([ordersSortedSelector], (orders) => {
    return orders.reduce((acc, order) => {
        if (order.orderType === OrderType.ORDER) {
            acc.push({ ...order, checkupProducts: order.products.filter((product) => isCheckup(product.product)) });
        } else {
            if (order.accepts.filter(isSolutionTypeAccepted).length > 1) {
                const accepts = [...order.accepts.filter(isSolutionTypeAccepted)];
                const idxOfMainOrder = accepts.reduce((acc, curr, i) => (new Date(order.accepts[acc].accepted) < new Date(curr.accepted) ? acc : i), 0);
                const mainOrder = accepts.splice(idxOfMainOrder, 1);
                const mainProducts = getProductsByAccept(mainOrder, order.products);
                const reorderProducts = getProductsByAccept(accepts, order.products);
                acc.push({ ...order, mainProducts, reorderProducts });
            } else {
                acc.push({ ...order, mainProducts: order.products });
            }
        }
        return acc;
    }, []);
});

function sortOrders(orders) {
    return orders.sort((o1, o2) =>
        o1?.createdTime && o2?.createdTime ? compareDesc(parseISO(o1.createdTime), parseISO(o2.createdTime)) : o1?.createdTime ? -1 : o2?.createdTime ? 1 : 0
    );
}

function getProductsByAccept(accepts, products) {
    return accepts
        .reduce((acc, accept) => {
            const resultProduct = products.filter((product) => accept.positions.includes(product.id));
            acc.push(...resultProduct);
            return acc;
        }, [])
        .filter((product) => !product.deleted);
}
