import { createRequestTypes } from 'redux/helpers';
import { City } from 'types/common';
export const SHOW_CITY_PANEL = createRequestTypes('CITY/SHOW_CITY_PANEL');
export const FETCH_CITY = createRequestTypes('CITY/FETCH_CITY');
export const FETCH_CAPITAL_CITY = createRequestTypes('CITY/FETCH_CAPITAL_CITY');
export const FETCH_LIST_CITY = createRequestTypes('CITY/FETCH_LIST_CITY');
export const CHANGE_CITY = createRequestTypes('CITY/CHANGE_CITY');
export const CHANGE_CHECKUP_CITY = createRequestTypes('CITY/CHANGE_CHECKUP_CITY');
export const SET_FILTER = createRequestTypes('CITY/SET_FILTER');
export const UPDATE_OUTPUT_LIST_CITY = createRequestTypes('CITY/UPDATE_OUTPUT_LIST_CITY');
export const SHOW_CHANGE_CITY_FORM = createRequestTypes('CITY/SHOW_CHANGE_CITY_FORM');
export const CHECKUP_SHOW_CHANGE_CITY_FORM = createRequestTypes('CITY/CHECKUP_SHOW_CHANGE_CITY_FORM');
export const UPDATE_CITY_LETTERS = createRequestTypes('CITY/UPDATE_CITY_LETTERS');
export const GET_ACTIVE_CITY = createRequestTypes('CITY/GET_ACTIVE_CITY');
export const SET_ACTIVE_CITY = createRequestTypes('CITY/SET_ACTIVE_CITY');
export const SET_ACTIVE_CHECKUP_CITY = createRequestTypes('CITY/SET_ACTIVE_CHECKUP_CITY');
export const CLEAR_ACTIVE_CHECKUP_CITY = createRequestTypes('CITY/CLEAR_ACTIVE_CHECKUP_CITY');
export const UPDATE_COUNTRY_CODE = createRequestTypes('CITY/UPDATE_COUNTRY_CODE');

export const changeCity = (payload: City) => CHANGE_CITY.base(payload);
export const changeCheckupCity = (payload: City) => CHANGE_CHECKUP_CITY.base(payload);
export const setFilter = (payload: { type: string; param: string }) => SET_FILTER.base(payload);
export const fetchListCity = () => FETCH_LIST_CITY.base();
export const updateCityLetters = (payload: City[]) => UPDATE_CITY_LETTERS.base(payload);
export const showCityPanel = (payload: boolean) => SHOW_CITY_PANEL.base(payload);
export const showChangeCityForm = (payload?: boolean) => SHOW_CHANGE_CITY_FORM.base(payload);
export const showChangeCityFormCheckup = (payload?: boolean) => CHECKUP_SHOW_CHANGE_CITY_FORM.base(payload);
