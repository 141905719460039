import { createSelector } from 'reselect';
import { Store } from 'types/store';

export const localeSelector = createSelector([(state: Store.App.State) => state.i18n], (locale) => ({
    currentMessages: locale.currentMessages,
    defaultLocale: locale.defaultLocale,
    defaultMessages: locale.defaultMessages,
    locale: locale.currentLocale,
    messagesCache: locale.messagesCache
}));
