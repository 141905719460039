//@ts-nocheck
import * as cartClient from 'client/CartClient';
import { OfficeType } from 'constants/officeType';
import { CALCULATE_OFFICE, UPDATE_OFFICE_TYPE } from 'redux/cart/actions';
import { APPOINTMENT_COOKIE_KEY } from 'redux/cart/constants';
import { cartDataAnalyzesArticlesSelector, cartDataProductsIdsSelector, onlineRegistrationEnabledSelector } from 'redux/cart/selectors';
import { currentCitySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { CHOOSE_DEFAULT_OFFICE, CHOOSE_OFFICE, FETCH_ALL, SELECT_OFFICE } from 'redux/offices/actions';
import { OFFICE_STORAGE_KEY } from 'redux/offices/constants';
import { chosenOfficeSelector } from 'redux/offices/selectors';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import LocalStorageService from 'services/LocalStorageService';
import { getCookie } from 'utils/browserUtils';

export function* chooseDefaultOffice() {
    const officeIdLocalStorage = yield LocalStorageService.getItem(OFFICE_STORAGE_KEY);
    const officeIdCovidOrder = yield JSON.parse(getCookie(APPOINTMENT_COOKIE_KEY) || null);
    let officeId = officeIdLocalStorage;
    if (officeIdCovidOrder?.number) {
        officeId = officeIdCovidOrder?.officeId.toLowerCase();
    }
    if (officeId) {
        const city = yield select(currentCitySelector);
        const productsArticles = yield select(cartDataAnalyzesArticlesSelector);
        const officesResponse = yield* fetchOffices({
            meta: {
                articles: productsArticles,
                cityId: city.id,
                officesIds: [officeId]
            }
        });
        const offices = officesResponse?.data;
        if (offices && offices.length > 0) {
            const onlineRegistrationEnabled = yield select(onlineRegistrationEnabledSelector);
            if (onlineRegistrationEnabled && !offices[0].office.onlineRegistrationSupported) {
                return;
            }
            const productsIds = yield select(cartDataProductsIdsSelector);
            const selectionResult = yield* selectOffice({
                meta: {
                    officeId,
                    productsIds
                }
            });
            if (selectionResult) {
                yield put(CHOOSE_OFFICE.base({ id: officeId }));
            }
        }
    }
}

function* fetchOffices(action) {
    return yield request(FETCH_ALL, [cartClient.fetchOffices, action.meta]);
}

export function* selectOffice(action) {
    return yield request(SELECT_OFFICE, [cartClient.validate, action.meta], action.meta);
}

export function* chooseOffice(action) {
    yield put(CHOOSE_OFFICE.success({}, action.meta));
    yield put(UPDATE_OFFICE_TYPE.base({ type: OfficeType.OFFICE }));
    LocalStorageService.setItem(OFFICE_STORAGE_KEY, action.meta.id);
    const cartOffice = yield select(chosenOfficeSelector);
    if (cartOffice?.allProductsAvailable) {
        yield put(CALCULATE_OFFICE.base());
    }
}

export default function* officesSagas() {
    yield all([
        takeLatest(FETCH_ALL.BASE, fetchOffices),
        takeLatest(SELECT_OFFICE.BASE, selectOffice),
        takeLatest(CHOOSE_OFFICE.BASE, chooseOffice),
        takeLatest(CHOOSE_DEFAULT_OFFICE.BASE, chooseDefaultOffice)
    ]);
}
