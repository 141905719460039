//@ts-nocheck
import { CountryCode } from 'libphonenumber-js/mobile';
import { COUNTRY_CODES_BY_TERRITORY, SUPPORTED_TERRITORIES, TERRITORIES_BY_DOMAIN } from 'redux/cities/constants';
import { getHostname } from 'utils/browserUtils';

export function getHostnameTerritory() {
    const parts = getHostname().split('.');
    const firstLevelDomain = parts[parts.length - 1];
    return TERRITORIES_BY_DOMAIN[firstLevelDomain] || SUPPORTED_TERRITORIES.RUSSIA;
}

export const getCountryCode = () => {
    const territory = getHostnameTerritory();
    return COUNTRY_CODES_BY_TERRITORY[territory] as CountryCode;
};
