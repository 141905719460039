import classNames from 'classnames/bind';
import React, { FC, memo } from 'react';

import styles from './Button.module.css';
const cx = classNames.bind(styles);

type TProps = {
    text?: string | (() => string);
    onClick?: (event: any) => void;
    className?: string;
    children?: any;
    disabled?: boolean;
    innerRef?: any;
    title?: string;
    icon?: string;
};

const Button: FC<TProps> = ({ text, className, onClick, children, disabled, innerRef, icon, ...rest }) => {
    return (
        <button disabled={disabled} className={cx(styles.button, className)} onClick={onClick} ref={innerRef} {...rest}>
            {icon && <img className={styles.icon} src={icon} alt='' />}
            {children ? children : text}
        </button>
    );
};

export default memo(Button);
