import { defineMessages } from 'react-intl';

export default defineMessages({
    alfaPay: {
        defaultMessage: 'Подели',
        id: 'Payment.alfaPay'
    },
    alfaPayDescription: {
        defaultMessage: 'Payment in parts',
        id: 'Payment.alfaPayDescription'
    },
    office: {
        defaultMessage: 'In medical office',
        id: 'Payment.office'
    },
    officeDescription: {
        defaultMessage: 'Office {officeName}',
        id: 'Payment.officeDescription'
    },
    online: {
        defaultMessage: 'Cart on the site',
        id: 'Payment.online'
    },
    onlineDescription: {
        defaultMessage: 'МИР, MasterCard, Visa',
        id: 'Payment.onlineDescription'
    },
    sberPay: {
        defaultMessage: 'SberBank',
        id: 'Payment.sberPay'
    },
    sberPayDescription: {
        defaultMessage: 'SberBank Application',
        id: 'Payment.sberPayDescription'
    },
    title: {
        defaultMessage: 'Payment methods',
        id: 'Payment.title'
    }
});
