import { defineMessages } from 'react-intl';

export default defineMessages({
    accrued: {
        defaultMessage: 'Accrued:',
        id: 'IKBBonusHistoryRecord.accrued'
    },
    order: {
        defaultMessage: 'Order №{number}',
        id: 'IKBBonusHistoryRecord.order'
    },
    orderReturn: {
        defaultMessage: 'Order return №{number}',
        id: 'IKBBonusHistoryRecord.orderReturn'
    },
    paid: {
        defaultMessage: 'Paid:',
        id: 'IKBBonusHistoryRecord.paid'
    },
    writeOff: {
        defaultMessage: 'Written Off:',
        id: 'IKBBonusHistoryRecord.writeOff'
    }
});
