export enum BonusTransactionType {
    SALE_POINTS = 'SALE_POINTS',

    RETURN_POINTS = 'RETURN_POINTS',

    PROMOTION_POINTS = 'PROMOTION_POINTS',

    PROMOTION_SALE_POINTS = 'PROMOTION_SALE_POINTS',

    MANUAL_ADJUSTMENT_POINTS = 'MANUAL_ADJUSTMENT_POINTS',

    MIGRATION = 'MIGRATION',

    COUPON = 'COUPON'
}
