export const Currency = {
    /**
     * Армянский драм
     */
    AMD: 'AMD',

    /**
     * Белорусский рубль (до 1 июля 2016 года)
     */
    BYR: 'BYR',

    /**
     * Белорусский рубль
     */
    BYN: 'BYN',

    /**
     * Евро
     */
    EUR: 'EUR',

    /**
     * Российский рубль
     */
    RUB: 'RUB',

    /**
     * Киргизский сом
     */
    KGS: 'KGS',

    /**
     * Российский рубль (до реформы 1998 года)
     */
    RUR: 'RUR',

    /**
     * Американский доллар
     */
    USD: 'USD',

    /**
     * Казахстанский тенге
     */
    KZT: 'KZT'
};
