//@ts-nocheck
import { FETCH_DOCUMENTS, FETCH_SIGNED_DOCUMENTS } from 'redux/documents/actions';
import { basicReducer } from 'redux/helpers';

const initialState = {
    fetchDocuments: {},
    fetchSignedDocuments: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DOCUMENTS.STARTED:
        case FETCH_DOCUMENTS.SUCCEEDED:
        case FETCH_DOCUMENTS.ERRORED: {
            return {
                ...state,
                fetchDocuments: basicReducer(state.fetchDocuments, action)
            };
        }
        case FETCH_SIGNED_DOCUMENTS.STARTED:
        case FETCH_SIGNED_DOCUMENTS.SUCCEEDED:
        case FETCH_SIGNED_DOCUMENTS.ERRORED: {
            return {
                ...state,
                fetchSignedDocuments: basicReducer(state.fetchSignedDocuments, action)
            };
        }
        default:
            return state;
    }
}
