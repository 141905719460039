import { defineMessages } from 'react-intl';

export default defineMessages({
    error: {
        defaultMessage: 'Unable to initialize application.',
        id: 'Init.error'
    },
    loading: {
        defaultMessage: 'Loading...',
        id: 'Init.loading'
    }
});
