//@ts-nocheck
import * as cityClient from 'client/CityClient';
import { TerritoryType } from 'constants/territoryType';
import {
    CHANGE_CHECKUP_CITY,
    CHANGE_CITY,
    CHECKUP_SHOW_CHANGE_CITY_FORM,
    FETCH_CAPITAL_CITY,
    FETCH_CITY,
    FETCH_LIST_CITY,
    GET_ACTIVE_CITY,
    SET_ACTIVE_CHECKUP_CITY,
    SET_ACTIVE_CITY,
    SHOW_CHANGE_CITY_FORM,
    SHOW_CITY_PANEL,
    UPDATE_CITY_LETTERS,
    UPDATE_COUNTRY_CODE
} from 'redux/cities/actions';
import { CITY_GUID_COOKIE_KEY, COUNTRY_CODES_BY_TERRITORY, INVITRO_LKP_COUNTRY_CODE } from 'redux/cities/constants';
import { currentCitySelector } from 'redux/cities/selectors';
import { request } from 'redux/helpers';
import { CLEAR_OFFICE } from 'redux/offices/actions';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';
import { getHostnameTerritory } from 'utils/hostNameUtils';

function* fetchCities(action) {
    yield request(FETCH_LIST_CITY, [cityClient.fetchAll, action.meta]);
    const state = yield select();
    const currentTerritory = currentCitySelector(state).territory;
    const cities = yield state.cities.entities[currentTerritory];
    yield put(UPDATE_CITY_LETTERS.base(cities));
}

function* getActiveCity() {
    const cityId = yield cookieService.get(CITY_GUID_COOKIE_KEY);

    const territory = getHostnameTerritory();
    const countryCode = COUNTRY_CODES_BY_TERRITORY[territory];

    yield put({ meta: { currentCountryCode: countryCode }, type: UPDATE_COUNTRY_CODE.BASE });

    let response;

    if (cityId) {
        response = yield request(FETCH_CITY, [cityClient.fetch, { cityId }]);
        const responseTerritory = response?.data?.territory;

        if (response.error || responseTerritory !== territory) {
            response = yield request(FETCH_CAPITAL_CITY, [cityClient.fetchCapital, { territory }]);
        }
    } else {
        response = yield request(FETCH_CAPITAL_CITY, [cityClient.fetchCapital, { territory }]);
        yield put(SHOW_CITY_PANEL.base(true));
    }

    if (response) {
        yield updateCookieCityGuid({ city: response.data, currentCountryCode: countryCode });
    }
}

function* changeCity(action) {
    const city = action.meta;
    const response = yield request(FETCH_CITY, [cityClient.fetch, { cityId: city.id }]);
    const currentCountryCode = COUNTRY_CODES_BY_TERRITORY[response.data.territory];

    yield put({ meta: { currentCountryCode }, type: UPDATE_COUNTRY_CODE.BASE });

    if (response) {
        yield updateCookieCityGuid({ city: response.data, currentCountryCode });
    }
}

function* changeCheckupCity(action) {
    const city = action.meta;
    yield put(CHECKUP_SHOW_CHANGE_CITY_FORM.base(false));
    yield put(SET_ACTIVE_CHECKUP_CITY.base(city));
}

function* updateCookieCityGuid({ city, currentCountryCode }) {
    cookieService.set(CITY_GUID_COOKIE_KEY, city.id, 365);
    cookieService.set(INVITRO_LKP_COUNTRY_CODE, currentCountryCode, 365);
    yield put(CLEAR_OFFICE.base());
    yield put(SHOW_CITY_PANEL.base(false));
    yield put(SHOW_CHANGE_CITY_FORM.base(false));
    yield put(SET_ACTIVE_CITY.base(city));
}

export default function* citiesSagas() {
    yield all([
        takeLatest(FETCH_LIST_CITY.BASE, fetchCities),
        takeLatest(GET_ACTIVE_CITY.BASE, getActiveCity),
        takeLatest(CHANGE_CITY.BASE, changeCity),
        takeLatest(CHANGE_CHECKUP_CITY.BASE, changeCheckupCity)
    ]);
}
