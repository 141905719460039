import { defineMessages } from 'react-intl';

export default defineMessages({
    buttonText: {
        defaultMessage: 'To Home Page',
        id: 'NotFoundPage.buttonText'
    },
    text: {
        defaultMessage: 'Sorry, the page you requested was not found. Use the search to find the page you want or go to the home page of the site.',
        id: 'NotFoundPage.text'
    },
    title: {
        defaultMessage: 'Page not found',
        id: 'NotFoundPage.title'
    }
});
