import { useAppDispatch, useAppSelector } from 'app/Hooks';
import LoaderModal from 'components/modal/loader/LoaderModal';
// @ts-ignore
import { isEmpty } from 'lodash';
import commonMessages from 'messages/CommonMessages';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import NextChangeDays from 'page/loyalty/ikb/components/NextChangeDays';
import RegularPageConfirmCard from 'page/loyalty/ikb/regular/RegularPageConfirmCard/RegularPageConfirmCard';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { setCashbackStatus } from 'redux/loyaltyIKB/actions';
import { cashbackStatusSelector, dataLoyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import { fetchLoyaltyStatus } from 'redux/loyaltyStatus/actions';
import { select } from 'redux/patient/actions';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { IKBRegularCategory } from 'types/common';
import { getDaysToChanges } from 'utils/cashbackUtils';

import styles from '../style.module.css';

const IKBRegularDetailsPage: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const territory = useAppSelector(currentTerritorySelector);
    const cashbackStatus = useAppSelector(cashbackStatusSelector);
    const { loyaltyStatus, loadingLoyaltyStatus } = useAppSelector(dataLoyaltyIKBSelector);
    const IKBLoyalty = new IKBLoyaltyService(null, intl);

    const [state, setState] = useState<{
        daysToChanges: number;
        regularMultiplicator: IKBRegularCategory | null;
    }>({
        daysToChanges: 0,
        regularMultiplicator: null
    });

    useEffect(() => {
        const patientId = searchParams.get('patientId');
        if (isEmpty(cashbackStatus) && patientId) {
            dispatch(select(patientId));
            dispatch(fetchLoyaltyStatus({ patientId: patientId, territory }));
        } else if (cashbackStatus) {
            const regularMultiplicator = IKBLoyalty.getRegularMultiplicator([cashbackStatus.category]);
            setState((prevState) => ({ ...prevState, regularMultiplicator, daysToChanges: getDaysToChanges(cashbackStatus.newChangeDate) }));
        }
    }, []);

    useEffect(() => {
        const patientId = searchParams.get('patientId');
        if (isEmpty(cashbackStatus) && !loadingLoyaltyStatus && loyaltyStatus && patientId) {
            const bonusStatus = loyaltyStatus?.bonusProgramStatus?.bonusStatus;
            dispatch(setCashbackStatus(bonusStatus));
            const regularMultiplicator = IKBLoyalty.getRegularMultiplicator([bonusStatus?.category]);
            setState((prevState) => ({ ...prevState, regularMultiplicator, daysToChanges: getDaysToChanges(bonusStatus?.newChangeDate) }));
        }
    }, [loadingLoyaltyStatus]);

    return (
        <Fragment>
            <LoaderModal show={loadingLoyaltyStatus} />
            <IKBNavigationBar title={formatMessage(commonMessages.regularCashback)} back={true} />
            <div className={styles.confirmPageWrapper}>
                <div className={styles.container}>
                    <section>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardContent}>
                                {state?.regularMultiplicator?.percents?.map((category, i) => (
                                    <RegularPageConfirmCard key={i} message={category.message} percent={category.percent} />
                                ))}
                            </div>
                            <div className={styles.cardDivider}></div>
                            <div className={styles.cardConfirmInfo}>
                                <p>
                                    <NextChangeDays days={state.daysToChanges} />
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    );
};

export default IKBRegularDetailsPage;
