import classNames from 'classnames';
import React, { FC, memo } from 'react';

import styles from './ActionCard.module.css';
const cx = classNames.bind(styles);

type TProps = {
    show?: boolean;
    icon: string;
    data?: any;
    text: string;
    loading?: boolean;
    className?: string;
    classNameText?: string;
    onClick: () => void;
    newFeature?: boolean;
};

const ActionCard: FC<TProps> = ({ show = true, icon, data, text, className, classNameText, newFeature, loading, ...rest }) => {
    return show ? (
        <div className={classNames(styles.container, className, loading ? styles.loading : '')} {...rest}>
            <div className={styles.top}>
                {icon && <img className={styles.icon} alt='' src={icon} />}
                {newFeature && <span className={styles.newFeature}>Новое</span>}
                {data && <div className={styles.data}>{data}</div>}
            </div>
            <div className={cx([styles.text, classNameText])}>{text}</div>
        </div>
    ) : null;
};

export default memo(ActionCard);
