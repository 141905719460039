//@ts-nocheck
import * as loyaltyClient from 'client/LoyaltyClient';
import { request } from 'redux/helpers';
import { FETCH_BONUS_HISTORY, FETCH_DISCOUNT_HISTORY } from 'redux/loyaltyHistory/actions';
import { all, takeLatest } from 'redux-saga/effects';

function* fetchBonusHistory(action) {
    yield request(FETCH_BONUS_HISTORY, [loyaltyClient.fetchBonusHistory, action.meta]);
}

function* fetchDiscountHistory(action) {
    yield request(FETCH_DISCOUNT_HISTORY, [loyaltyClient.fetchDiscountHistory, action.meta]);
}

export default function* loyaltyHistorySagas() {
    yield all([takeLatest(FETCH_BONUS_HISTORY.BASE, fetchBonusHistory), takeLatest(FETCH_DISCOUNT_HISTORY.BASE, fetchDiscountHistory)]);
}
