import { createRequestTypes } from 'redux/helpers';

export const FETCH_CONCLUSIONS = createRequestTypes('CONCLUSIONS/FETCH_CONCLUSIONS');
export const FETCH_NEXT_CONCLUSIONS_BATCH = createRequestTypes('CONCLUSIONS/FETCH_NEXT_CONCLUSIONS_BATCH');
export const ADD_RECOMMENDED_PRODUCT = createRequestTypes('CONCLUSIONS/ADD_RECOMMENDED_PRODUCT');
export const SEND_DOCUMENT = createRequestTypes('CONCLUSIONS/SEND_DOCUMENT');
export const CLEAR_CONCLUSIONS_ALL = createRequestTypes('CONCLUSIONS/CLEAR_ALL');
export const CLEAR_SEND = createRequestTypes('CONCLUSIONS/CLEAR_SEND');
export const CANCEL_APPOINTMENT = createRequestTypes('CONCLUSIONS/CANCEL_APPOINTMENT');

interface IPayload {
    patientId: string;
    skip: number;
    take: number;
    startDate: Date | string | null;
    endDate: Date | string | null;
    type: string;
}

interface SendDocument {
    patientId: string;
    email: string;
    documentId: number;
    territory: string;
    additionalEmail1?: string;
    additionalEmail2?: string;
    additionalEmail3?: string;
    additionalEmail4?: string;
}

interface CancelAppointment {
    patientId: string;
    appointmentId: string;
}

export const fetchConclusions = (payload: IPayload) => FETCH_CONCLUSIONS.base(payload);
export const fetchNextConclusionsBatch = (payload: IPayload) => FETCH_NEXT_CONCLUSIONS_BATCH.base(payload);
export const clearAllConclusions = () => CLEAR_CONCLUSIONS_ALL.base();

export const addRecommendationProduct = (payload: string) => ADD_RECOMMENDED_PRODUCT.base(payload);
export const sendDocument = (payload: SendDocument) => SEND_DOCUMENT.base(payload);
export const clearSendDocument = () => CLEAR_SEND.base();
export const cancelAppointment = (payload: CancelAppointment) => CANCEL_APPOINTMENT.base(payload);
