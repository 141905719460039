import { defineMessages } from 'react-intl';

export default defineMessages({
    checkupTitle: {
        defaultMessage: 'Health programs:',
        id: 'OrderList.checkupTitle'
    },
    itemTitle: {
        defaultMessage: 'Products:',
        id: 'OrderList.itemTitle'
    }
});
