import IconCart from 'components/icon/cart/IconCart';
import styles from 'page/layout/header/middle/Cart.module.css';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { cartDataProductsCountSelector } from 'redux/cart/selectors';

const Cart = () => {
    const productsCount = useSelector(cartDataProductsCountSelector);

    return (
        <div className={styles.container + ' ' + styles.row + ' ' + styles.center}>
            <NavLink to={'/cart'} className={styles.iconBlock}>
                <IconCart className={styles.icon} />
                {productsCount > 0 && <div className={styles.count}>{productsCount}</div>}
            </NavLink>
        </div>
    );
};

export default Cart;
