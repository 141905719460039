//@ts-nocheck
import { paymentSystemsIcons } from 'components/icon/paymentSystems';
import { NUMBER } from 'page/cart/confirmation/cardForm/cardForm.config';
import messages from 'page/cart/confirmation/cardForm/cardForm.messages';
import React, { Component } from 'react';

import style from '../CardForm.module.css';

export class PaymentSystems extends Component {
    render() {
        const { intl } = this.props;

        return (
            <>
                <div className={style.payments}>
                    {paymentSystemsIcons.map((icon) => (
                        <img key={icon.id} src={icon.src} alt={intl.formatMessage(messages[icon.alt])} />
                    ))}
                </div>
                <p className={style.paymentsText}>{intl.formatMessage(messages.description)}</p>
                <p className={style.paymentsText}>
                    {intl.formatMessage(messages.refundMessage, {
                        number: NUMBER
                    })}
                </p>
            </>
        );
    }
}
