//@ts-nocheck
import classNames from 'classnames/bind';
import * as React from 'react';
import { getFreeCallText } from 'utils/messageUtils';

import styles from './Phone.module.css';

interface IPhoneProps {
    intl: any;
    territory: string;
    data: any;
    className?: string;
}
const Phone = (props: IPhoneProps) => {
    const { className, data, territory, intl } = props;

    return (
        <div className={classNames(styles.container, className)}>
            <span>
                <a className={styles.link} href={'tel:' + data.number}>
                    {data.name}
                </a>
            </span>
            {data.free && <span className={styles.description}>{getFreeCallText(intl, territory)}</span>}
        </div>
    );
};

export default Phone;
