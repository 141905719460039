import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        defaultMessage: 'Cancel',
        id: 'ReorderModal.cancel'
    },
    countProductsInCart: {
        defaultMessage: 'The current contents of your cart ({count, plural, one {# product} other {# products}}) will be cleared.{br}{br}',
        id: 'ReorderModal.countProductsInCart'
    },
    description: {
        defaultMessage:
            'This service allows you to add additional studies to the current order without re-applying to the medical office and without paying for the taking of biomaterial. The laboratory uses the biomaterial that you submitted earlier.{br}{br}<b>Important!</b> The service does not apply to all studies. Click the <b>Select tests to reorder</b> button to view a list of available tests.{br}{br}You can pay for the service only by credit card.{br}{br}',
        id: 'ReorderModal.description'
    },
    select: {
        defaultMessage: 'Select tests to reorder',
        id: 'ReorderModal.select'
    },
    title: {
        defaultMessage: 'What is Reorder?',
        id: 'ReorderModal.title'
    }
});
