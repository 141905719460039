import { defineMessages } from 'react-intl';

export default defineMessages({
    notification: {
        defaultMessage:
            'Certain examinations may not be available at all medical offices. Before making a choice, please check the availability of the products you are interested in at the convenient medical office.',
        id: 'IKBNotification.notification'
    },
    title: {
        defaultMessage: 'Note!',
        id: 'IKBNotification.title'
    }
});
