import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Switch from 'components/switch/Switch';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import { IKBNumberOfServices } from 'constants/ikbNumberOfServices';
import commonMessages from 'messages/CommonMessages';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import messages from 'page/loyalty/ikb/solutions/IKBSolutionsPageMessages';
import React, { FC, Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedDate, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchMultiplicatorsCategory, fetchSolutionsCategory, selectCashbackSolution, setCashbackType, setSearchTextServices } from 'redux/loyaltyIKB/actions';
import { fetchSolutionsCategorySelector, loyaltyIKBSelector, solutionsCategoryDataSelector, solutionsSelector } from 'redux/loyaltyIKB/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { IKBSolution } from 'types/common';

import styles from './IKBSolutionsPage.module.css';

const IKBSolutionsPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const solutionsCategory = useAppSelector(solutionsSelector);
    const { territory } = useAppSelector(currentTerritorySettingsSelector);
    const { selectedCashbackSolutions } = useAppSelector(loyaltyIKBSelector);
    const fetchCategoriesData = useAppSelector(fetchSolutionsCategorySelector);
    const solutionsCategoryData = useAppSelector(solutionsCategoryDataSelector);
    const [solutions, setSolutions] = useState<IKBSolution[]>([]);

    useEffect(() => {
        setSolutions((prevState) => prevState.concat(solutionsCategory));
    }, [solutionsCategory]);

    useEffect(() => {
        dispatch(setCashbackType(ikbCashbackType.READY_SOLUTIONS));
        handleFetch();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setSearchTextServices(''));
        };
    }, []);
    const getPatientId = () => {
        return searchParams.get('patientId') as string;
    };
    useEffect(() => {
        const patientId = getPatientId();
        if (patientId) {
            dispatch(
                fetchMultiplicatorsCategory({
                    patientId: patientId,
                    territory
                })
            );
        } else {
            navigate('/loyalty');
        }
    }, []);

    const handleFetch = () => {
        if (!fetchCategoriesData.loading) {
            const patientId = searchParams.get('patientId');
            const positionFrom = solutions.length || 0;
            const positionTo = solutions.length + IKBNumberOfServices;
            const payload = {
                patientId,
                positionFrom,
                positionTo,
                territory
            };

            dispatch(fetchSolutionsCategory(payload));
        }
    };

    const handleSelectCashbackSolution = (solution: IKBSolution, isSelectedSolution: boolean) => {
        if (isSelectedSolution && selectedCashbackSolutions.length >= 1) {
            return;
        }
        return dispatch(
            selectCashbackSolution({
                selected: isSelectedSolution,
                solution
            })
        );
    };

    const renderContentColumns = (solutions: IKBSolution[]) => {
        return solutions.map((solution) => {
            const isSelectedSolution = selectedCashbackSolutions.filter((selectedSolution) => solution.categoryId === selectedSolution.categoryId).length > 0;
            return (
                <div
                    className={classNames({
                        [styles.card]: true,
                        [styles.selectedSolution]: isSelectedSolution
                    })}
                    key={solution.categoryId}
                >
                    <div className={styles.infoBlock}>
                        <div className={styles.topBlock}>
                            <h3 className={styles.title} onClick={() => handleSelectCashbackSolution(solution, isSelectedSolution)}>
                                {solution.name}
                            </h3>
                            <div className={styles.solutionSwitch}>
                                <Switch active={isSelectedSolution} onClick={() => handleSelectCashbackSolution(solution, isSelectedSolution)} />
                            </div>
                        </div>
                        {solution.validDate && (
                            <div className={styles.solutionExpire}>
                                {formatMessage(messages.solutionExpire)}{' '}
                                <FormattedDate value={new Date(solution.validDate)} day='numeric' month='long' year='numeric' />
                            </div>
                        )}
                    </div>
                    <div className={styles.products}>
                        {solution.products?.map((product) => (
                            <div className={styles.productItem} key={product.productId}>
                                <Link className={styles.productTitle} to={`/loyalty/ikb/cashback/solutions/product/${product.productId}`}>
                                    {product.shortName}
                                </Link>
                                <div className={styles.productDiscount}>{product.charge}%</div>
                            </div>
                        ))}
                    </div>
                    {solution.charge && (
                        <div className={styles.notice}>
                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({ id: 'Common.otherProductsNotice' })
                                    }}
                                />{' '}
                                {solution.charge}%
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    const renderCards = () => {
        return (
            <div className={styles.cards}>
                {solutionsCategory.length > 1 && (
                    <Fragment>
                        <div className={styles.leftColumn}>{renderContentColumns(solutionsCategory.filter((item, i) => i % 2 === 0 && item))}</div>
                        <div className={styles.rightColumn}>{renderContentColumns(solutionsCategory.filter((item, i) => i % 2 > 0 && item))}</div>
                    </Fragment>
                )}
                {solutionsCategory.length === 1 && renderContentColumns(solutionsCategory)}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <>
                <LoaderModal show={fetchCategoriesData.loading} />
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <IKBCashbackTypesButtons />
                </IKBNavigationBar>
                <div className={styles.container}>
                    <IKBNotification />
                    {renderCards()}
                </div>
                <CategoriesPanel
                    maxCountSelected={1}
                    categories={selectedCashbackSolutions}
                    cashbackType={ikbCashbackType.READY_SOLUTIONS}
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/solutions/confirmation?patientId=${searchParams.get('patientId')}`)}
                />
            </>
        );
    };

    return (
        <InfiniteScroll
            next={handleFetch}
            hasChildren={true}
            loader={<Fragment />}
            dataLength={solutions.length}
            hasMore={solutions.length >= IKBNumberOfServices && solutionsCategoryData.hasMore}
        >
            {renderContent()}
        </InfiniteScroll>
    );
};

export default IKBSolutionsPage;
