import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Loader from 'components/loader/Loader';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import commonMessages from 'messages/CommonMessages';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import messages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
import RegularPageCard from 'page/loyalty/ikb/regular/RegularPageCard/RegularPageCard';
import React, { FC, Fragment, ReactNode, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// @ts-ignore
import { MediaContext } from 'react-media-query-hoc';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setCashbackType } from 'redux/loyaltyIKB/actions';
import { fetchSolutionsCategorySelector, multiplicatorsCategorySelector } from 'redux/loyaltyIKB/selectors';
import IKBLoyaltyService from 'services/IKBLoyaltyService';

import calendar from './assets/calendar.svg';
import cart from './assets/cart.svg';
import infoIcon from './assets/infoIcon.svg';
import styles from './style.module.css';

const IKBRegularPage: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const media = useContext<{ mobile: boolean }>(MediaContext);
    const multiplicators = useAppSelector(multiplicatorsCategorySelector);
    const fetchCategoriesData = useAppSelector(fetchSolutionsCategorySelector);

    const renderContent = () => {
        const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
        const regularMultiplicator = ikbLoyaltyService.getRegularMultiplicator(multiplicators);

        useEffect(() => {
            dispatch(setCashbackType(ikbCashbackType.REGULAR_CASHBACK));
        }, []);

        if (fetchCategoriesData && fetchCategoriesData.loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        }

        return (
            <Fragment>
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <IKBCashbackTypesButtons />
                </IKBNavigationBar>
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <IKBNotification />
                        <section>
                            <div className={styles.detailsContainer}>
                                <div className={styles.detailsCard}>
                                    <img src={cart} alt='cart' />
                                    <p>
                                        <FormattedMessage
                                            {...messages.cardInfo}
                                            values={{
                                                b: (v) => <b>{v}</b>
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className={styles.detailsCard}>
                                    <img src={calendar} alt='calendar' />
                                    <p>{formatMessage(messages.cardInfo2)}</p>
                                </div>
                            </div>
                            <div className={styles.featureContainer}>
                                {regularMultiplicator?.percents?.map((category, i) => (
                                    <RegularPageCard key={i} index={i} {...category} />
                                ))}
                            </div>
                            <div className={styles.infoContainer}>
                                {!media.mobile && <img src={infoIcon} alt='infoIcon' />}
                                <div>
                                    {formatMessage(messages.info, {
                                        p: (value: ReactNode) => <p>{value}</p>
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <CategoriesPanel
                    maxCountSelected={1}
                    cashbackType={ikbCashbackType.REGULAR_CASHBACK}
                    categories={[{ name: formatMessage(commonMessages.regularCashback) }]}
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/regular/confirm?patientId=${searchParams.get('patientId')}`)}
                />
            </Fragment>
        );
    };

    return renderContent();
};

export default IKBRegularPage;
