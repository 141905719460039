import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Switch from 'components/switch/Switch';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import { IKBNumberOfServices } from 'constants/ikbNumberOfServices';
import commonMessages from 'messages/CommonMessages';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import SearchComponent from 'page/orders/component/search/SearchComponent';
import React, { FC, Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import {
    clearSelectedCashbackServices,
    fetchServicesCategory,
    searchServicesCategory,
    selectCashbackService,
    setCashbackType,
    setSearchTextServices
} from 'redux/loyaltyIKB/actions';
import { fetchServicesCategorySelector, loyaltyIKBSelector, servicesCategoryDataSelector, servicesSelector } from 'redux/loyaltyIKB/selectors';
import { IKBService } from 'types/common';

import clearSearchIcon from './assets/clearSearchIcon.svg';
import noResultsIcon from './assets/noResultsIcon.svg';
import styles from './IKBServicesPage.module.css';
import messages from './IKBServicesPageMessages';

const IKBServicesPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const territory = useAppSelector(currentTerritorySelector);
    const servicesCategory = useAppSelector(servicesSelector);
    const servicesCategoryData = useAppSelector(servicesCategoryDataSelector);
    const fetchCategoriesData = useAppSelector(fetchServicesCategorySelector);
    const { searchTextServices, selectedCashbackServices } = useAppSelector(loyaltyIKBSelector);
    const [services, setServices] = useState<IKBService[]>([]);
    const patientId = searchParams.get('patientId') as string;

    useEffect(() => {
        setServices((prevState) => prevState.concat(servicesCategory));
    }, [servicesCategory]);

    useEffect(() => {
        dispatch(setCashbackType(ikbCashbackType.INDIVIDUAL_SERVICES));

        return () => {
            dispatch(setSearchTextServices(''));
        };
    }, []);

    useEffect(() => {
        handleFetch();
    }, [searchTextServices]);

    const handleFetch = () => {
        if (!fetchCategoriesData.loading) {
            const positionFrom = services.length || 0;
            const positionTo = services.length + IKBNumberOfServices;
            const payload = {
                patientId,
                positionFrom,
                positionTo,
                territory
            };

            if (searchTextServices.length > 0) {
                dispatch(searchServicesCategory({ ...payload, searchText: searchTextServices }));
            } else {
                dispatch(fetchServicesCategory(payload));
            }
        }
    };

    const handleSelectCashbackService = (service: IKBService, isSelectedService: boolean) => {
        if (!isSelectedService && selectedCashbackServices.length >= servicesCategoryData.maxProducts) {
            return;
        }
        return dispatch(
            selectCashbackService({
                selected: isSelectedService,
                service: service
            })
        );
    };

    const renderContent = () => {
        const patientId = searchParams.get('patientId') as string;

        return (
            <>
                <LoaderModal show={fetchCategoriesData.loading} />
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <div className='navigationBarInner'>
                        <IKBCashbackTypesButtons />
                    </div>
                </IKBNavigationBar>
                <div
                    className={classNames({
                        [styles.container]: true,
                        [styles.noResultsWrap]: services.length < 1
                    })}
                >
                    <IKBNotification />
                    <div className='navigationBarInner'>
                        <SearchComponent
                            // @ts-ignore
                            clearReset={true}
                            name='searchTextServices'
                            className={styles.searchString}
                            value={searchTextServices}
                            placeholder={formatMessage(messages.searchHint)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setSearchTextServices(event.target.value));
                                setServices([]);
                            }}
                            clearIcon={
                                <img alt='' src={clearSearchIcon} className={styles.clearSearchIcon} onClick={() => dispatch(setSearchTextServices(''))} />
                            }
                        />
                    </div>
                    {searchTextServices?.length > 0 && services.length < 1 && !fetchCategoriesData.loading && (
                        <div className={styles.noResults}>
                            <img alt='' src={noResultsIcon} className={styles.noResultsIcon} />
                            <h1 className={styles.noResultsTitle}>{formatMessage(messages.noResultsTitle)}</h1>
                            <p className={styles.noResultsInfo}>{formatMessage(messages.noResultsInfo)}</p>
                        </div>
                    )}
                    <div className={styles.cards}>
                        {services.length > 0 &&
                            services.map((service: any) => {
                                const isSelectedService =
                                    selectedCashbackServices.filter((selectedService) => service.productId === selectedService.productId).length > 0;

                                return (
                                    <div
                                        className={classNames({
                                            [styles.card]: true,
                                            [styles.selectedService]: isSelectedService,
                                            [styles.disabledService]: !isSelectedService && selectedCashbackServices.length >= service.maxFavoriteProducts
                                        })}
                                        key={service.productId}
                                    >
                                        <div className={styles.infoBlock}>
                                            <div className={styles.info}>
                                                <div className={styles.topBlock}>
                                                    <h3 className={styles.title} onClick={() => handleSelectCashbackService(service, isSelectedService)}>
                                                        {formatMessage(commonMessages.numberFormat, {
                                                            number: service.article
                                                        })}
                                                    </h3>
                                                    <div className={styles.discountSwitchBlock}>
                                                        <div className={styles.discount}>{service.charge}%</div>
                                                        <div className={styles.serviceSwitch}>
                                                            <Switch
                                                                active={isSelectedService}
                                                                onClick={() => handleSelectCashbackService(service, isSelectedService)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link
                                                    state={{ service }}
                                                    className={styles.description}
                                                    to={`/loyalty/ikb/cashback/services/product/${service.productId}`}
                                                >
                                                    {service.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <CategoriesPanel
                    categories={selectedCashbackServices}
                    maxCountSelected={3}
                    onClear={() => dispatch(clearSelectedCashbackServices())}
                    onCategoryClear={(service: IKBService) =>
                        dispatch(
                            selectCashbackService({
                                selected: true,
                                service: service
                            })
                        )
                    }
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/services/confirmation?patientId=${patientId}`)}
                    cashbackType={ikbCashbackType.INDIVIDUAL_SERVICES}
                />
            </>
        );
    };

    return (
        <InfiniteScroll
            next={handleFetch}
            hasChildren={true}
            dataLength={services.length}
            loader={<Fragment />}
            hasMore={services.length >= IKBNumberOfServices && servicesCategoryData.hasMore}
        >
            {renderContent()}
        </InfiniteScroll>
    );
};

export default IKBServicesPage;
