import { useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import Modal from 'components/modal/Modal';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { cartDataProductsSelector } from 'redux/cart/selectors';

import styles from './ReorderModal.module.css';
import messages from './ReorderModalMessages';

interface IProps {
    showModal: boolean;
    onClose: () => void;
    handleReorder: () => void;
}

const ReorderModal: FC<IProps> = ({ showModal, onClose, handleReorder }) => {
    const { formatMessage } = useIntl();
    const cartProducts = useAppSelector(cartDataProductsSelector);
    return (
        <Modal show={showModal} className={styles.reorderModal} onClose={onClose} closable>
            <h2>{formatMessage(messages.title)}</h2>
            <p>{formatMessage(messages.description, { b: (v) => <b>{v}</b>, br: <br /> })}</p>
            <>
                {cartProducts.length > 0 && (
                    <p className={styles.countProducts}>{formatMessage(messages.countProductsInCart, { br: <br />, count: cartProducts.length })}</p>
                )}
            </>
            <div className={styles.buttons}>
                <Button className={styles.choose} text={formatMessage(messages.select)} onClick={handleReorder} />
                <ButtonSecondary onClick={onClose} text={formatMessage(messages.cancel)} />
            </div>
        </Modal>
    );
};

export default memo(ReorderModal);
