import { defineMessages } from 'react-intl';

export default defineMessages({
    accrued: {
        defaultMessage: 'Points accrued',
        id: 'IKBBonusHistoryPage.accrued'
    },
    bonusHistory: {
        defaultMessage: 'Bonus account history',
        id: 'IKBBonusHistoryPage.bonusHistory'
    },
    bonusMovement: {
        defaultMessage: 'Accruals and withdrawals',
        id: 'IKBBonusHistoryPage.bonusMovement'
    },
    changesProgram: {
        defaultMessage: 'Program changes',
        id: 'IKBBonusHistoryPage.changesProgram'
    },
    date: {
        defaultMessage: 'Date',
        id: 'IKBBonusHistoryPage.date'
    },
    event: {
        defaultMessage: 'Event',
        id: 'IKBBonusHistoryPage.event'
    },
    eventType: {
        defaultMessage: 'Event type',
        id: 'IKBBonusHistoryPage.eventType'
    },
    fetchError: {
        defaultMessage: 'Unable to get bonus history',
        id: 'IKBBonusHistoryPage.fetchError'
    },
    noHistory: {
        defaultMessage: 'There is no history for the selected period',
        id: 'IKBBonusHistoryPage.noHistory'
    },
    paid: {
        defaultMessage: 'Paid',
        id: 'IKBBonusHistoryPage.paid'
    },
    periodLabel: {
        defaultMessage: 'View by period',
        id: 'IKBBonusHistoryPage.periodLabel'
    },
    writeOff: {
        defaultMessage: 'Points written off',
        id: 'IKBBonusHistoryPage.writeOff'
    }
});
