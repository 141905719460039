import { defineMessages } from 'react-intl';

export default defineMessages({
    addBtn: {
        defaultMessage: 'Add',
        id: 'AddResultDialog.addBtn'
    },
    additionalFieldsNote: {
        defaultMessage: 'It is necessary to provide additional information to add the results. You can only view them otherwise.',
        id: 'AddResultDialog.additionalFieldsNote'
    },
    lastNameNote: {
        defaultMessage: 'Patient lastname from receipt',
        id: 'AddResultDialog.lastNameNote'
    },
    orderNumberLabel: {
        defaultMessage: 'Order number',
        id: 'AddResultDialog.orderNumberLabel'
    },
    orderNumberNote: {
        defaultMessage: 'Enter 9 numbers from receipt (OIN)',
        id: 'AddResultDialog.orderNumberNote'
    },
    orderNumberPlaceholder: {
        defaultMessage: '9 numbers',
        id: 'AddResultDialog.orderNumberPlaceholder'
    },
    success: {
        defaultMessage: 'Result added successfully',
        id: 'AddResultDialog.success'
    },
    title: {
        defaultMessage: 'Add results',
        id: 'AddResultDialog.title'
    }
});
