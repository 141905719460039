import classNames from 'classnames';
import InputRadio from 'components/input/radio/InputRadio';
import React, { FC, memo, ReactElement } from 'react';
import { OnlinePayment, Payment } from 'types/common';

import style from './GroupSwitch.module.css';

type TButtonProps = {
    active: boolean;
    icon: string;
    title: string;
    description: string;
    selected: boolean;
    onChange?: (value: Payment | OnlinePayment) => void;
    value: Payment | OnlinePayment;
};

export const Button: FC<TButtonProps> = ({ active, icon, title, description, selected, onChange, value }) => {
    const handleChange = () => {
        if (onChange) {
            onChange(value);
        }
    };

    if (!active) {
        return null;
    }

    return (
        <button onClick={handleChange} className={classNames(style.button, { [style.selected]: selected })}>
            <div className={style.icon}>
                <img src={icon} alt='' />
            </div>
            <div className={style.content}>
                <div className={style.title}>{title}</div>
                <div className={style.description}>{description}</div>
            </div>
            <div>
                <InputRadio value={value} checked={selected} onChange={handleChange} />
            </div>
        </button>
    );
};

type TGroupSwitchProps = {
    children: ReactElement | ReactElement[];
    onChange?: (value: Payment) => void;
};

const GroupSwitch: FC<TGroupSwitchProps> = ({ children, onChange }) => {
    return (
        <div className={style.container}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // @ts-ignore
                    return React.cloneElement(child, { onChange });
                }
                return child;
            })}
        </div>
    );
};

export default memo(GroupSwitch);
