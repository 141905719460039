export const OrderType = {
    /**
     * Заказ
     */
    ORDER: 'ORDER',

    /**
     * Предзаказ
     */
    PREORDER: 'PREORDER',

    /**
     * ДОЗАКАЗ
     */
    REORDER: 'REORDER'
};
