import { defineMessages } from 'react-intl';

export default defineMessages({
    clear: {
        defaultMessage: 'Clear',
        id: 'IKBServicesPage.clear'
    },
    continue: {
        defaultMessage: 'Continue',
        id: 'IKBServicesPage.continue'
    },
    noResultsInfo: {
        defaultMessage: 'Please repeat the search with a different request.',
        id: 'IKBServicesPage.noResultsInfo'
    },
    noResultsTitle: {
        defaultMessage: 'Nothing found',
        id: 'IKBServicesPage.noResultsTitle'
    },
    searchHint: {
        defaultMessage: 'Enter analysis number or name',
        id: 'IKBServicesPage.searchHint'
    }
});
