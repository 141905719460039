//@ts-nocheck
import { getPaymentStatus } from 'client/OrderClient';
import { FETCH_PAYMENT_STATUS } from 'redux/cart/actions';
import { MAX_POLLING_COUNT, PAYMENT_STATUS, POLLING_DELAY, POLLING_ERROR_STATUS } from 'redux/cart/constants';
import { push, replace } from 'redux-first-history';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

function* paymentStatus(action) {
    yield race({
        cancel: take(FETCH_PAYMENT_STATUS.CANCELLED),
        task: call(paymentStatusPolling, { payload: action.meta })
    });
}

export function* paymentStatusPolling(action) {
    const pollingCount = action.payload.pollingCount ?? MAX_POLLING_COUNT;
    const pollingDelay = action.payload.pollingDelay ?? POLLING_DELAY;

    let currentPoolingCount = 0;

    while (true) {
        if (currentPoolingCount === pollingCount) {
            yield put({ payload: { type: POLLING_ERROR_STATUS.MAX_POLLING }, type: FETCH_PAYMENT_STATUS.ERRORED });
            yield put({ type: FETCH_PAYMENT_STATUS.CANCELLED });
            currentPoolingCount = 0;
        }

        try {
            const { orderId, patientId, orderType, paymentType } = action.payload;

            const payload = { orderId, orderType, patientId, paymentType };
            const { data } = yield call(getPaymentStatus, payload);

            const { success, transactionStatus } = data;

            if (success) {
                switch (transactionStatus) {
                    case PAYMENT_STATUS.AWAITING: {
                        yield put({
                            payload: {
                                ...data
                            },
                            type: FETCH_PAYMENT_STATUS.AWAITING
                        });
                        yield delay(pollingDelay);
                        break;
                    }

                    case PAYMENT_STATUS.AUTHORIZED:
                    case PAYMENT_STATUS.COMPLETED:
                    case PAYMENT_STATUS.CANCELLED:
                    case PAYMENT_STATUS.DECLINED: {
                        yield put({
                            payload: {
                                ...data
                            },
                            type: FETCH_PAYMENT_STATUS.SUCCEEDED
                        });
                        yield put({ type: FETCH_PAYMENT_STATUS.CANCELLED });
                        break;
                    }

                    default: {
                        ++currentPoolingCount;
                        yield put({ type: FETCH_PAYMENT_STATUS.PROGRESS });
                        yield delay(pollingDelay);
                    }
                }
            } else {
                ++currentPoolingCount;
                yield put({ type: FETCH_PAYMENT_STATUS.PROGRESS });
                yield delay(pollingDelay);
            }
        } catch (error) {
            const statusCode = error?.response?.status;
            if (statusCode === 401) {
                const location = yield select((state) => state.router.location);
                yield put(push('/login', { referrer: location }));
                yield put({ type: FETCH_PAYMENT_STATUS.CANCELLED });
            } else if (statusCode === 403) {
                yield put(replace('/orders'));
                yield put({ type: FETCH_PAYMENT_STATUS.CANCELLED });
            } else {
                ++currentPoolingCount;
                yield delay(pollingDelay);
            }
        }
    }
}

export default function* paymentSaga() {
    yield takeLatest(FETCH_PAYMENT_STATUS.BASE, paymentStatus);
}
