//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { CLEAR_HISTORY, FETCH_BONUS_HISTORY, FETCH_DISCOUNT_HISTORY } from 'redux/loyaltyHistory/actions';

const initialState = {
    bonusHistory: [],
    discountHistory: [],
    fetchBonusHistory: {},
    fetchDiscountHistory: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_BONUS_HISTORY.STARTED:
        case FETCH_BONUS_HISTORY.SUCCEEDED:
        case FETCH_BONUS_HISTORY.ERRORED:
            let bonusHistory = state.bonusHistory;
            if (action.type === FETCH_BONUS_HISTORY.SUCCEEDED) {
                bonusHistory = action.payload.data;
            }
            return {
                ...state,
                bonusHistory,
                fetchBonusHistory: basicReducer(state.fetchBonusHistory, action)
            };
        case FETCH_DISCOUNT_HISTORY.STARTED:
        case FETCH_DISCOUNT_HISTORY.SUCCEEDED:
        case FETCH_DISCOUNT_HISTORY.ERRORED:
            let discountHistory = state.discountHistory;
            if (action.type === FETCH_DISCOUNT_HISTORY.SUCCEEDED) {
                discountHistory = action.payload.data;
            }
            return {
                ...state,
                discountHistory,
                fetchDiscountHistory: basicReducer(state.fetchDiscountHistory, action)
            };
        case CLEAR_HISTORY.BASE:
            return {
                ...state,
                bonusHistory: [],
                discountHistory: []
            };
        default:
            return state;
    }
}
