//@ts-nocheck
import withRouter from 'adapters/WithRouter';
import Button from 'components/button/Button';
import FormField from 'components/form/field/FormField';
import Input from 'components/input/Input';
import LanguageSelector from 'components/language/LanguageSelector';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import ModalSuccess from 'components/modal/success/ModalSuccess';
import commonMessages from 'messages/CommonMessages';
import iconMail from 'page/orders/assets/details/result/iconMail.svg';
import ActionCard from 'page/orders/component/actionCard/ActionCard';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { SUPPORTED_LOCALES } from 'redux/i18n/constants';
import { CLEAR_SEND_EMAIL_RESULT_UNAUTH, SEND_EMAIL_RESULT, SEND_EMAIL_RESULT_UNAUTH } from 'redux/result/actions';
import FormHelper from 'utils/FormHelper';
import { validateEmail } from 'utils/validators';

import styles from './ResultEmailControl.module.css';

class ResultEmailControl extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.result.patient.email,
            emailLang: SUPPORTED_LOCALES.RUSSIAN,
            errors: {},
            showEmailModal: false,
            showSuccessModal: false
        };
        const validators = {
            email: validateEmail
        };
        this.formHelper = new FormHelper(this, validators);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sendSuccess !== this.props.sendSuccess && this.props.sendSuccess) {
            this.handleModalClose();
            this.setState({ showSuccessModal: true });
        }
    }

    render() {
        const { intl, loading } = this.props;
        return (
            <React.Fragment>
                <ActionCard
                    icon={iconMail}
                    classNameText={styles.text}
                    text={intl.formatMessage(commonMessages.sendByEmail)}
                    onClick={(event) => this.handleSendEmail(event)}
                />
                {this.renderEmailModal()}
                <LoaderModal show={loading} />
            </React.Fragment>
        );
    }

    renderEmailModal() {
        const { intl, result } = this.props;
        return (
            <React.Fragment>
                <Modal show={this.state.showEmailModal} className={styles.emailModal} onClose={(event) => this.handleModalClose(event)} closable>
                    <h2>
                        <FormattedMessage {...commonMessages.sendByEmail} />
                    </h2>
                    {this.formHelper.renderError()}
                    <FormField label={intl.formatMessage(commonMessages.email)}>
                        <Input
                            type='email'
                            name='email'
                            defaultValue={this.props.result.patient.email}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors.email}
                        />
                    </FormField>
                    {result.filesLocales.length > 1 ? (
                        <FormField label={intl.formatMessage(commonMessages.language)}>
                            <LanguageSelector
                                className={styles.emailLangSelector}
                                locales={result.filesLocales}
                                locale={this.state.emailLang}
                                changeRequest={(locale) => this.setState({ emailLang: locale.locale })}
                            />
                        </FormField>
                    ) : null}

                    <Button className={styles.sendBtn} text={intl.formatMessage(commonMessages.send)} onClick={(event) => this.sendEmail(event)} />
                </Modal>
                <ModalSuccess
                    message={intl.formatMessage(commonMessages.sendEmailSuccess)}
                    show={this.state.showSuccessModal}
                    onClose={() => this.setState({ showSuccessModal: false })}
                />
            </React.Fragment>
        );
    }

    handleSendEmail(event) {
        event.stopPropagation();
        this.setState({ showEmailModal: true });
    }

    handleModalClose(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ showEmailModal: false });
        const { clear } = this.props;
        if (clear) {
            clear();
        }
    }

    sendEmail(event) {
        event.stopPropagation();
        if (this.formHelper.validate(['email'])) {
            this.setState({ sending: true });
            const { resultKey, patient, result, sendEmail, sendEmailUnauth, territory } = this.props;
            const { email, emailLang } = this.state;

            if (resultKey) {
                sendEmailUnauth({ email, key: resultKey, lang: emailLang, territory });
            } else {
                sendEmail({ email, inz: result.inz, lang: emailLang, patientId: patient.id, territory });
            }
        }
    }
}

ResultEmailControl.propTypes = {
    patient: PropTypes.object,
    result: PropTypes.object.isRequired,
    resultKey: PropTypes.string
};

const mapStateToProps = (state) => {
    const sendEmail = state.result.sendEmail;
    return {
        error: sendEmail.error,
        loading: sendEmail.loading,
        sendSuccess: sendEmail.success,
        territory: currentTerritorySelector(state)
    };
};

const mapDispatchToProps = {
    clearSendEmail: CLEAR_SEND_EMAIL_RESULT_UNAUTH.base,
    sendEmail: SEND_EMAIL_RESULT.base,
    sendEmailUnauth: SEND_EMAIL_RESULT_UNAUTH.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResultEmailControl)));
