//@ts-nocheck
import * as resultClient from 'client/ResultClient';
import * as unauthResultClient from 'client/UnauthResultClient';
import { loadBlobFile } from 'utils/fileUtils';

class ResultService {
    async handleFile(request) {
        const response = await resultClient.getPdf({ ...request });
        loadBlobFile(response);
    }

    async handleFileUnauth(request) {
        const response = await unauthResultClient.getPdf({ ...request });
        loadBlobFile(response);
    }

    async handleViewFile(request) {
        const response = await unauthResultClient.getPdf({ ...request });
        loadBlobFile(response);
    }
}

export default new ResultService();
