//@ts-nocheck
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './ds_3.0.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app/App';

// Изменить значение на "true" чтобы в консоль браузера сыпались предупреждения
// когда вызывается метод "render" компонента без необходимости.
const debugRender = false;
if (debugRender && process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true
    });
}

const domContainer = document.querySelector('#root');
const root = ReactDOM.createRoot(domContainer);
root.render(<App />);
