import { defineMessages } from 'react-intl';

export default defineMessages({
    info: {
        defaultMessage: 'To continue, you need to confirm any of your contacts. Which contact is convenient for you to confirm?',
        id: 'IKBConfirmationPage.info'
    },
    navigationTitle: {
        defaultMessage: 'Come back',
        id: 'IKBConfirmationPage.navigationTitle'
    }
});
