//@ts-nocheck
import ButtonView from 'components/view/ButtonView';
import messages from 'page/orders/details/results/result/action/view/ResultViewControlMessages';
import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { SUPPORTED_LOCALES } from 'redux/i18n/constants';
import ResultService from 'services/ResultService';

class ResultViewControl extends React.PureComponent {
    render() {
        const { result, intl } = this.props;
        if (!result.filesLocales) {
            return null;
        } else if (result.filesLocales.length > 1) {
            return (
                <React.Fragment>
                    <ButtonView text={intl.formatMessage(messages.viewTranslation)} onClick={(event) => this.handleView(event, SUPPORTED_LOCALES.ENGLISH)} />
                    <ButtonView onClick={(event) => this.handleView(event, SUPPORTED_LOCALES.RUSSIAN)} />
                </React.Fragment>
            );
        } else {
            return <ButtonView onClick={(event) => this.handleView(event, SUPPORTED_LOCALES.RUSSIAN)} />;
        }
    }

    handleView(event, lang) {
        event.stopPropagation();
        const { resultKey, territory } = this.props;
        ResultService.handleViewFile({ download: false, key: resultKey, lang, territory });
    }
}

ResultViewControl.propTypes = {
    patient: PropTypes.object,
    result: PropTypes.object.isRequired,
    resultKey: PropTypes.string
};

export default injectIntl(ResultViewControl);
