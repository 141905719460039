//@ts-nocheck
import * as userClient from 'client/UserClient';
import { CLEAR as AUTH_CLEAR, LOGOUT } from 'redux/auth/actions';
import { USER_ID_COOKIE_KEY } from 'redux/auth/constants';
import { request } from 'redux/helpers';
import { DEACTIVATE, FETCH, FETCH_HISTORY, PASSWORD, UPDATE, UPDATE_EMAIL, UPDATE_PHONE } from 'redux/user/actions';
import { USER_COOKIE_NAME } from 'redux/user/constants';
import { all, put, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';

function* fetchUser() {
    yield request(FETCH, [userClient.fetchCurrent]);
}

function* updateUser(action) {
    yield request(UPDATE, [userClient.updateUser, action.meta]);
}

function* updateEmail(action) {
    yield request(UPDATE_EMAIL, [userClient.updateEmail, action.meta]);
}

function* updatePhone(action) {
    yield request(UPDATE_PHONE, [userClient.updatePhone, action.meta]);
}

function* updatePassword(action) {
    yield request(PASSWORD, [userClient.updatePassword, action.meta]);
}

function* updateHistory(action) {
    yield request(FETCH_HISTORY, [userClient.fetchHistory, action.meta]);
}

function* deactivateUser(action) {
    yield request(DEACTIVATE, [userClient.deactivate, action.meta]);
}

function* clearAuth() {
    yield put(LOGOUT.base());
}

function* updateCookie(action) {
    const user = action?.payload?.data;
    if (user) {
        const { nickname, email, phone } = user;
        yield cookieService.set(USER_COOKIE_NAME, JSON.stringify({ email, nickname, phone }));
    } else {
        yield cookieService.remove(USER_COOKIE_NAME);
    }
}

function* removeCookie() {
    yield cookieService.remove(USER_COOKIE_NAME);
    yield cookieService.remove(USER_ID_COOKIE_KEY);
}

export default function* userSagas() {
    yield all([
        takeLatest(FETCH.BASE, fetchUser),
        takeLatest(FETCH.SUCCEEDED, updateCookie),
        takeLatest(UPDATE.BASE, updateUser),
        takeLatest(UPDATE.SUCCEEDED, updateCookie),
        takeLatest(UPDATE_EMAIL.BASE, updateEmail),
        takeLatest(UPDATE_EMAIL.SUCCEEDED, updateCookie),
        takeLatest(UPDATE_PHONE.BASE, updatePhone),
        takeLatest(UPDATE_PHONE.SUCCEEDED, updateCookie),
        takeLatest(PASSWORD.BASE, updatePassword),
        takeLatest(FETCH_HISTORY.BASE, updateHistory),
        takeLatest(DEACTIVATE.BASE, deactivateUser),
        takeLatest(DEACTIVATE.SUCCEEDED, clearAuth),
        takeLatest(AUTH_CLEAR.SUCCEEDED, removeCookie)
    ]);
}
