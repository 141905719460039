import { useAppSelector } from 'app/Hooks';
import Hint from 'components/hint/Hint';
import Cost from 'containers/const/Cost';
import DiscountModal from 'page/orders/component/discounts/DiscountModal';
import React, { FC, useState } from 'react';
import { appliedMarketingMechanicsSelector, saleActionSelector } from 'redux/cart/selectors';
import { Localization, MarketingMechanics } from 'types/common';

import styles from './Discounts.module.css';
import messages from './DiscountsMessages';

const Discounts: FC = () => {
    const saleAction = useAppSelector(saleActionSelector);
    const marketingMechanics = useAppSelector(appliedMarketingMechanicsSelector);
    const locale: Localization = useAppSelector((state) => state.i18n.currentLocale);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [currentMarketingMechanics, setCurrentMarketingMechanics] = useState<MarketingMechanics | null>(null);

    const renderDiscounts = () => {
        if (!saleAction?.employeeDiscount && !saleAction?.personalDiscount) {
            return null;
        }

        return (
            <div className={styles.discount}>
                <div className={styles.discount}>{saleAction.employeeDiscount?.description || saleAction.personalDiscount?.description}</div>
            </div>
        );
    };

    const renderMarketingMechanics = () => {
        if (marketingMechanics?.length > 0) {
            return marketingMechanics.map((mechanics) => {
                const { id, name, description, discount } = mechanics;
                return (
                    <div className={styles.discount} key={id}>
                        <div className={styles.name}>
                            {name}:
                            <Hint
                                className={styles.tip}
                                show={description?.length > 0}
                                onClick={() => {
                                    setCurrentMarketingMechanics(mechanics);
                                    setShowDiscountModal(true);
                                }}
                            >
                                {messages.tooltip[locale]}
                            </Hint>
                        </div>
                        <div className={styles.value}>
                            –<Cost amount={discount} currency={saleAction.currency} />
                        </div>
                    </div>
                );
            });
        }
    };

    return saleAction?.personalDiscount || saleAction?.employeeDiscount || marketingMechanics?.length > 0 ? (
        <div className={styles.container}>
            <h3>{messages.title[locale]}</h3>
            {renderDiscounts()}
            {renderMarketingMechanics()}
            <DiscountModal show={showDiscountModal} marketingMechanics={currentMarketingMechanics} onClose={() => setShowDiscountModal(false)} />
        </div>
    ) : null;
};

export default Discounts;
