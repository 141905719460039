//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import {
    ACTIVATE_ACCOUNT,
    CHANGE_CATEGORY,
    CLEAR_ACTIVATION,
    CLEAR_CASHBACK_STATUS,
    CLEAR_CHANGE_CATEGORY,
    CLEAR_CONFIRM_DATA,
    CLEAR_SELECTED_CASHBACK_SERVICES,
    CLEAR_SEND_ACTIVATION_PIN,
    FETCH_MOTIVATING_TOOLTIP,
    FETCH_MULTIPLICATORS_CATEGORY,
    FETCH_SERVICES_CATEGORY,
    FETCH_SOLUTIONS_CATEGORY,
    SEARCH_SERVICES_CATEGORY,
    SELECT_CASHBACK_SERVICE,
    SELECT_CASHBACK_SOLUTION,
    SEND_ACTIVATION_PIN,
    SET_ACTIVATION,
    SET_CASHBACK_STATUS,
    SET_CASHBACK_TYPE,
    SET_CONFIRM_DATA,
    SET_SEARCH_TEXT_SERVICES
} from 'redux/loyaltyIKB/actions';

const initialState = {
    activateAccount: {},
    activation: {},
    activeCashbackType: null,
    confirmLoyalty: {},
    fetchMultiplicatorsCategory: {},
    cashbackStatus: {},
    fetchServicesCategory: {},
    changeCategory: {},
    fetchSolutionsCategory: {},
    fetchMotivatingTooltip: {},
    sendActivationPin: {},
    multiplicatorsCategoryData: {},
    searchTextServices: '',
    selectedCashbackServices: [],
    selectedCashbackSolutions: [],
    servicesCategoryData: {},
    showMotivatingTooltip: false,
    solutionsCategoryData: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CASHBACK_TYPE.BASE: {
            const params = action?.meta;
            return {
                ...state,
                activeCashbackType: params
            };
        }
        case SELECT_CASHBACK_SOLUTION.BASE: {
            const { solution } = action?.meta;
            return {
                ...state,
                selectedCashbackSolutions: [solution]
            };
        }
        case SELECT_CASHBACK_SERVICE.BASE: {
            const { service, selected } = action?.meta;
            let selectedCashbackServices = [];
            if (!selected) {
                selectedCashbackServices = [...state.selectedCashbackServices];
                selectedCashbackServices.push(service);
            } else {
                selectedCashbackServices = state.selectedCashbackServices.filter((selectedService) => service.productId !== selectedService.productId);
            }
            return {
                ...state,
                selectedCashbackServices: [...selectedCashbackServices]
            };
        }
        case CLEAR_SELECTED_CASHBACK_SERVICES.BASE: {
            return {
                ...state,
                selectedCashbackServices: []
            };
        }
        case SEND_ACTIVATION_PIN.STARTED:
        case SEND_ACTIVATION_PIN.SUCCEEDED:
        case SEND_ACTIVATION_PIN.ERRORED: {
            return {
                ...state,
                sendActivationPin: basicReducer(state.sendActivationPin, action)
            };
        }
        case CLEAR_SEND_ACTIVATION_PIN.BASE: {
            return {
                ...state,
                sendActivationPin: {}
            };
        }
        case SET_ACTIVATION.BASE: {
            const { accountNumber, discountNumber, phone } = action.meta;
            return {
                ...state,
                activation: {
                    accountNumber,
                    discountNumber,
                    phone
                }
            };
        }
        case ACTIVATE_ACCOUNT.STARTED:
        case ACTIVATE_ACCOUNT.SUCCEEDED:
        case ACTIVATE_ACCOUNT.ERRORED: {
            let activation = state.activation;
            if (action.type === ACTIVATE_ACCOUNT.SUCCEEDED) {
                activation = {
                    ...activation,
                    status: action.payload.data
                };
            }
            return {
                ...state,
                activateAccount: basicReducer(state.activateAccount, action),
                activation
            };
        }
        case CLEAR_ACTIVATION.BASE: {
            return {
                ...state,
                activation: {}
            };
        }
        case SET_CONFIRM_DATA.BASE: {
            const { patientId, contactType, territory } = action.meta;
            return {
                ...state,
                confirmData: {
                    contactType,
                    patientId,
                    territory
                }
            };
        }
        case CLEAR_CONFIRM_DATA.BASE: {
            return {
                ...state,
                confirmData: {}
            };
        }
        case FETCH_MULTIPLICATORS_CATEGORY.STARTED:
        case FETCH_MULTIPLICATORS_CATEGORY.SUCCEEDED:
        case FETCH_MULTIPLICATORS_CATEGORY.ERRORED: {
            const data = action?.payload?.data || {};
            return {
                ...state,
                fetchMultiplicatorsCategory: basicReducer(state.fetchMultiplicatorsCategory, action),
                multiplicatorsCategoryData: { ...data }
            };
        }
        case FETCH_SOLUTIONS_CATEGORY.STARTED:
        case FETCH_SOLUTIONS_CATEGORY.SUCCEEDED:
        case FETCH_SOLUTIONS_CATEGORY.ERRORED: {
            const data = action?.payload?.data || {};

            return {
                ...state,
                fetchSolutionsCategory: basicReducer(state.fetchSolutionsCategory, action),
                solutionsCategoryData: { ...data, hasMore: data?.categories?.length > 0 }
            };
        }
        case FETCH_SERVICES_CATEGORY.STARTED:
        case FETCH_SERVICES_CATEGORY.SUCCEEDED:
        case FETCH_SERVICES_CATEGORY.ERRORED: {
            const data = action?.payload?.data || {};
            return {
                ...state,
                fetchServicesCategory: basicReducer(state.fetchServicesCategory, action),
                servicesCategoryData: { ...data, hasMore: data?.categories?.length > 0 }
            };
        }
        case SET_SEARCH_TEXT_SERVICES.BASE: {
            return {
                ...state,
                searchTextServices: action.meta
            };
        }
        case SEARCH_SERVICES_CATEGORY.STARTED:
        case SEARCH_SERVICES_CATEGORY.SUCCEEDED:
        case SEARCH_SERVICES_CATEGORY.ERRORED: {
            const data = action?.payload?.data || {};
            return {
                ...state,
                fetchServicesCategory: basicReducer(state.fetchServicesCategory, action),
                servicesCategoryData: { ...data, hasMore: data?.categories?.length > 0 }
            };
        }
        case SET_CASHBACK_STATUS.BASE: {
            return {
                ...state,
                cashbackStatus: action.meta
            };
        }
        case CLEAR_CASHBACK_STATUS.BASE: {
            return {
                ...state,
                cashbackStatus: {}
            };
        }
        case CHANGE_CATEGORY.STARTED:
        case CHANGE_CATEGORY.SUCCEEDED:
        case CHANGE_CATEGORY.ERRORED: {
            return {
                ...state,
                changeCategory: basicReducer(state.changeCategory, action)
            };
        }
        case CLEAR_CHANGE_CATEGORY.BASE: {
            return {
                ...state,
                changeCategory: {}
            };
        }
        case FETCH_MOTIVATING_TOOLTIP.SUCCEEDED: {
            return {
                ...state,
                fetchMotivatingTooltip: basicReducer(state.fetchMotivatingTooltip, action),
                showMotivatingTooltip: action.payload.data
            };
        }
        default:
            return state;
    }
}
