import { defineMessages } from 'react-intl';

export default defineMessages({
    note1: {
        defaultMessage:
            'If your account was deleted, you are able to recover access in 14 days. All personal information will be deleted and recovery will not be possible upon the expiration of this term.',
        id: 'ReactivateInstructionPage.note1'
    },
    note2: {
        defaultMessage: 'To recover access to deleted account you are to: ',
        id: 'ReactivateInstructionPage.note2'
    },
    note3: {
        defaultMessage: 'If the password is lost, you need to recover it first. You can reset it on ',
        id: 'ReactivateInstructionPage.note3'
    },
    note3link: {
        defaultMessage: 'login webpage',
        id: 'ReactivateInstructionPage.note3link'
    },
    step1: {
        defaultMessage: 'Go to the login page using the following link',
        id: 'ReactivateInstructionPage.step1'
    },
    step1link: {
        defaultMessage: 'login webpage',
        id: 'ReactivateInstructionPage.step1link'
    },
    step1link2: {
        defaultMessage: 'login webpage',
        id: 'ReactivateInstructionPage.step1link2'
    },
    step2: {
        defaultMessage: 'Enter your login (email or phone number that you used for registration):',
        id: 'ReactivateInstructionPage.step2'
    },
    step3: {
        defaultMessage: 'enter the code which was sent to registered email or phone number.',
        id: 'ReactivateInstructionPage.step3'
    },
    step4: {
        defaultMessage: 'You will see a page with a message stating that your personal account has been deleted. Click on the "Restore" button;',
        id: 'ReactivateInstructionPage.step4'
    },
    title: {
        id: 'ReactivateInstructionPage.title',
        defaultMessage: 'Deleted account recovery'
    },
    step5: {
        defaultMessage: 'Enter the confirmation code to agree to restore your personal account.',
        id: 'ReactivateInstructionPage.step5'
    }
});
