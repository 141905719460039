import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import Error from 'components/error/Error';
import FormField from 'components/form/field/FormField';
import InputDate from 'components/input/date/InputDate';
import Loader from 'components/loader/Loader';
import NotFoundContent from 'components/notfoundcontent/NotFoundContent';
import SelectPicker from 'components/selectpicker/SelectPicker';
import { subMonths } from 'date-fns';
import commonMessages from 'messages/CommonMessages';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { localeSelector } from 'redux/i18n/selectors';
import { clearHistory, fetchBonusHistory, fetchDiscountHistory } from 'redux/loyaltyHistory/actions';
import { filteredDiscountHistorySelector } from 'redux/loyaltyHistory/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { IKBLoyalty, IPatient } from 'types/common';
import FC_FormHelper from 'utils/FC_FormHelper';
import { formatDate, fromUnixTime } from 'utils/timeUtils';
import { validateNotBlank } from 'utils/validators';

import IKBBonusHistoryRecord from './bonusHistoryRecord/IKBBonusHistoryRecord';
import { EVENT_TYPE } from './constants';
import styles from './IKBBonusHistoryPage.module.css';
import messages from './IKBBonusHistoryPageMessages';

type TProps = {
    selected: IPatient;
    loyaltyStatus: IKBLoyalty.Status;
    territory: string;
};

type EventType = { value: string; label: string };

type TState = {
    currentDate: Date;
    eventType: EventType;
    eventTypes: Record<string, string>[];
    dateFrom: Date;
    dateUntil: Date;
    patientId: string;
};

const IKBBonusHistoryPage: FC<TProps> = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { locale } = useAppSelector((state) => localeSelector(state));
    const discountHistory = useAppSelector((state) => filteredDiscountHistorySelector(state));
    const loyaltyHistory = useAppSelector((state) => state.loyaltyHistory);
    const bonusHistory = loyaltyHistory.bonusHistory;
    const loading = loyaltyHistory.fetchBonusHistory.loading || loyaltyHistory.fetchDiscountHistory.loading;

    const getFilterMessages = () => {
        return {
            eventTypes: [
                { label: formatMessage(messages.bonusMovement), value: EVENT_TYPE.BONUS_MOVEMENT },
                { label: formatMessage(messages.changesProgram), value: EVENT_TYPE.CHANGES_PROGRAM }
            ]
        };
    };

    const getPatientId = () => {
        return searchParams.get('patientId') as string;
    };

    const initialState = () => {
        const { eventTypes } = getFilterMessages();
        const currentDate = new Date();
        return {
            currentDate,
            dateFrom: subMonths(currentDate, 1),
            dateUntil: currentDate,
            errors: {},
            eventType: eventTypes[0],
            eventTypes,
            patientId: getPatientId()
        };
    };

    const [state, setState] = useState<TState>(initialState());

    const validators = {
        dateFrom: validateNotBlank,
        dateUntil: validateNotBlank,
        eventType: (type: { value: string }) => state.eventTypes.filter((item) => item.value === type.value).length > 0
    };
    const formHelper = new FC_FormHelper(state, setState, null, validators);

    useEffect(() => {
        handleFetchBonusHistory();
        return () => {
            dispatch(clearHistory());
        };
    }, []);

    useEffect(() => {
        const { dateFrom, dateUntil, eventType } = state;
        if (!dateFrom || !dateUntil) {
            onFilter(dateFrom, dateUntil, eventType);
        }
    }, [state.dateFrom, state.dateUntil]);

    const transformDate = (date: Date) => {
        return formatDate(date, locale, 'yyyy-MM-dd');
    };

    const onFilter = (dateFrom: Date, dateUntil: Date, eventType: EventType) => {
        setState((prevState) => ({ ...prevState, dateFrom, dateUntil, eventType }));

        const { patientId } = state;
        const param = {
            dateFrom: '',
            dateUntil: '',
            patientId,
            territory
        };
        if (dateFrom) {
            param.dateFrom = transformDate(dateFrom);
        }
        if (dateUntil) {
            param.dateUntil = transformDate(dateUntil);
        }
        eventType.value === EVENT_TYPE.BONUS_MOVEMENT ? dispatch(fetchBonusHistory(param)) : dispatch(fetchDiscountHistory(param));
    };

    const handleFetchBonusHistory = () => {
        const { dateFrom, dateUntil, patientId } = state;
        dispatch(
            fetchBonusHistory({
                dateFrom: transformDate(dateFrom),
                dateUntil: transformDate(dateUntil),
                patientId,
                territory
            })
        );
    };

    const renderContent = () => {
        const { eventType, dateFrom, dateUntil } = state;
        const selectTypeChangesProgram = eventType.value === EVENT_TYPE.CHANGES_PROGRAM;
        const selectTypeBonusMovement = eventType.value === EVENT_TYPE.BONUS_MOVEMENT;
        const isDiscountHistoryContent = discountHistory?.length > 0 && selectTypeChangesProgram;
        const isBonusHistoryContent = bonusHistory?.length > 0 && selectTypeBonusMovement;
        const isDiscountHistoryError = loyaltyHistory.fetchDiscountHistory.error && selectTypeChangesProgram;
        const isBonusHistoryError = loyaltyHistory.fetchBonusHistory.error && selectTypeBonusMovement;

        if (loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        } else if (isDiscountHistoryError || isBonusHistoryError) {
            return (
                <Error
                    alertText={formatMessage(messages.fetchError)}
                    buttonClassName={styles.retryBtn}
                    buttonText={formatMessage(commonMessages.retry)}
                    onClick={() => onFilter(dateFrom, dateUntil, eventType)}
                />
            );
        } else {
            if (isBonusHistoryContent) {
                return (
                    <>
                        {bonusHistory?.map((item, index) => (
                            <IKBBonusHistoryRecord key={index} bonus={item} eventType={eventType.value} />
                        ))}
                    </>
                );
            } else if (isDiscountHistoryContent) {
                return (
                    <>
                        {discountHistory?.map((item, index: number) => (
                            <IKBBonusHistoryRecord key={index} bonus={item} eventType={eventType.value} />
                        ))}
                    </>
                );
            } else if (!isBonusHistoryContent || !isBonusHistoryContent) {
                return <NotFoundContent message={formatMessage(messages.noHistory)} />;
            }
        }
    };

    const { eventType, eventTypes, dateFrom, dateUntil } = state;
    const isBonusMovement = eventType.value === EVENT_TYPE.BONUS_MOVEMENT;

    return (
        <Fragment>
            <IKBNavigationBar title={formatMessage(messages.bonusHistory)} back={true} />
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.toolBarWrapper}>
                        <div className={styles.toolBar}>
                            <SelectPicker
                                isClearable={false}
                                isSearchable={false}
                                name='eventType'
                                label={formatMessage(messages.eventType)}
                                value={eventType}
                                options={eventTypes}
                                onChange={(event) =>
                                    formHelper.handleValueChange(
                                        {
                                            target: {
                                                name: 'eventType',
                                                value: event
                                            }
                                        },
                                        // @ts-ignore
                                        (value: EventType) => onFilter(dateFrom, dateUntil, value)
                                    )
                                }
                                className={styles.selectPicker}
                                classNamePrefix={'simple-selectpicker-large'}
                            />
                            <FormField label={formatMessage(messages.periodLabel)} className={styles.dateRangeField}>
                                <div className={styles.dateRangeContainer}>
                                    <InputDate
                                        name='dateFrom'
                                        selected={dateFrom ? fromUnixTime(dateFrom) : null}
                                        onChange={(date) =>
                                            formHelper.handleValueChange(
                                                { target: { name: 'dateFrom', value: date } },
                                                // @ts-ignore
                                                (value) => onFilter(value, dateUntil, eventType)
                                            )
                                        }
                                        locale={locale}
                                        isClearable
                                    />
                                    <div className={styles.datesSeparator} />
                                    <InputDate
                                        name='dateUntil'
                                        selected={dateUntil ? fromUnixTime(dateUntil) : null}
                                        onChange={(date) =>
                                            formHelper.handleValueChange(
                                                { target: { name: 'dateUntil', value: date } },
                                                // @ts-ignore
                                                (value) => onFilter(dateFrom, value, eventType)
                                            )
                                        }
                                        locale={locale}
                                        isClearable
                                    />
                                </div>
                            </FormField>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.header}>
                            <div className={styles.date}>{formatMessage(messages.date)}</div>
                            <div className={classNames(styles.event, { [styles.discountEvent]: !isBonusMovement })}>{formatMessage(messages.event)}</div>
                            {isBonusMovement && (
                                <>
                                    <div className={styles.accured}>{formatMessage(messages.accrued)}</div>
                                    <div className={styles.writeOff}>{formatMessage(messages.writeOff)}</div>
                                    <div className={styles.paid}>{formatMessage(messages.paid)}</div>
                                </>
                            )}
                        </div>
                        {renderContent()}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default memo(IKBBonusHistoryPage);
