import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';

import styles from './DescriptionBlock.module.css';
import messages from './DescriptionBlockMessages';

interface IProps {
    cartOrderType: any;
    calculationData: any;
}

const DescriptionBlock: FC<IProps> = ({ cartOrderType, calculationData }): JSX.Element => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.descriptionBlock}>
            {calculationData?.checkups ? (
                <div>{formatMessage(messages.checkupDescription, { br: <br /> })}</div>
            ) : (
                !cartOrderType.reorder && (
                    <>
                        <div className={styles.descriptionTitle}>{formatMessage(messages.title)}</div>
                        <ol className={styles.descriptionBlockList}>
                            <li>{formatMessage(messages.firstListItem)}</li>
                            <li>{formatMessage(messages.secondListItem)}</li>
                        </ol>
                        <div>{formatMessage(messages.text)}</div>
                    </>
                )
            )}
        </div>
    );
};

export default memo(DescriptionBlock);
