import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseAnother: {
        defaultMessage: 'Choose another',
        id: 'Cities.chooseAnother'
    },
    confirmLocation: {
        defaultMessage: 'Yeah right',
        id: 'Cities.confirmLocation'
    },
    hintLocation: {
        defaultMessage: 'You are in the city',
        id: 'Cities.hintLocation'
    },
    location: {
        defaultMessage: 'Your city:',
        id: 'Cities.location'
    },
    notice: {
        defaultMessage: 'Prices and payment methods depend on the selected city.',
        id: 'Cities.notice'
    }
});
