import classNames from 'classnames/bind';
import Button from 'components/button/Button';
import React, { FC, memo } from 'react';

import styles from './ButtonLink.module.css';
const cx = classNames.bind(styles);

type TProps = {
    text?: string;
    children?: any;
    show?: boolean;
    className?: string;
    disabled?: boolean;
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
};

const ButtonLink: FC<TProps> = ({ show = true, text, className, onClick, children, ...rest }) => {
    return show ? (
        <Button text={text} onClick={onClick} className={cx(styles.button, className)} {...rest}>
            {children}
        </Button>
    ) : null;
};

export default memo(ButtonLink);
