//@ts-nocheck
import Cookies from 'js-cookie';
import { getHostname } from 'utils/browserUtils';

const IP_ADDRESS_REGEXP =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

class CookieService {
    constructor() {
        this.attributes = { domain: getCookieDomain() };
    }

    get(key) {
        return Cookies.get(key);
    }

    set(key, value, expires) {
        Cookies.set(key, value, { ...this.attributes, expires });
    }

    remove(key) {
        Cookies.remove(key, this.attributes);
    }
}

function getCookieDomain() {
    const hostname = getHostname();
    if ('localhost' === hostname || IP_ADDRESS_REGEXP.test(hostname)) {
        return hostname;
    }

    const parts = hostname.split('.');
    if (parts.length < 3) {
        return `.${hostname}`;
    } else {
        return `.${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }
}

export default new CookieService();
