export const EMAIL = 'EMAIL';
export const EMAIL_2 = 'EMAIL_2';
export const EMAIL_3 = 'EMAIL_3';
export const MOBILE_PHONE = 'MOBILE_PHONE';
export const WORK_PHONE = 'WORK_PHONE';
export const ADDITIONAL_PHONE = 'ADDITIONAL_PHONE';

export const MAKE_MAIN_TYPE = 'MAKE_MAIN';
export const ALLOW_HISTORY_TYPE = 'ALLOW_HISTORY';
export const ALLOW_LOYALTY_TYPE = 'ALLOW_LOYALTY';
export const ALLOW_DOCUMENTS_TYPE = 'ALLOW_DOCUMENTS';

export const SENDING_RESULTS = 'SENDING_RESULTS';
export const DISABLED_SENDING_RESULTS = 'DISABLED_SENDING_RESULTS';
