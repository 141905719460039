import React, { memo } from 'react';
import notFoundIcon from 'shared/assets/notFoundIcon.svg';

import styles from './NotFoundContent.module.css';

interface IProps {
    message: string;
}

const NotFoundContent = ({ message }: IProps): JSX.Element => {
    return (
        <div className={styles.container}>
            <img src={notFoundIcon} alt={message} />
            <div className={styles.message}>{message}</div>
        </div>
    );
};

export default memo(NotFoundContent);
