//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import { OrderType } from 'constants/orderType';
import queryString from 'query-string';

const ORDERS_URL = `${BASE_URL}/patients`;
const PREORDERS_URL = `${BASE_URL}/preorders`;

export const create = (data) => {
    const { patientId, ...request } = data;
    return apiClient.post(`${ORDERS_URL}/${patientId}/orders`, request);
};

export const fetchPaymentPreorderAvailable = (preorderId, territory, config) => {
    return apiClient.get(`${PREORDERS_URL}/${preorderId}/payment/available?territory=${territory}`, config);
};

export const preorderPay = (data) => {
    const { preorderId } = data;
    return apiClient.post(`${PREORDERS_URL}/${preorderId}/pay`, data);
};

export const fetchAcceptedInfo = ({ patientId, orderId, orderType }) => {
    return apiClient.get(`${BASE_URL}/patients/${patientId}/orders/${orderId}/info?orderType=${orderType}`);
};

export const cancel = (data) => {
    const { patientId, orderId } = data;
    return apiClient.post(`${ORDERS_URL}/${patientId}/orders/${orderId}/cancel`);
};

export const fetchOrdersCount = (patientId) => {
    return apiClient.get(`${ORDERS_URL}/${patientId}/orders/count`);
};

export const searchOrders = (patientId) => {
    return apiClient.get(`${ORDERS_URL}/${patientId}/orders`);
};

export const fetchOrderDetails = (data) => {
    const { patientId, orderId, ...request } = data;
    return apiClient.get(`${ORDERS_URL}/${patientId}/orders/${orderId}?${queryString.stringify(request)}`);
};

export const downloadFile = (data) => {
    const { patientId, orderId, ...request } = data;
    return apiClient.get(`${ORDERS_URL}/${patientId}/orders/${orderId}/download?${queryString.stringify(request)}`, { responseType: 'blob' });
};

export const getPaymentStatus = (data) => {
    const { patientId, orderId, ...params } = data;
    return apiClient.get(`${BASE_URL}/orders/${orderId}/payments/${patientId}/payment-status?${queryString.stringify(params)}`);
};
