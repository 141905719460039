export const BAD_REQUEST = 400;
export const WRONG_CREDENTIALS = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const WRONG_CODE = 409;
export const DEACTIVATED = 410;
export const UNCONFIRMED = 412;
export const EXPECTATION_FAILED = 417;
export const TOO_MANY_REQUESTS = 429;
export const INTERNAL_SERVER_ERROR = 500;
