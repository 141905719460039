//@ts-nocheck
import HeaderBottom from 'page/layout/header/bottom/HeaderBottom';
import HeaderMiddle from 'page/layout/header/middle/HeaderMiddle';
import HeaderTop from 'page/layout/header/top/HeaderTop';
import React from 'react';
import { connect } from 'react-redux';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

import styles from './Header.module.css';

const Header = ({ territorySettings, authenticated }) => {
    return (
        <header className={styles.header + ' ' + styles.show}>
            <HeaderTop />
            {authenticated ? (
                <>
                    <HeaderMiddle territorySettings={territorySettings} />
                    <HeaderBottom />
                </>
            ) : (
                <HeaderMiddle territorySettings={territorySettings} />
            )}
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        territorySettings: currentTerritorySettingsSelector(state)
    };
};

export default connect(mapStateToProps, null)(Header);
