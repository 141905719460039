import { defineMessages } from 'react-intl';

export default defineMessages({
    loginButton: {
        defaultMessage: 'Login',
        id: 'AuthBlock.loginButton'
    },
    registrationButton: {
        defaultMessage: 'Registration',
        id: 'AuthBlock.registrationButton'
    },
    title: {
        defaultMessage: 'Authorization',
        id: 'AuthBlock.title'
    },
    vndTitle: {
        defaultMessage: 'Login or register to place an order',
        id: 'AuthBlock.vndTitle'
    }
});
