import { defineMessages } from 'react-intl';

export default defineMessages({
    code: {
        defaultMessage: 'Code',
        id: 'IKBCodeEntryPage.code'
    },
    error: {
        defaultMessage: 'Wrong code entered',
        id: 'IKBCodeEntryPage.error'
    },
    info: {
        defaultMessage: 'Request to view and manage loyalty options has been sent. Enter the confirmation code from the message.',
        id: 'IKBCodeEntryPage.info'
    },
    navigationTitle: {
        defaultMessage: 'Code entry',
        id: 'IKBCodeEntryPage.navigationTitle'
    },
    noticeSendCode: {
        defaultMessage: 'You can send the code again via <span></span> s',
        id: 'IKBCodeEntryPage.noticeSendCode'
    },
    sendCode: {
        defaultMessage: 'Resend code',
        id: 'IKBCodeEntryPage.sendCode'
    }
});
