import { useAppDispatch } from 'app/Hooks';
import Button from 'components/button/Button';
import ButtonLink from 'components/button/link/ButtonLink';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { clearActionCart, mergeCart, replaceCart } from 'redux/cart/actions';
import { ErrorResponse } from 'types/common';

import cartErrorIcon from './cartErrorIcon.svg';
import cartIcon from './cartIcon.svg';
import styles from './ChangeProductsCartModal.module.css';
import messages from './ChangeProductsCartModalMessages';

type TArticles = { article: string };

type TProps = {
    show: boolean;
    repeatPrevProducts: TArticles[];
    repeatOrderProducts: TArticles[];
    loading?: boolean;
    error?: ErrorResponse;
};

const ChangeProductsCartModal: FC<TProps> = ({ show, repeatOrderProducts, loading, error }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [, setShowModal] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(clearActionCart());
        };
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/cart');
    };

    const renderError = (code: number) => {
        const typeError = code >= 500 ? 'server' : 'code';
        return (
            <Fragment>
                <div className={styles.top}>
                    <h3 className={styles.title}>
                        {typeError === 'server' && formatMessage(messages.serverError)}
                        {typeError === 'code' && formatMessage(messages.codeError)}
                    </h3>
                    <img alt='' src={cartErrorIcon} className={styles.cartErrorIcon} />
                </div>
                <div className={styles.buttons}>
                    {typeError === 'server' && (
                        <Fragment>
                            <ButtonLink onClick={() => handleCloseModal()}>{formatMessage(messages.cancel)}</ButtonLink>
                            <Button onClick={() => window.location.reload()}>{formatMessage(messages.retry)}</Button>
                        </Fragment>
                    )}
                    {typeError === 'code' && (
                        <Fragment>
                            <Button onClick={() => handleCloseModal()}>{formatMessage(messages.close)}</Button>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Modal
                closable={true}
                onClose={() => {
                    handleCloseModal();
                }}
                overlayClassName={styles.overlayClassName}
                show={show}
            >
                <div className={styles.container}>
                    {error && renderError(error.status)}
                    {!error && (
                        <Fragment>
                            <div className={styles.top}>
                                <h3 className={styles.title}>{formatMessage(messages.title)}</h3>
                                <img alt='' src={cartIcon} className={styles.cartIcon} />
                            </div>
                            <div className={styles.buttons}>
                                <ButtonLink onClick={() => handleCloseModal()}>{formatMessage(messages.cancel)}</ButtonLink>
                                <ButtonLink
                                    onClick={() => {
                                        dispatch(mergeCart({ repeatOrderProducts }));
                                    }}
                                >
                                    {formatMessage(messages.merge)}
                                </ButtonLink>
                                <Button
                                    onClick={() => {
                                        dispatch(replaceCart({ repeatOrderProducts }));
                                    }}
                                >
                                    {formatMessage(messages.replace)}
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </div>
            </Modal>
            {loading && <LoaderModal show={loading} />}
        </Fragment>
    );
};

export default memo(ChangeProductsCartModal);
