import { useAppDispatch } from 'app/Hooks';
import ButtonLink from 'components/button/link/ButtonLink';
import messages from 'page/layout/header/top/LogoutButtonMessages';
import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { logout } from 'redux/auth/actions';

interface IProps {
    disabled: boolean;
}

const LogoutButton: FC<IProps> = ({ disabled }) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    return <ButtonLink text={formatMessage(messages.text)} onClick={() => dispatch(logout())} disabled={disabled} />;
};

export default memo(LogoutButton);
