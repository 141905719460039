import { defineMessages } from 'react-intl';

export default defineMessages({
    CardNumber_Empty: {
        defaultMessage: 'Enter card number',
        id: 'CardForm.CardNumber_Empty'
    },
    CardNumber_Invalid: {
        defaultMessage: 'Invalid card number entered',
        id: 'CardForm.CardNumber_Invalid'
    },
    CardNumber_Length: {
        defaultMessage: 'Incomplete card number entered',
        id: 'CardForm.CardNumber_Length'
    },
    Cvv_Empty: {
        defaultMessage: 'Enter CVC/CVV code',
        id: 'CardForm.Cvv_Empty'
    },
    cardNumber: {
        defaultMessage: 'Card number',
        id: 'CardForm.cardNumber'
    },
    Cvv_Invalid: {
        defaultMessage: 'CVC/CVV code is incomplete',
        id: 'CardForm.Cvv_Invalid'
    },
    cvv: {
        defaultMessage: 'CVC/CVV',
        id: 'CardForm.cvv'
    },
    ExpDateMonthYear_Empty: {
        defaultMessage: 'Enter expiration date',
        id: 'CardForm.ExpDateMonthYear_Empty'
    },
    description: {
        defaultMessage:
            'Payment is secure. We do not store card data; all transactions are handled by CloudPayments, a payment system certified to the international PCI DSS security standard.',
        id: 'CardForm.description'
    },
    ExpDateMonthYear_Invalid: {
        defaultMessage: 'Enter the correct expiration date',
        id: 'CardForm.ExpDateMonthYear_Invalid'
    },
    title: {
        id: 'CardForm.title',
        defaultMessage: 'Card details'
    },
    ExpDateMonth_Invalid: {
        defaultMessage: 'Enter correct month in the date',
        id: 'CardForm.ExpDateMonth_Invalid'
    },
    Unknown_error: {
        defaultMessage: 'Unknown error',
        id: 'CardForm.Unknown_error'
    },
    expDate: {
        id: 'CardForm.expDate',
        defaultMessage: 'mm/year'
    },
    confirmButton: {
        defaultMessage: 'Confirm order',
        id: 'ConfirmationBlock.confirmButton'
    },
    refundMessage: {
        id: 'CardForm.refundMessage',
        defaultMessage: 'To make a refund, please call {number}'
    },
    mastercard: {
        defaultMessage: 'MasterCard',
        id: 'ConfirmationBlock.mastercard'
    },
    mir: {
        defaultMessage: 'MIR',
        id: 'ConfirmationBlock.mir'
    },
    notice: {
        defaultMessage: 'Authorization required',
        id: 'ConfirmationBlock.notice'
    },
    payButton: {
        defaultMessage: 'Pay',
        id: 'ConfirmationBlock.payButton'
    },
    pci: {
        defaultMessage: 'pci',
        id: 'ConfirmationBlock.pci'
    },
    visa: {
        defaultMessage: 'Visa',
        id: 'ConfirmationBlock.visa'
    },
    yandexBtn: {
        defaultMessage: 'Pay with',
        id: 'ConfirmationBlock.yandexBtn'
    }
});
