import whatsAppIcon from 'page/layout/assets/socialMediaImages/whatsAppIcon.svg';
import React from 'react';

import styles from './WhatsApp.module.css';

interface IWhatsApp {
    data: {
        name: string;
        uri: string;
    };
}

const WhatsApp = ({ data }: IWhatsApp) => {
    return (
        <div key={data.uri} className={styles.container}>
            <span>
                <a className={styles.link} target='_blank' rel='noopener noreferrer' href={data.uri}>
                    <img className={styles.image} src={whatsAppIcon} alt={'whatsapp'} />
                    {data.name}
                </a>
            </span>
        </div>
    );
};

export default WhatsApp;
