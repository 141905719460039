import { defineMessages } from 'react-intl';

export default defineMessages({
    checkupInformation: {
        defaultMessage: 'Bonus points, certificates, coupons and other discounts cannot be used when purchasing health programs.',
        id: 'OrderBlock.checkupInformation'
    },
    orderListTitle: {
        defaultMessage: 'Order list',
        id: 'OrderBlock.orderListTitle'
    },
    refresh: {
        defaultMessage: 'Refresh',
        id: 'OrderBlock.refresh'
    },
    reorderListTitle: {
        defaultMessage: 'Reorder list',
        id: 'OrderBlock.reorderListTitle'
    },
    totalTitle: {
        defaultMessage: 'Total:',
        id: 'OrderBlock.totalTitle'
    }
});
