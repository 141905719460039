import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Button from 'components/button/Button';
import Modal from 'components/modal/Modal';
import commonMessages from 'messages/CommonMessages';
import IKBNoticeCard from 'page/loyalty/ikb/components/cards/notice/IKBNoticeCard';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import messages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
import RegularPageConfirmCard from 'page/loyalty/ikb/regular/RegularPageConfirmCard/RegularPageConfirmCard';
import cashbackNoticeIcon from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIcon.svg';
import React, { FC, Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { changeCategory } from 'redux/loyaltyIKB/actions';
import { cashbackStatusSelector, loyaltyIKBSelector, multiplicatorsCategorySelector } from 'redux/loyaltyIKB/selectors';
import { selectedPatientSelector } from 'redux/patient/selectors';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { getLoyaltyErrorText } from 'utils/messageUtils';

import IKBServicesConfirmationMessages from '../servicesConfirmation/IKBServicesConfirmationMessages';
import styles from './style.module.css';

const IKBRegularPageConfirm: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const selectedPatient = useAppSelector(selectedPatientSelector);
    const bonusStatus = useAppSelector(cashbackStatusSelector);
    const loyaltyIKB = useAppSelector(loyaltyIKBSelector);
    const territory = useAppSelector(currentTerritorySelector);
    const { loading, success, error } = useAppSelector(() => loyaltyIKB.changeCategory);
    const multiplicators = useAppSelector(multiplicatorsCategorySelector);
    const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
    const regularCategory = ikbLoyaltyService.getRegularMultiplicator(multiplicators);

    useEffect(() => {
        if (!regularCategory) {
            navigate(-1);
        }
    }, []);

    useEffect(() => {
        if (success) {
            navigate('/loyalty');
        }
    }, [success]);

    const handleConfirmButton = (categoryId: number) => {
        dispatch(
            changeCategory({
                categoryId,
                number: bonusStatus.number,
                patientId: selectedPatient?.id || (searchParams.get('patientId') as string),
                territory
            })
        );
    };

    return (
        <Fragment>
            <IKBNavigationBar title={formatMessage(messages.title)} back={true} />
            <div className={styles.confirmPageWrapper}>
                <div className={styles.container}>
                    {error ? (
                        <IKBNoticeCard
                            title={formatMessage(IKBServicesConfirmationMessages.requestError)}
                            description={getLoyaltyErrorText(intl, error.errorCode, error.message)}
                            icon={cashbackNoticeIcon}
                        />
                    ) : (
                        <Fragment>
                            <Modal show={loading} />
                            <section>
                                <div className={styles.cardContainer}>
                                    <div className={styles.cardContent}>
                                        {regularCategory?.percents?.map((category, i) => (
                                            <RegularPageConfirmCard key={i} message={category.message} percent={category.percent} />
                                        ))}
                                    </div>
                                    <div className={styles.cardDivider}></div>
                                    <div className={styles.cardConfirmInfo}>
                                        <p>{formatMessage(messages.nextChangesAvailable, { mark: (v: any) => <mark>{v}</mark> })}</p>
                                    </div>
                                    <div className={styles.cardFooter}>
                                        <Button disabled={loading} onClick={() => handleConfirmButton(regularCategory?.multiplicator.categoryId)}>
                                            {formatMessage(commonMessages.buttonConfirmSelection)}
                                        </Button>
                                    </div>
                                </div>
                            </section>
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default IKBRegularPageConfirm;
