//@ts-nocheck
import { isAfter } from 'date-fns';
import activatedCashbackIcon from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIcon.svg';
import activatedCashbackIconBr from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconBr.svg';
import activatedCashbackIconDram from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconDram.svg';
import activatedCashbackIconSom from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconSom.svg';
import activatedCashbackIconT from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconT.svg';
import cashbackIcon from 'page/loyalty/ikb/components/assets/cashbackIcon.svg';
import cashbackIconBr from 'page/loyalty/ikb/components/assets/cashbackIconBr.svg';
import cashbackIconDram from 'page/loyalty/ikb/components/assets/cashbackIconDram.svg';
import cashbackIconSom from 'page/loyalty/ikb/components/assets/cashbackIconSom.svg';
import cashbackIconT from 'page/loyalty/ikb/components/assets/cashbackIconT.svg';
import cashbackBlue from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackblue.svg';
import cashbackOrange from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackorange.svg';
import cashbackRed from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackred.svg';
import regularMessages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
import cashbackNoticeIcon from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIcon.svg';
import cashbackNoticeIconBr from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconBr.svg';
import cashbackNoticeIconDram from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconDram.svg';
import cashbackNoticeIconSom from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconSom.svg';
import cashbackNoticeIconT from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconT.svg';
import { defineMessages } from 'react-intl';
import { SUPPORTED_TERRITORIES } from 'redux/cities/constants';

const messages = defineMessages({
    armeniaBonusGiftInfo: {
        defaultMessage: 'bonus drams as a gift',
        id: 'IKBLoyaltyService.armeniaBonusGiftInfo'
    },
    armeniaCardTitle: {
        defaultMessage: '{balance} b.d.',
        id: 'IKBLoyaltyService.armeniaCardTitle'
    },
    armeniaDescription3: {
        defaultMessage: '1 bonus dram equals 1 dram',
        id: 'IKBLoyaltyService.armeniaDescription3'
    },
    armeniaDescription4: {
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection',
        id: 'IKBLoyaltyService.armeniaDescription4'
    },
    armeniaMotivationTextTooltip: {
        defaultMessage: 'We give you {bonusValue} bonus drams',
        id: 'IKBLoyaltyService.armeniaMotivationTextTooltip'
    },
    belarusDescription4: {
        defaultMessage: 'Bonus roubles can be used for laboratory diagnostics services',
        id: 'IKBLoyaltyService.belarusDescription4'
    },
    bonusGiftInfo: {
        defaultMessage: 'bonus rubles as a gift',
        id: 'IKBLoyaltyService.bonusGiftInfo'
    },
    cardTitle: {
        defaultMessage: '{balance} b. r.',
        id: 'IKBLoyaltyService.cardTitle'
    },
    crimeaDescription4: {
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection',
        id: 'IKBLoyaltyService.crimeaDescription4'
    },
    description3: {
        defaultMessage: '1 bonus ruble is equal to 1 ruble',
        id: 'IKBLoyaltyService.description3'
    },
    description4: {
        defaultMessage: 'Write off bonuses for any procedures',
        id: 'IKBLoyaltyService.description4'
    },
    featureText: {
        defaultMessage: 'Register for the loyalty program "Healthy Cashback" and get {bonusValue} bonus points',
        id: 'IKBLoyaltyService.featureText'
    },
    kazakhstanBonusGiftInfo: {
        defaultMessage: 'bonus tenge as a gift',
        id: 'IKBLoyaltyService.kazakhstanBonusGiftInfo'
    },
    kazakhstanCardTitle: {
        defaultMessage: '{balance} b. t.',
        id: 'IKBLoyaltyService.kazakhstanCardTitle'
    },
    kazakhstanDescription3: {
        defaultMessage: '1 bonus tenge is equal to 1 tenge',
        id: 'IKBLoyaltyService.kazakhstanDescription3'
    },
    kazakhstanDescription4: {
        defaultMessage: 'Bonus tenge can be used for laboratory diagnostics services',
        id: 'IKBLoyaltyService.kazakhstanDescription4'
    },
    kazakhstanMotivationTextTooltip: {
        defaultMessage: 'We give you {bonusValue} bonus tenge',
        id: 'IKBLoyaltyService.kazakhstanMotivationTextTooltip'
    },
    kyrgyzstanBonusGiftInfo: {
        defaultMessage: 'bonus soms as a gift',
        id: 'IKBLoyaltyService.kyrgyzstanBonusGiftInfo'
    },
    kyrgyzstanCardTitle: {
        defaultMessage: '{balance} b.s.',
        id: 'IKBLoyaltyService.kyrgyzstanCardTitle'
    },
    kyrgyzstanDescription3: {
        defaultMessage: '1 bonus som equals 1 som',
        id: 'IKBLoyaltyService.kyrgyzstanDescription3'
    },
    kyrgyzstanDescription4: {
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection',
        id: 'IKBLoyaltyService.kyrgyzstanDescription4'
    },
    kyrgyzstanMotivationTextTooltip: {
        defaultMessage: 'We give you {bonusValue} bonus soms',
        id: 'IKBLoyaltyService.kyrgyzstanMotivationTextTooltip'
    },
    motivationTextCard: {
        defaultMessage: 'After activating the account, you will receive {bonusValue}',
        id: 'IKBLoyaltyService.cashbackMotivationTextCard'
    },
    motivationTextTooltip: {
        defaultMessage: 'We give you {bonusValue} bonus rubles',
        id: 'IKBLoyaltyService.cashbackMotivationTextTooltip'
    }
});

const Program = {
    ARMENIA: {
        activatedCashbackIcon: activatedCashbackIconDram,
        baseDiscountPercent: 3,
        bonusGiftInfo: messages.armeniaBonusGiftInfo,
        cardTitle: messages.armeniaCardTitle,
        cashbackIcon: cashbackIconDram,
        cashbackNoticeIcon: cashbackNoticeIconDram,
        currencySign: '֏',
        description3: messages.armeniaDescription3,
        description4: messages.armeniaDescription4,
        featureText: messages.featureText,
        giftBonusSize: 1000,
        maxWriteOffPercent: 50,
        motivationTextCard: messages.motivationTextCard,
        motivationTextTooltip: messages.armeniaMotivationTextTooltip,
        noticeLink: 'invitromed.am'
    },

    BELARUS: {
        activatedCashbackIcon: activatedCashbackIconBr,
        baseDiscountPercent: 3,
        bonusGiftInfo: messages.bonusGiftInfo,
        cardTitle: messages.cardTitle,
        cashbackIcon: cashbackIconBr,
        cashbackNoticeIcon: cashbackNoticeIconBr,
        currencySign: 'Br',
        description3: messages.description3,
        description4: messages.belarusDescription4,
        featureText: messages.featureText,
        giftBonusSize: 5,
        maxWriteOffPercent: 30,
        motivationTextCard: messages.motivationTextCard,
        motivationTextTooltip: messages.motivationTextTooltip,
        noticeLink: 'invitro.by'
    },

    KAZAKHSTAN: {
        activatedCashbackIcon: activatedCashbackIconT,
        baseDiscountPercent: 3,
        bonusGiftInfo: messages.kazakhstanBonusGiftInfo,
        cardTitle: messages.kazakhstanCardTitle,
        cashbackIcon: cashbackIconT,
        cashbackNoticeIcon: cashbackNoticeIconT,
        currencySign: '₸',
        description3: messages.kazakhstanDescription3,
        description4: messages.kazakhstanDescription4,
        featureText: messages.featureText,
        giftBonusSize: 500,
        maxWriteOffPercent: 90,
        motivationTextCard: messages.motivationTextCard,
        motivationTextTooltip: messages.kazakhstanMotivationTextTooltip,
        noticeLink: 'invitro.kz'
    },

    KYRGYZSTAN: {
        activatedCashbackIcon: activatedCashbackIconSom,
        baseDiscountPercent: 3,
        bonusGiftInfo: messages.kyrgyzstanBonusGiftInfo,
        cardTitle: messages.kyrgyzstanCardTitle,
        cashbackIcon: cashbackIconSom,
        cashbackNoticeIcon: cashbackNoticeIconSom,
        currencySign: 'с',
        description3: messages.kyrgyzstanDescription3,
        description4: messages.kyrgyzstanDescription4,
        featureText: messages.featureText,
        giftBonusSize: 250,
        maxWriteOffPercent: 50,
        motivationTextCard: messages.motivationTextCard,
        motivationTextTooltip: messages.kyrgyzstanMotivationTextTooltip,
        noticeLink: 'invitro.kg'
    },

    RUSSIA: {
        activatedCashbackIcon: activatedCashbackIcon,
        baseDiscountPercent: 3,
        bonusGiftInfo: messages.bonusGiftInfo,
        cardTitle: messages.cardTitle,
        cashbackIcon: cashbackIcon,
        cashbackNoticeIcon: cashbackNoticeIcon,
        currencySign: '₽',
        description3: messages.description3,
        description4: messages.description4,
        featureText: messages.featureText,
        giftBonusSize: 200,
        maxWriteOffPercent: 50,
        motivationTextCard: messages.motivationTextCard,
        motivationTextTooltip: messages.motivationTextTooltip,
        noticeLink: 'invitro.ru'
    }
};

class IKBLoyaltyService {
    constructor(territory, intl) {
        this.territory = territory;
        this.intl = intl;
    }

    getBaseDiscountPercent() {
        const program = getProgram(this.territory);
        return program.baseDiscountPercent;
    }

    getMaxWriteOffPercent() {
        const program = getProgram(this.territory);
        return program.maxWriteOffPercent;
    }

    getGiftBonusSize() {
        const program = getProgram(this.territory);
        return program.giftBonusSize;
    }

    getNoticeLink() {
        const program = getProgram(this.territory);
        return program.noticeLink;
    }

    getDescription3Message() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.description3);
    }

    getDescription4Message() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.description4);
    }

    getBonusGiftInfoMessage() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.bonusGiftInfo);
    }

    getCurrencySign() {
        const program = getProgram(this.territory);
        return program.currencySign;
    }

    getCardTitleMessage(balance) {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.cardTitle, balance);
    }

    getCashbackIcon() {
        const program = getProgram(this.territory);
        return program.cashbackIcon;
    }

    getCashbackNoticeIcon() {
        const program = getProgram(this.territory);
        return program.cashbackNoticeIcon;
    }

    getActivatedCashbackIcon() {
        const program = getProgram(this.territory);
        return program.activatedCashbackIcon;
    }

    getMotivationTextTooltip(bonusValue) {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.motivationTextTooltip, bonusValue);
    }

    getMotivationTextCard(bonusValue) {
        const program = getProgram(this.territory);
        return `${this.intl.formatMessage(program.motivationTextCard, bonusValue)} ${this.intl.formatMessage(program.bonusGiftInfo)}`;
    }

    getFeatureText(bonusValue) {
        const program = getProgram(this.territory);
        return `${this.intl.formatMessage(program.featureText, bonusValue)}`;
    }

    getRegularMultiplicator(multiplicators) {
        const multiplicator = multiplicators?.find(
            (multiplicator) => isAfter(new Date(multiplicator.validDate), new Date(Date.now())) || !multiplicator.validDate
        );

        if (!multiplicator) return null;

        return {
            multiplicator,
            percents: [
                {
                    icon: cashbackRed,
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 3 }),
                    percent: multiplicator?.thirdOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 3 })
                },
                {
                    icon: cashbackOrange,
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 2 }),
                    percent: multiplicator?.secondOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 2 })
                },
                {
                    icon: cashbackBlue,
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 1 }),
                    percent: multiplicator?.firstOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 1 })
                }
            ]
        };
    }
}

export default IKBLoyaltyService;

function getProgram(territory) {
    switch (territory) {
        case SUPPORTED_TERRITORIES.RUSSIA:
            return Program.RUSSIA;
        case SUPPORTED_TERRITORIES.KAZAKHSTAN:
            return Program.KAZAKHSTAN;
        case SUPPORTED_TERRITORIES.BELARUS:
            return Program.BELARUS;
        case SUPPORTED_TERRITORIES.KYRGYZSTAN:
            return Program.KYRGYZSTAN;
        case SUPPORTED_TERRITORIES.ARMENIA:
            return Program.ARMENIA;
        default:
            return {};
    }
}
