import { defineMessages } from 'react-intl';

export default defineMessages({
    address: {
        defaultMessage: 'Registration address',
        id: 'OnlineRegistrationBlock.address'
    },
    addressApartmentLabel: {
        defaultMessage: 'Apartment',
        id: 'OnlineRegistrationBlock.addressApartmentLabel'
    },
    addressApartmentPlaceholder: {
        defaultMessage: '3',
        id: 'OnlineRegistrationBlock.addressApartmentPlaceholder'
    },
    addressBlockLabel: {
        defaultMessage: 'Block (optional)',
        id: 'OnlineRegistrationBlock.addressBlockLabel'
    },
    addressCityLabel: {
        defaultMessage: 'City',
        id: 'OnlineRegistrationBlock.addressCityLabel'
    },
    addressCityPlaceholder: {
        defaultMessage: 'New-York',
        id: 'OnlineRegistrationBlock.addressCityPlaceholder'
    },
    addressCountryLabel: {
        defaultMessage: 'Country',
        id: 'OnlineRegistrationBlock.addressCountryLabel'
    },
    addressHomeNumberLabel: {
        defaultMessage: 'Home number',
        id: 'OnlineRegistrationBlock.addressHomeNumberLabel'
    },
    addressHomeNumberPlaceholder: {
        defaultMessage: '21',
        id: 'OnlineRegistrationBlock.addressHomeNumberPlaceholder'
    },
    addressRegionLabel: {
        defaultMessage: 'Region',
        id: 'OnlineRegistrationBlock.addressRegionLabel'
    },
    addressStreetLabel: {
        defaultMessage: 'Street',
        id: 'OnlineRegistrationBlock.addressStreetLabel'
    },
    addressStreetPlaceholder: {
        defaultMessage: 'Lincoln Blvd.',
        id: 'OnlineRegistrationBlock.addressStreetPlaceholder'
    },
    description: {
        defaultMessage: 'Data to form a contract',
        id: 'OnlineRegistrationBlock.description'
    },
    hintAddressRegistration: {
        defaultMessage: 'Fill out the address registration form. This data will be saved for future orders.',
        id: 'OnlineRegistrationBlock.hintAddressRegistration'
    },
    hintOnlineRegistration: {
        defaultMessage: 'Fill out the online registration form. This data will be saved for future orders.',
        id: 'OnlineRegistrationBlock.hintOnlineRegistration'
    },
    passport: {
        defaultMessage: 'Passport',
        id: 'OnlineRegistrationBlock.passport'
    },
    passportIssueDateLabel: {
        defaultMessage: 'Issue date',
        id: 'OnlineRegistrationBlock.passportIssueDateLabel'
    },
    passportIssuerLabel: {
        defaultMessage: 'Issuer',
        id: 'OnlineRegistrationBlock.passportIssuerLabel'
    },
    passportIssuerPlaceholder: {
        defaultMessage: 'NYPD',
        id: 'OnlineRegistrationBlock.passportIssuerPlaceholder'
    },
    passportNumberLabel: {
        defaultMessage: 'Passport number',
        id: 'OnlineRegistrationBlock.passportNumberLabel'
    },
    passportNumberPlaceholder: {
        defaultMessage: '000000',
        id: 'OnlineRegistrationBlock.passportNumberPlaceholder'
    },
    passportSeriesLabel: {
        defaultMessage: 'Passport series',
        id: 'OnlineRegistrationBlock.passportSeriesLabel'
    },
    passportSeriesPlaceholder: {
        defaultMessage: '0000',
        id: 'OnlineRegistrationBlock.passportSeriesPlaceholder'
    },
    passportUnitCodeLabel: {
        defaultMessage: 'Unit code',
        id: 'OnlineRegistrationBlock.passportUnitCodeLabel'
    },
    passportUnitCodePlaceholder: {
        defaultMessage: '000-000',
        id: 'OnlineRegistrationBlock.passportUnitCodePlaceholder'
    },
    title: {
        defaultMessage: 'Online registration',
        id: 'OnlineRegistrationBlock.title'
    }
});
