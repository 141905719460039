import { createRequestTypes } from 'redux/helpers';

export const FETCH_ALL = createRequestTypes('DYNAMICS/FETCH_ALL');
export const SELECT = createRequestTypes('DYNAMICS/SELECT');
export const FILTER = createRequestTypes('DYNAMICS/FILTER');
export const UPDATE_COMMENT = createRequestTypes('DYNAMICS/UPDATE_COMMENT');
export const SEND = createRequestTypes('DYNAMICS/SEND');
export const CLEAR_SEND = createRequestTypes('DYNAMICS/CLEAR_SEND');

export const send = (payload: { patientId: string; codes: string[]; from: string; to: string }) => SEND.base(payload);
export const clearSendEmail = () => CLEAR_SEND.base();

export const fetch = (payload: { patientId: string }) => FETCH_ALL.base(payload);
export const select = (payload: { selected: { label: string; value: string }[] | [] }) => SELECT.base(payload);
export const updateFilter = (payload: { startTime: number; endTime: number }) => FILTER.base(payload);
export const updateComment = (payload: { patientId: string; inz: string; code: string; comment: string }) => UPDATE_COMMENT.base(payload);
