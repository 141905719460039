import { defineMessages } from 'react-intl';

export default defineMessages({
    backToCart: {
        defaultMessage: 'Back to cart',
        id: 'CartConfirmationPage.backToCart'
    },
    backToOrders: {
        defaultMessage: 'Back',
        id: 'CartConfirmationPage.backToOrders'
    },
    errorCalculate: {
        defaultMessage: 'Unable to calculate preorder',
        id: 'CartConfirmationPage.errorCalculate'
    },
    errorCreateOrder: {
        defaultMessage: 'Unable to create order',
        id: 'CartConfirmationPage.errorCreateOrder'
    },
    errorCreatePreorder: {
        defaultMessage: 'Unable to create preorder',
        id: 'CartConfirmationPage.errorCreatePreorder'
    },
    pageTitle: {
        defaultMessage: 'Checkout',
        id: 'CartConfirmationPage.pageTitle'
    },
    title: {
        defaultMessage: 'Checkout',
        id: 'CartConfirmationPage.title'
    }
});
