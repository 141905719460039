declare const window: any;

export function mindbox(operation: string, clientGuid: string) {
    window.mindbox('async', {
        data: {
            customer: {
                ids: {
                    clientGuid
                }
            }
        },
        onError: function (error: unknown) {
            console.error(error);
        },
        onSuccess: function () {},
        operation
    });
}
