import { defineMessages } from 'react-intl';

export default defineMessages({
    description: {
        defaultMessage: 'Please, enter patient last name to view the result',
        id: 'LastNameModal.description'
    },
    title: {
        defaultMessage: 'Order',
        id: 'LastNameModal.title'
    }
});
