import { format, secondsToHours, secondsToMilliseconds } from 'date-fns';
import React, { FC, memo, useEffect, useState } from 'react';

type TProps = {
    seconds: number;
    className?: string;
    children?: any;
    onEnd?: () => void;
};

const Timer: FC<TProps> = ({ seconds, className, children, onEnd }) => {
    const [intervalID, setIntervalID] = useState<number>();
    const [modifySeconds, setModifySeconds] = useState<number>(seconds);

    useEffect(() => {
        clearInterval(intervalID);
        setModifySeconds(seconds);
        if (seconds > 0) {
            setIntervalID(
                window.setInterval(() => {
                    setModifySeconds((prevState) => {
                        if (prevState > 0) {
                            return prevState - 1;
                        }

                        if (prevState === 0) {
                            clearInterval(intervalID);
                        }

                        return prevState;
                    });
                }, 1000)
            );
        }
    }, [seconds]);

    useEffect(() => {
        if (modifySeconds === 0) {
            if (onEnd) {
                onEnd();
            }
        }
    }, [modifySeconds]);

    useEffect(() => {
        return () => clearInterval(intervalID);
    }, []);

    const getFormatted = (seconds: number = 0) => {
        const hours = secondsToHours(seconds) ? `${secondsToHours(seconds)}:` : '';
        return `${hours}${format(secondsToMilliseconds(seconds), `mm:ss`)}`;
    };

    return typeof children === 'function' ? (
        children({ expired: modifySeconds === 0, formatted: getFormatted(modifySeconds), seconds: modifySeconds })
    ) : modifySeconds > 0 ? (
        <div className={className}>{modifySeconds > 0 ? getFormatted(modifySeconds) : null}</div>
    ) : null;
};

export default memo(Timer);
