//@ts-nocheck
import React from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { connect } from 'react-redux';

class AsyncIntlProvider extends React.PureComponent {
    render() {
        return (
            <IntlProvider
                locale={this.props.locale}
                messages={this.props.messages}
                onError={(e) => e.code !== ReactIntlErrorCode.MISSING_DATA && console.error(e)}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.i18n.currentLocale,
        messages: state.i18n.currentMessages
    };
};

export default connect(mapStateToProps)(AsyncIntlProvider);
