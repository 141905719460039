//@ts-nocheck
import { parse } from 'content-disposition-attachment';

export function loadBlobFile(response: any) {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const link = document.createElement('a');

    const { attachment, filename } = parse(response.headers['content-disposition']);

    link.href = window.URL.createObjectURL(blob);
    if (attachment) {
        link.download = `${filename}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    } else {
        window.open(link, '_blank');
    }
}

export function printBlobFile(response: any) {
    document.querySelector('#printBlobFile')?.remove();
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const blobURL = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.id = 'printBlobFile';
    URL.revokeObjectURL(blob);
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow?.print();
        }, 1);
    };
    return true;
}
