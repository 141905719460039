import { defineMessages } from 'react-intl';

export default defineMessages({
    email: {
        defaultMessage: 'E-mail',
        id: 'CheckupDeliveryBlock.email'
    },
    phone: {
        defaultMessage: 'Phone',
        id: 'CheckupDeliveryBlock.phone'
    },
    title: {
        defaultMessage: 'Send the number of the health program by',
        id: 'CheckupDeliveryBlock.title'
    }
});
