import Input from 'components/input/Input';
import React, { FC, InputHTMLAttributes, memo } from 'react';

import style from './InputBlock.module.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    input: {
        ref: React.RefObject<HTMLInputElement>;
        focused: boolean;
        touched: boolean;
    };
}

const CardInput: FC<InputProps> = ({ input, onBlur, ...rest }) => {
    return (
        <Input
            ref={input.ref}
            onFocus={() => {
                input.focused = true;
            }}
            onBlur={(e) => {
                input.focused = false;
                input.touched = true;
                if (onBlur) {
                    onBlur(e);
                }
            }}
            className={style.input}
            resetStyles={true}
            {...rest}
        />
    );
};

export default memo(CardInput);
