//@ts-nocheck
import apiClient from 'client/ApiClient';
import * as userClient from 'client/UserClient';
import {
    AUTHENTICATE,
    AUTHORIZATION,
    CLEAR,
    DEAUTHENTICATE,
    LOGIN,
    LOGIN_AUTHENTICATED,
    LOGIN_CONFIRM,
    LOGIN_CONFIRM_AUTHENTICATED,
    LOGIN_PASSWORD,
    LOGOUT,
    REACTIVATION,
    REACTIVATION_CONFIRM,
    RECOVERY,
    RECOVERY_CONFIRM_BY_CODE,
    RECOVERY_CONFIRM_BY_INZ,
    RECOVERY_SET_PASSWORD,
    REGISTER,
    RESET_PASSWORD,
    SET_ACTION_LOGIN
} from 'redux/auth/actions';
import { ACCESS_TOKEN, MINDBOX_AUTHORIZE_CUSTOMER, USER_ID_COOKIE_KEY } from 'redux/auth/constants';
import { authSelector } from 'redux/auth/selectors';
import { CITY_GUID_COOKIE_KEY, LK_CODES_BY_TERRITORY } from 'redux/cities/constants';
import { request } from 'redux/helpers';
import { CLEAR_ORDERS_ALL } from 'redux/orders/actions';
import { CLEAR_PATIENTS } from 'redux/patient/actions';
import { CLEAR as USER_CLEAR, CLEAR_FETCH, FETCH } from 'redux/user/actions';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';
import CookieService from 'services/CookieService';
import { getHostnameTerritory } from 'utils/hostNameUtils';
import { mindbox } from 'utils/mindboxUtils';

function* login(action) {
    const response = yield request(LOGIN, [userClient.login, action.meta]);

    if (response?.data?.authorizationCode) {
        yield put(AUTHORIZATION.base({ authorizationCode: response.data.authorizationCode }));
    }
}

function* loginAuthenticated(action) {
    const response = yield request(LOGIN_AUTHENTICATED, [userClient.loginAuthenticated, action.meta]);

    if (response?.data?.authorizationCode) {
        yield put(AUTHORIZATION.base({ authorizationCode: response.data.authorizationCode }));
    }
}

function* loginConfirm(action) {
    yield request(LOGIN_CONFIRM, [userClient.loginConfirm, action.meta]);
}

function* loginConfirmAuthenticated(action) {
    yield request(LOGIN_CONFIRM_AUTHENTICATED, [userClient.loginConfirmAuthenticated, action.meta]);
}

function* loginPassword(action) {
    yield request(LOGIN_PASSWORD, [userClient.loginPassword, action.meta]);
}

function* register(action) {
    const cookieCityId = yield cookieService.get(CITY_GUID_COOKIE_KEY);
    const data = { cityId: cookieCityId, ...action.meta };
    yield request(REGISTER, [userClient.register, data]);
}

function* recovery(action) {
    yield request(RECOVERY, [userClient.recovery, action.meta]);
}

function* recoveryConfirmByCode(action) {
    yield request(RECOVERY_CONFIRM_BY_CODE, [userClient.recoveryConfirmByCode, action.meta]);
}

function* recoveryConfirmByINZ(action) {
    yield request(RECOVERY_CONFIRM_BY_INZ, [userClient.recoveryConfirmByINZ, action.meta]);
}

function* recoverySetPassword(action) {
    yield request(RECOVERY_SET_PASSWORD, [userClient.recoverySetPassword, action.meta]);
}

function* resetPassword(action) {
    yield request(RESET_PASSWORD, [userClient.resetPassword, action.meta]);
}

function* reactivation(action) {
    yield request(REACTIVATION, [userClient.reactivation, action.meta]);
}

function* reactivationConfirm(action) {
    yield request(REACTIVATION_CONFIRM, [userClient.reactivationConfirm, action.meta]);
}

function* authorization(action) {
    const { authorizationCode } = action.meta;
    const domain = LK_CODES_BY_TERRITORY[getHostnameTerritory()];
    yield request(AUTHORIZATION, [userClient.authorization, authorizationCode, domain]);
}

function* loginSucceeded() {
    const access_token = CookieService.get(ACCESS_TOKEN);
    apiClient.setGlobalHeader('Authorization', `Bearer ${access_token}`);
    yield put(SET_ACTION_LOGIN.base());
    yield put(FETCH.base());
}

function* authSucceeded(action) {
    const { id: userId, mainContactId } = action.payload.data;
    yield cookieService.set(USER_ID_COOKIE_KEY, userId);
    const { actionLogin } = yield select(authSelector);
    if (mainContactId && actionLogin) {
        mindbox(MINDBOX_AUTHORIZE_CUSTOMER, mainContactId);
    }
    yield put(AUTHENTICATE.base());
}

function* authClear() {
    apiClient.removeGlobalHeader(ACCESS_TOKEN);
    yield cookieService.remove(USER_ID_COOKIE_KEY);
    yield put(DEAUTHENTICATE.base());
    yield put(USER_CLEAR.base());
    yield put(USER_CLEAR.success());
    yield put(CLEAR.success());
}

function* logout() {
    yield request(LOGOUT, [userClient.logout]);
}

function* logoutSucceeded() {
    yield put(CLEAR.base());
    yield put(CLEAR_PATIENTS.base());
    yield put(CLEAR_FETCH.base());
    yield put(CLEAR_ORDERS_ALL.base());
}

export default function* authSagas() {
    yield all([
        takeLatest(LOGIN.BASE, login),
        takeLatest(LOGIN_AUTHENTICATED.BASE, loginAuthenticated),
        takeLatest(LOGIN_CONFIRM.BASE, loginConfirm),
        takeLatest(LOGIN_CONFIRM_AUTHENTICATED.BASE, loginConfirmAuthenticated),
        takeLatest(LOGIN_PASSWORD.BASE, loginPassword),
        takeLatest(REGISTER.BASE, register),
        takeLatest(RECOVERY.BASE, recovery),
        takeLatest(RECOVERY_CONFIRM_BY_CODE.BASE, recoveryConfirmByCode),
        takeLatest(RECOVERY_CONFIRM_BY_INZ.BASE, recoveryConfirmByINZ),
        takeLatest(RECOVERY_SET_PASSWORD.BASE, recoverySetPassword),
        takeLatest(RESET_PASSWORD.BASE, resetPassword),
        takeLatest(REACTIVATION.BASE, reactivation),
        takeLatest(REACTIVATION_CONFIRM.BASE, reactivationConfirm),
        takeLatest(AUTHORIZATION.BASE, authorization),
        takeLatest(AUTHORIZATION.SUCCEEDED, loginSucceeded),
        takeLatest(FETCH.SUCCEEDED, authSucceeded),
        takeLatest(CLEAR.BASE, authClear),
        takeLatest(LOGOUT.BASE, logout),
        takeLatest(LOGOUT.SUCCEEDED, logoutSucceeded)
    ]);
}
