import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootSaga from 'redux/sagas';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory()
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware];

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [...middlewares],
    reducer: rootReducer(routerReducer)
});

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
