//@ts-nocheck
import * as patientClient from 'client/PatientClient';
import * as regionClient from 'client/RegionClient';
import queryString from 'query-string';
import { CALCULATE_OFFICE } from 'redux/cart/actions';
import { request } from 'redux/helpers';
import { CLEAR_BY_PATIENT_ID } from 'redux/loyaltyStatus/actions';
import { CLEAR_ORDERS_COUNT_BY_PATIENT_ID } from 'redux/ordersCount/actions';
import {
    ALLOW_DOCUMENTS,
    ALLOW_HISTORY,
    ALLOW_LOYALTY,
    CONFIRM_DOCUMENTS,
    CONFIRM_HISTORY,
    CONFIRM_LOYALTY,
    CONFIRM_MAIN_PATIENT,
    CREATE,
    DELETE,
    DISALLOW_DOCUMENTS,
    DISALLOW_HISTORY,
    DISALLOW_LOYALTY,
    FETCH_ALL,
    FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION,
    FETCH_ONLINE_REGISTRATION_DATA,
    FETCH_PATIENT,
    FETCH_REGIONS,
    GET_EPGU_INZ_STATUSES,
    MAIN_PATIENT,
    PUBLISHED_PATIENT,
    SELECT,
    SEND_TO_EPGU,
    SET_EPGU_SEND_START_DATE
} from 'redux/patient/actions';
import { mainPatientSelector, selectedPatientSelector } from 'redux/patient/selectors';
import { USER_COOKIE_NAME } from 'redux/user/constants';
import { replace } from 'redux-first-history';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import cookieService from 'services/CookieService';

function* setEpguSendStartDate(action) {
    yield request(SET_EPGU_SEND_START_DATE, [patientClient.setEpguSendStartDate, action.meta], action.meta);
}

function* fetchPatients(action) {
    yield request(FETCH_ALL, [patientClient.fetchAll, action.meta]);
}

function* fetchOnlineRegistrationData() {
    const mainPatient = yield select(mainPatientSelector);
    if (mainPatient) {
        const requestData = { patientId: mainPatient.id };
        yield request(FETCH_ONLINE_REGISTRATION_DATA, [patientClient.fetchOnlineRegistrationData, requestData], requestData);
    }
}

function* createPatient(action) {
    yield request(CREATE, [patientClient.create, action.meta]);
}

function* deletePatient(action) {
    yield request(DELETE, [patientClient.deletePatient, action.meta], action.meta);
}

function* allowHistory(action) {
    const { patientId } = action.meta;
    yield request(ALLOW_HISTORY, [patientClient.allowHistory, action.meta], patientId);
}

function* confirmHistory(action) {
    const { patientId, code, contactType } = action.meta;
    yield request(CONFIRM_HISTORY, [patientClient.confirmHistory, patientId, code, contactType], patientId);
}

function* disallowHistory(action) {
    yield request(DISALLOW_HISTORY, [patientClient.disallowHistory, action.meta], { patientId: action.meta });
}

function* mainPatient(action) {
    const { patientId } = action.meta;
    yield request(MAIN_PATIENT, [patientClient.mainPatient, action.meta], patientId);
}

function* confirmMainPatient(action) {
    const { patientId, code, contactType } = action.meta;
    yield request(CONFIRM_MAIN_PATIENT, [patientClient.confirmMainPatient, patientId, code, contactType], { patientId: patientId });
    yield put(SELECT.base(null));
}

function* allowLoyalty(action) {
    const { patientId } = action.meta;
    yield request(ALLOW_LOYALTY, [patientClient.allowLoyalty, action.meta], patientId);
}

function* confirmLoyalty(action) {
    const { patientId, code, contactType } = action.meta;
    yield request(CONFIRM_LOYALTY, [patientClient.confirmLoyalty, patientId, code, contactType], patientId);
}

function* disallowLoyalty(action) {
    yield request(DISALLOW_LOYALTY, [patientClient.disallowLoyalty, action.meta], { patientId: action.meta });
}

function* allowDocuments(action) {
    const { patientId } = action.meta;
    yield request(ALLOW_DOCUMENTS, [patientClient.allowDocuments, action.meta], patientId);
}

function* confirmDocuments(action) {
    const { patientId, code, contactType } = action.meta;
    yield request(CONFIRM_DOCUMENTS, [patientClient.confirmDocuments, patientId, code, contactType], patientId);
}

function* disallowDocuments(action) {
    yield request(DISALLOW_DOCUMENTS, [patientClient.disallowDocuments, action.meta], { patientId: action.meta });
}

function* clearPatientData(action) {
    yield put(CLEAR_BY_PATIENT_ID.base(action.meta));
    yield put(CLEAR_ORDERS_COUNT_BY_PATIENT_ID.base(action.meta));
}

function* selectPatient(action) {
    const id = action.meta;
    const query = queryString.parse(window.location.search);
    const { state } = yield select((state) => state.router.location);
    const locationState = state ? { ...state } : null;
    if (query.patientId !== id) {
        const searchString = queryString.stringify({
            ...query,
            patientId: id
        });
        yield put(replace({ pathname: window.location.pathname, search: searchString }, locationState));
    }
    yield put(SELECT.success());
}

function* updateUserCookie() {
    const mainPatient = yield select(mainPatientSelector);
    const lkUserCookie = yield JSON.parse(cookieService.get(USER_COOKIE_NAME));
    if (mainPatient && lkUserCookie) {
        cookieService.set(USER_COOKIE_NAME, JSON.stringify({ ...lkUserCookie, contactId: mainPatient.contactId }));
    }
}

function* fetchPatient(action) {
    yield request(FETCH_PATIENT, [patientClient.fetchPatient, action.meta], action.meta);
}

function* getEpguInzStatuses(action) {
    yield request(GET_EPGU_INZ_STATUSES, [patientClient.getEpguInzStatuses, action.meta]);
}

function* sendToEpgu(action) {
    yield request(SEND_TO_EPGU, [patientClient.sendToEpgu, action.meta]);
}

function* fetchPatientPublicationStatus() {
    const maxAttempts = 6;
    let attempt = 0;
    let delayTime = 1000;

    const patient = yield select(selectedPatientSelector);
    yield put(PUBLISHED_PATIENT.start());

    while (attempt < maxAttempts) {
        try {
            const { data } = yield call(patientClient.publishedPatient, patient.id);

            if (data?.contactPublished) {
                yield put(PUBLISHED_PATIENT.success({}));
                yield put(CALCULATE_OFFICE.base());
                break;
            }
        } catch (error) {
            yield put(PUBLISHED_PATIENT.error(error));
            break;
        }

        attempt++;
        delayTime *= 2;

        yield delay(delayTime);
    }

    if (attempt === maxAttempts) {
        yield put(PUBLISHED_PATIENT.error());
    }
}

function* fetchAllowedCountriesForOnlineRegistration() {
    yield request(FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION, [regionClient.fetchAllowedCountriesForOnlineRegistration]);
}

function* fetchRegions(action) {
    yield request(FETCH_REGIONS, [regionClient.fetchRegions, action.meta]);
}

export default function* patientSagas() {
    yield all([
        takeLatest(FETCH_ALL.BASE, fetchPatients),
        takeLatest(FETCH_ALL.SUCCEEDED, updateUserCookie),
        takeLatest(FETCH_ONLINE_REGISTRATION_DATA.BASE, fetchOnlineRegistrationData),
        takeLatest(CREATE.BASE, createPatient),
        takeLatest(CREATE.SUCCEEDED, updateUserCookie),
        takeLatest(DELETE.BASE, deletePatient),
        takeLatest(DELETE.SUCCEEDED, clearPatientData),
        takeLatest(ALLOW_HISTORY.BASE, allowHistory),
        takeLatest(CONFIRM_HISTORY.BASE, confirmHistory),
        takeLatest(DISALLOW_HISTORY.BASE, disallowHistory),
        takeLatest(MAIN_PATIENT.BASE, mainPatient),
        takeLatest(MAIN_PATIENT.SUCCEEDED, updateUserCookie),
        takeLatest(CONFIRM_MAIN_PATIENT.BASE, confirmMainPatient),
        takeLatest(ALLOW_LOYALTY.BASE, allowLoyalty),
        takeLatest(CONFIRM_LOYALTY.BASE, confirmLoyalty),
        takeLatest(DISALLOW_LOYALTY.BASE, disallowLoyalty),
        takeLatest(ALLOW_DOCUMENTS.BASE, allowDocuments),
        takeLatest(CONFIRM_DOCUMENTS.BASE, confirmDocuments),
        takeLatest(DISALLOW_DOCUMENTS.BASE, disallowDocuments),
        takeLatest(SELECT.BASE, selectPatient),
        takeLatest(SET_EPGU_SEND_START_DATE.BASE, setEpguSendStartDate),
        takeLatest(FETCH_PATIENT.BASE, fetchPatient),
        takeLatest(GET_EPGU_INZ_STATUSES.BASE, getEpguInzStatuses),
        takeLatest(SEND_TO_EPGU.BASE, sendToEpgu),
        takeLatest(PUBLISHED_PATIENT.BASE, fetchPatientPublicationStatus),
        takeLatest(FETCH_REGIONS.BASE, fetchRegions),
        takeLatest(FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.BASE, fetchAllowedCountriesForOnlineRegistration)
    ]);
}
