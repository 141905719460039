import { defineMessages } from 'react-intl';

export default defineMessages({
    ACCOUNT_ALREADY_ACTIVATED: {
        defaultMessage: 'Account already activated',
        id: 'LoyaltyError.ACCOUNT_ALREADY_ACTIVATED'
    },
    ACCOUNT_BLOCKED: {
        defaultMessage: '',
        id: 'LoyaltyError.ACCOUNT_BLOCKED'
    },
    ACCOUNT_DEACTIVATED: {
        defaultMessage: '',
        id: 'LoyaltyError.ACCOUNT_DEACTIVATED'
    },
    ACCOUNT_NOT_FOUND: {
        defaultMessage: '',
        id: 'LoyaltyError.ACCOUNT_NOT_FOUND'
    },
    ACCOUNT_STATUS_ACTIVE: {
        defaultMessage: '',
        id: 'LoyaltyError.ACCOUNT_STATUS_ACTIVE'
    },
    CONTACT_DEACTIVATED: {
        defaultMessage: '',
        id: 'LoyaltyError.CONTACT_DEACTIVATED'
    },
    CONTACT_NO_MATCH_ACCOUNT: {
        defaultMessage: '',
        id: 'LoyaltyError.CONTACT_NO_MATCH_ACCOUNT'
    },
    CONTACT_WRONG_PHONE: {
        defaultMessage: 'The phone number does not match the contact',
        id: 'LoyaltyError.CONTACT_WRONG_PHONE'
    },
    CREATE_ACCOUNT_NOT_ALLOWED: {
        defaultMessage: '',
        id: 'LoyaltyError.CREATE_ACCOUNT_NOT_ALLOWED'
    },
    PIN_AWAITING_TIME_EXPIRED: {
        defaultMessage: 'Timeout while waiting for the confirmation code',
        id: 'LoyaltyError.PIN_AWAITING_TIME_EXPIRED'
    },
    UNKNOWN_ERROR: {
        id: 'LoyaltyError.UNKNOWN_ERROR',
        defaultMessage: 'Error while interacting with loyalty service'
    },
    PIN_NOT_SENT: {
        defaultMessage: 'No confirmation code was sent for this account',
        id: 'LoyaltyError.PIN_NOT_SENT'
    },
    PIN_SEND_TIMEOUT_NOT_EXPIRED: {
        defaultMessage: 'The confirmation code was already sent earlier',
        id: 'LoyaltyError.PIN_SEND_TIMEOUT_NOT_EXPIRED'
    },
    WRONG_PIN: {
        defaultMessage: 'Invalid activation code',
        id: 'LoyaltyError.WRONG_PIN'
    }
});
