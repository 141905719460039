//@ts-nocheck
import { LOGOUT } from 'redux/auth/actions';
import { ACCESS_TOKEN } from 'redux/auth/constants';
import { FETCH_SETTINGS } from 'redux/startup/actions';
import { call, cancelled, put, select } from 'redux-saga/effects';
import CookieService from 'services/CookieService';

const TERRITORY_SETTINGS_VERSION = 'territory-settings-version';

export function createRequestTypes(type) {
    const BASE = type;
    const STARTED = `${type}_STARTED`;
    const SUCCEEDED = `${type}_SUCCEEDED`;
    const ERRORED = `${type}_ERRORED`;
    const CANCELLED = `${type}_CANCELLED`;
    const PROGRESS = `${type}_PROGRESS`;
    const AWAITING = `${type}_AWAITING`;

    return {
        AWAITING,
        BASE,
        CANCELLED,
        ERRORED,
        PROGRESS,
        STARTED,
        SUCCEEDED,

        base: (meta = {}) => ({
            meta,
            type: BASE
        }),

        cancel: (meta) => ({
            meta,
            type: CANCELLED
        }),

        error: (caught, meta) => ({
            error: true,
            meta,
            payload: caught,
            type: ERRORED
        }),

        start: (meta) => ({
            meta,
            type: STARTED
        }),

        success: (payload, meta) => ({
            meta,
            payload,
            type: SUCCEEDED
        })
    };
}

export function* requestSaga(type, callable, meta) {
    const { start, success, error, cancel } = type;
    yield put(start(meta));
    try {
        const payload = yield call(...callable);
        if (type !== FETCH_SETTINGS) {
            const territorySettingsVersion = yield payload.headers[TERRITORY_SETTINGS_VERSION];
            const settings = yield select((state) => state.startup.settings);
            if (territorySettingsVersion && settings) {
                if (parseInt(territorySettingsVersion) > parseInt(settings.territorySettingsVersion)) {
                    yield put(FETCH_SETTINGS.base());
                }
            }
        }
        yield put(success(payload, meta));
        return payload;
    } catch (caught) {
        if (caught.response?.status === 401) {
            const access_token = yield CookieService.get(ACCESS_TOKEN);
            const authenticated = yield select((state) => state.auth.authenticated);
            if (authenticated || access_token) {
                yield put(LOGOUT.base());
            }
        }
        return yield put(error(caught, meta));
    } finally {
        if (yield cancelled()) {
            yield put(cancel(meta));
        }
    }
}

export function request(type, callable, meta) {
    return call(requestSaga, type, callable, meta);
}

export function basicReducer(state, action, entityName = 'response') {
    const type = action.type;
    const entityNameCorrected = entityName || 'response';
    if (type.endsWith('_STARTED')) {
        return {
            ...state,
            [entityNameCorrected]: undefined,
            error: undefined,
            loading: true,
            request: action.meta,
            success: false
        };
    } else if (type.endsWith('_SUCCEEDED')) {
        return {
            ...state,
            [entityNameCorrected]: action.payload.data,
            loading: false,
            success: true
        };
    } else if (type.endsWith('_ERRORED')) {
        const data = action.payload?.response?.data;

        const errors = data?.errors?.reduce((map, error) => {
            map[error.field] = error.message;
            return map;
        }, {});

        return {
            ...state,
            error: data || {},
            errors,
            loading: false
        };
    } else {
        return state;
    }
}
