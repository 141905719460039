import { useAppDispatch, useAppSelector } from 'app/Hooks';
import classNames from 'classnames';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import LoaderModal from 'components/modal/loader/LoaderModal';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import commonMessages from 'messages/CommonMessages';
import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { fetchMultiplicatorsCategory } from 'redux/loyaltyIKB/actions';
import { fetchMultiplicatorsCategorySelector, loyaltyIKBSelector, multiplicatorsCategorySelector } from 'redux/loyaltyIKB/selectors';
import IKBLoyaltyService from 'services/IKBLoyaltyService';

import styles from './IKBСashbackTypesButtons.module.css';

type TProps = {
    className?: string;
};

const IKBCashbackTypesButtons: FC<TProps> = ({ className }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const territory = useAppSelector(currentTerritorySelector);
    const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
    const multiplicators = useAppSelector(multiplicatorsCategorySelector);
    const { loading } = useAppSelector(fetchMultiplicatorsCategorySelector);
    const { activeCashbackType } = useAppSelector((state) => loyaltyIKBSelector(state));
    const regularMultiplicator = ikbLoyaltyService.getRegularMultiplicator(multiplicators);
    const patientId = searchParams.get('patientId') as string;

    useEffect(() => {
        if (patientId) {
            if (!regularMultiplicator) {
                dispatch(
                    fetchMultiplicatorsCategory({
                        patientId: patientId,
                        territory
                    })
                );
            }
        }
    }, [patientId]);

    return (
        <div className={classNames(styles.cashbackTypesButtons, className)}>
            <LoaderModal show={loading} />
            <ButtonSecondary
                text={formatMessage(commonMessages.readySolutions)}
                variant={activeCashbackType === ikbCashbackType.READY_SOLUTIONS ? 'active' : ''}
                onClick={() => navigate(`/loyalty/ikb/cashback/solutions?patientId=${patientId}`)}
            />
            <ButtonSecondary
                text={formatMessage(commonMessages.individualServices)}
                variant={activeCashbackType === ikbCashbackType.INDIVIDUAL_SERVICES ? 'active' : ''}
                onClick={() => navigate(`/loyalty/ikb/cashback/services?patientId=${patientId}`)}
            />
            {regularMultiplicator && (
                <ButtonSecondary
                    text={formatMessage(commonMessages.regularCashback)}
                    variant={activeCashbackType === ikbCashbackType.REGULAR_CASHBACK ? 'active' : ''}
                    onClick={() => navigate(`/loyalty/ikb/cashback/regular?patientId=${patientId}`)}
                />
            )}
        </div>
    );
};

export default memo(IKBCashbackTypesButtons);
