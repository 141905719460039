//@ts-nocheck
import Cost from 'containers/const/Cost';
import styles from 'page/cart/confirmation/orderBlock/OrderBlock.module.css';
import messages from 'page/cart/confirmation/orderBlock/OrderBlockMessages';
import OrderList from 'page/cart/confirmation/orderBlock/orderList/OrderList';
import ProductList from 'page/cart/confirmation/orderBlock/productList/ProductList';
import Certificates from 'page/orders/component/certificates/Certificates';
import Coupons from 'page/orders/component/coupons/Coupons';
import Discounts from 'page/orders/component/discounts/Discounts';
import LoyaltySummary from 'page/orders/component/loyaltySummary/LoyaltySummary';
import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl } from 'react-intl';

class OrderBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleBonusesChanged = this.handleBonusesChanged.bind(this);
        this.handleCoupons = this.handleCoupons.bind(this);
        this.handleCertificates = this.handleCertificates.bind(this);
        this.refreshLoyalty = this.refreshLoyalty.bind(this);
    }

    refreshLoyalty() {
        this.props.handleCalculate();
    }

    handleBonusesChanged(value) {
        this.props.handleBonusesChanged(value);
    }

    handleCoupons(value) {
        this.props.handleCoupons(value);
    }

    handleCertificates(value) {
        this.props.handleCertificates(value);
    }

    render() {
        const { cart, intl, checkups, cartOrderType, saleAction, services, cost, currency, loading } = this.props;

        if (!cart?.products && !checkups?.valid) {
            return null;
        }

        return (
            <div className={styles.orderListBlock}>
                <h2 className={styles.orderListTitle}>{intl.formatMessage(cartOrderType.reorder ? messages.reorderListTitle : messages.orderListTitle)}</h2>
                <OrderList loading={loading} cost={cost} saleAction={saleAction} currency={currency} cartOrderType={cartOrderType} services={services} />
                <ProductList cart={cart} checkups={checkups?.valid} cartOrderType={cartOrderType} currency={currency} />
                {saleAction && (
                    <React.Fragment>
                        <LoyaltySummary saleAction={saleAction} handleBonusesChanged={this.handleBonusesChanged} />
                        <Discounts saleAction={saleAction} />
                        {(saleAction.coupons || saleAction.certificates) && (
                            <div className={styles.promotions}>
                                {this.renderCoupons()}
                                {this.renderCertificates()}
                            </div>
                        )}
                    </React.Fragment>
                )}

                {!loading && (
                    <div className={styles.orderListTotal}>
                        <div className={styles.orderListTotalTitle}>{intl.formatMessage(messages.totalTitle)}</div>
                        <div className={styles.orderListTotalPrice}>
                            {this.renderLoyaltyBlock()}
                            <div>
                                <Cost defaultValue={'-'} amount={cost.totalCost} currency={currency} />
                            </div>
                        </div>
                    </div>
                )}

                {cartOrderType.checkups && <div className={styles.checkupInformation}>{intl.formatMessage(messages.checkupInformation)}</div>}
            </div>
        );
    }

    renderCoupons() {
        const { onlinePaymentTypes, selectedPaymentType, saleAction } = this.props;
        if (onlinePaymentTypes[selectedPaymentType] && saleAction?.coupons) {
            return <Coupons handleCoupons={this.handleCoupons} />;
        }
    }

    renderCertificates() {
        const { onlinePaymentTypes, selectedPaymentType, saleAction, currency } = this.props;
        if (onlinePaymentTypes[selectedPaymentType] && saleAction?.certificates) {
            return <Certificates handleCertificates={this.handleCertificates} currency={currency} />;
        }
    }

    renderLoyaltyBlock() {
        const { cart, cartOrderType, currency } = this.props;
        if (
            ((cartOrderType.analyzes || cartOrderType.reorder || cartOrderType.paymentPreorder) &&
                cart.costWithDiscount &&
                cart.costWithDiscount !== cart.cost) ||
            cart.hasAppliedCertificates
        ) {
            return (
                <div>
                    <Cost defaultValue={'-'} amount={cart.costWithDiscount} currency={currency} />
                </div>
            );
        } else {
            return null;
        }
    }
}

OrderBlock.propTypes = {
    cart: PropTypes.object.isRequired,
    handleBonusesChanged: PropTypes.func.isRequired
};

export default injectIntl(OrderBlock);
