//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import {
    CANCEL,
    CLEAR_ACCEPTED_INFO,
    CLEAR_DATA_STATE,
    CLEAR_ORDERS_ALL,
    CREATE,
    CREATE_CHECKUP,
    CREATE_REORDER,
    FETCH_ACCEPTED_INFO,
    ORDERS_ALL,
    PREORDER_PAY
} from 'redux/orders/actions';

const initialState = {
    acceptedInfo: {},
    cancel: {},
    create: {},
    createCheckup: {},
    createReorder: {},
    entities: {},
    failedSources: [],
    fetchAcceptedInfo: {},
    fetchAll: {},
    preorderPay: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ORDERS_ALL.STARTED:
        case ORDERS_ALL.SUCCEEDED:
        case ORDERS_ALL.ERRORED:
            let entities = state.entities;
            let failedSources = state.failedSources;
            if (action.type === ORDERS_ALL.SUCCEEDED) {
                const response = action.payload.data;
                entities = response.content.reduce((map, order) => {
                    map[order.id] = order;
                    return map;
                }, {});
                failedSources = response.failedSources;
            }
            return {
                ...state,
                entities,
                failedSources,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        case CREATE.STARTED:
        case CREATE.SUCCEEDED:
        case CREATE.ERRORED: {
            return {
                ...state,
                create: basicReducer(state.create, action)
            };
        }
        case CREATE_REORDER.STARTED:
        case CREATE_REORDER.SUCCEEDED:
        case CREATE_REORDER.ERRORED: {
            return {
                ...state,
                createReorder: basicReducer(state.createReorder, action)
            };
        }
        case FETCH_ACCEPTED_INFO.STARTED:
        case FETCH_ACCEPTED_INFO.SUCCEEDED:
        case FETCH_ACCEPTED_INFO.ERRORED: {
            let acceptedInfo = state.acceptedInfo;
            if (action.type === FETCH_ACCEPTED_INFO.SUCCEEDED) {
                acceptedInfo = {
                    ...acceptedInfo,
                    ...action.payload.data
                };
            }

            return {
                ...state,
                acceptedInfo,
                fetchAcceptedInfo: basicReducer(state.acceptedInfo, action)
            };
        }
        case CREATE_CHECKUP.STARTED:
        case CREATE_CHECKUP.SUCCEEDED:
        case CREATE_CHECKUP.ERRORED: {
            return {
                ...state,
                createCheckup: basicReducer(state.createCheckup, action)
            };
        }
        case CANCEL.STARTED:
        case CANCEL.SUCCEEDED:
        case CANCEL.ERRORED: {
            let entities = state.entities;
            const { orderId } = action.meta;
            if (action.type === CANCEL.SUCCEEDED) {
                entities = { ...entities };
                delete entities[orderId];
            }
            return {
                ...state,
                cancel: basicReducer(state.cancel, action),
                entities
            };
        }
        case CLEAR_ORDERS_ALL.BASE:
            return initialState;
        case CLEAR_DATA_STATE.BASE:
            return {
                ...state,
                create: {},
                createReorder: {},
                preorderPay: {}
            };
        case CLEAR_ACCEPTED_INFO.BASE:
            return {
                ...state,
                acceptedInfo: {},
                fetchAcceptedInfo: {}
            };

        case PREORDER_PAY.STARTED:
        case PREORDER_PAY.SUCCEEDED:
        case PREORDER_PAY.ERRORED: {
            return {
                ...state,
                preorderPay: basicReducer(state.preorderPay, action)
            };
        }
        default:
            return state;
    }
}
