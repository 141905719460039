import { defineMessages } from 'react-intl';

export default defineMessages({
    nextChange: {
        defaultMessage: 'The following changes will be possible in {text}',
        id: 'NextChangeDays.nextChange'
    },
    nextChangeDays: {
        defaultMessage: '{days} {days, plural, one {day} other {days}}',
        id: 'NextChangeDays.nextChangeDays'
    }
});
