import React, { FC, memo } from 'react';

type TProps = {
    className?: string;
};

const Logo: FC<TProps> = ({ className }) => {
    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 220 40' version='1.1'>
            <defs />
            <g
                id='component/header/middle-block-client'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'
                transform='translate(0.000000, -10.000000)'
            >
                <g id='logo' transform='translate(0.000000, 10.000000)' fill='#0097B3'>
                    <polygon id='Shape' points='102.943169 0.554021659 95.2371585 39.1802512 103.989071 39.1802512 111.695082 0.554021659' />
                    <polygon id='Shape' points='16.4579235 0.554021659 7.70601093 0.554021659 0 39.1802512 8.75191257 39.1802512' />
                    <polygon
                        id='Shape'
                        points='100.106011 0.554021659 91.3540984 0.554021659 75.0644809 27.3627648 69.9191257 0.554021659 61.1672131 0.554021659 68.5726776 39.1802512 76.6273224 39.1802512'
                    />
                    <polygon
                        id='Shape'
                        points='144.418579 0.554021659 115.854645 0.554021659 114.339891 8.66877576 124.281967 8.66877576 118.186885 39.1802512 126.938798 39.1802512 133.021858 8.66877576 142.915847 8.66877576'
                    />
                    <polygon
                        id='Shape'
                        points='50.2874317 0.554021659 45.1901639 26.1124916 31.7978142 0.554021659 24.2360656 0.554021659 16.5420765 39.1802512 24.9693989 39.1802512 30.0786885 13.5616719 43.4349727 39.1802512 51.020765 39.1802512 58.7147541 0.554021659'
                    />
                    <path
                        d='M165.228415,0.554021659 L149.179235,0.554021659 L141.473224,39.1802512 L150.273224,39.1802512 L156.284153,8.66877576 L163.845902,8.66877576 C165.366932,8.59779858 166.851327,9.14902116 167.957377,10.1955517 C168.804803,11.1100763 169.216658,12.3456402 169.087432,13.5857156 C168.714754,17.4687758 165.661202,19.2600326 161.706011,19.2600326 L155.322404,19.2600326 L163.545355,39.1802512 L172.297268,39.1802512 L166.827322,25.9562075 C173.152468,25.1425269 177.89726,19.7705797 177.923497,13.3933659 C178.284153,5.90374843 172.778142,0.554021659 165.19235,0.554021659'
                        id='Shape'
                    />
                    <path
                        d='M179.582514,19.8731473 C180.248461,8.63320636 189.614104,-0.108412986 200.873224,0.00101619441 C212.522404,0.00101619441 220.384699,8.83708177 219.747541,19.8611255 C219.081594,31.1010665 209.715951,39.8426858 198.456831,39.7332566 C186.80765,39.7332566 178.945355,30.8971911 179.594536,19.8731473 M211.608743,19.8731473 C211.894714,16.7384115 210.827011,13.6309418 208.674744,11.3339661 C206.522478,9.03699029 203.490955,7.76961912 200.344262,7.85128942 C193.595759,7.82540734 188.030065,13.1311207 187.733333,19.8731473 C187.447363,23.0078831 188.515066,26.1153529 190.667332,28.4123286 C192.819599,30.7093044 195.851121,31.9766756 198.997814,31.8950053 C205.750966,31.9273603 211.323855,26.6198469 211.620765,19.8731473'
                        id='Shape'
                        fillRule='nonzero'
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(Logo);
